import Comments from "../comment/View";
import UserSignatures from "../user/Signatures";
import { useTranslation } from "react-i18next";
import { formatLink, translateCell } from "../utils/format";
import { Pie } from "../charts";
import { CreatedBy, Status, Video } from "../template";
import { RiStarFill } from "react-icons/ri";

const TrainingPlanView = ({
	id,
	_id,
	createdby,
	created,
	content,
	user_count,
	signature_count,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
				<div>
					<CreatedBy user={createdby} date={created}></CreatedBy>
					<Status
						color={content.type === "exam" ? "red" : "green"}
						left={
							content.point && (
								<div className="flex items-center">
									<RiStarFill className="mr-1" />
									{content.point} {t("fields.has-point")}
								</div>
							)
						}
						right={t(`const.training_type.${content.type}`)}
					></Status>
					{content.name && (
						<div className="my-4 uppercase font-semibold">{content.name}</div>
					)}
					{content.info && <div className="info">{content.info}</div>}
					<Video file={content.media} />
					{_id && (
						<Pie
							data={[
								{
									value: signature_count,
									name: t("const.training_status.completed"),
								},
								{
									value: user_count - signature_count,
									name: t("const.training_status.notcompleted"),
								},
							]}
							height={300}
							renderLabel={({ name, value }) => `${name}: ${value}`}
						/>
					)}
				</div>
				<div>
					<Comments model="training" id={id} />
				</div>
			</div>
			<div className="mt-4">
				<UserSignatures
					model="training"
					id={id}
					title={t("title.signature-training")}
					statusColumn={[
						{
							key: "status",
							label: "fields.status",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/static/training_status",
							},
							render: (row) => {
								return translateCell(row, t, "training_status", "status");
							},
						},
						content.type === "exam" && {
							key: "exam_status",
							label: "training.exam_status",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/static/exam_status",
							},
							render: (row) => {
								return formatLink(
									translateCell(row, t, "exam_status", "exam_status"),
									null,
									`${row._id}`
								);
							},
						},
					]}
				></UserSignatures>
			</div>
		</div>
	);
};

export default TrainingPlanView;
