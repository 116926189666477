import { useRef } from "react";
import { RiInstallLine } from "react-icons/ri";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";

const PngExportable = ({ children, title }) => {
	const exportRef = useRef();
	const { t } = useTranslation();

	const exportToPng = async () => {
		const element = exportRef.current;
		const canvas = await html2canvas(element, { useCORS: true });
		const data = canvas.toDataURL("image/png");

		var a = document.createElement("a");
		a.href = data;
		a.download = `${title}.png`;
		a.click();
	};

	return (
		<div>
			<div className="flex justify-end">
				<div>
					<button
						className="secondary"
						onClick={() => {
							exportToPng();
						}}
					>
						<RiInstallLine className="text-2xl mr-2" />
						{t("button.download")}
					</button>
				</div>
			</div>
			<div ref={exportRef} className="p-10 pt-0">
				<div className="text-center border-b m-2 p-2 border-def-secondary">
					{title}
				</div>
				<div>{children}</div>
			</div>
		</div>
	);
};

export default PngExportable;
