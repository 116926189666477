import { useTranslation } from "react-i18next";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../list/Table";
import {
	formatDate,
	formatMedia,
	formatLink,
	translateCell,
	createdByCol,
} from "../utils/format";

const UserTable = () => {
	const { t } = useTranslation();

	return (
		<div>
			<h1 className="bordered">
				{t("title.table", { param: t("title.manager") })}
			</h1>
			<div className="mt-4">
				<Table
					renderHeader={() => {
						return (
							<div className="flex justify-end">
								<div className="mb-4">
									<Link to="new" className="button primary">
										{t("button.create")}
										<HiArrowNarrowRight className="text-lg ml-1" />
									</Link>
								</div>
							</div>
						);
					}}
					url={`/user/table`}
					columns={[
						{
							key: "profile",
							label: "user.profile",
							render: (row) => formatMedia(row, "profile", "name"),
						},
						{
							key: "phone",
							label: "user.phone",
							sortable: true,
							filter: true,
							render: (row) =>
								formatLink(row, "phone", `/admin/manager/${row._id}`),
						},
						{
							key: "role",
							label: "user.role",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/static/manager_role",
							},
							render: (row) => translateCell(row, t, "manager_role", "role"),
						},
						{
							key: "lastname",
							label: "user.lastname",
							sortable: true,
							filter: true,
						},
						{
							key: "firstname",
							label: "user.firstname",
							sortable: true,
							filter: true,
						},
						{
							key: "email",
							label: "user.email",
							sortable: true,
							filter: true,
						},
						{
							key: "created",
							label: "fields.created",
							sortable: true,
							filter: { type: "date" },
							render: (row) => formatDate(row, "created"),
						},
						createdByCol,
					]}
				/>
			</div>
		</div>
	);
};

export default UserTable;
