import { useTranslation } from "react-i18next";

const DAYS = [2, 3, 4, 5, 6, 7, 1];
const HOURS = [
	"00:00",
	"01:00",
	"02:00",
	"03:00",
	"04:00",
	"05:00",
	"06:00",
	"07:00",
	"08:00",
	"09:00",
	"10:00",
	"11:00",
	"12:00",
	"13:00",
	"14:00",
	"15:00",
	"16:00",
	"17:00",
	"18:00",
	"19:00",
	"20:00",
	"21:00",
	"22:00",
	"23:00",
];

const renderCell = (value, renderColor) => {
	return (
		<div
			className={`h-full flex items-center justify-center ${
				renderColor ? renderColor(value) : "bg-def-gray"
			}`}
		>
			{value}
		</div>
	);
};

const Hour = ({ data, renderColor }) => {
	const { t } = useTranslation();

	return (
		<div className="text-xs overflow-y-auto">
			{DAYS.map((day) => {
				const hours = data[day]?.hours;

				let hourObj = {};
				if (Array.isArray(hours)) {
					hourObj = hours.reduce((acc, curr) => {
						return { ...acc, [curr.hour]: curr.value };
					}, {});
				}

				return (
					<div key={day} className="flex border-b">
						<div className="w-24 flex-shrink-0 flex items-center justify-center bg-def-gray">
							{t(`days.${day}`)}
						</div>
						{HOURS.map((hour, hourIndex) => (
							<div
								key={hour}
								className="flex-1 border-l"
								style={{ minWidth: 50, height: 50 }}
							>
								{renderCell(hourObj[hourIndex], renderColor)}
							</div>
						))}
					</div>
				);
			})}
			<div className="flex">
				<div className="w-24 flex-shrink-0"></div>
				{HOURS.map((hour, hourIndex) => (
					<div
						key={hour}
						className="flex-1 border-l text-center bg-def-gray"
						style={{ minWidth: 50 }}
					>
						{hour}
					</div>
				))}
			</div>
		</div>
	);
};
export default Hour;
