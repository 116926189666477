import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "./i18n/config";

import AuthProvider from "./utils/auth";
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import {
	AppPrivacy,
	Forget,
	Login,
	NotFound,
	Privacy,
	Recover,
	Register,
	Home,
	Terms,
} from "./pages";
import { Loader } from "./template";

import ReactGA from "react-ga";
import { GOOGLE_TAG } from "./defines";

ReactGA.initialize(GOOGLE_TAG);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
	<BrowserRouter>
		<React.StrictMode>
			<Suspense fallback={<Loader />}>
				<AuthProvider>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/terms" element={<Terms />} />
						<Route path="/privacy" element={<Privacy />} />
						<Route path="/app-privacy" element={<AppPrivacy />} />
						<Route path="auth" element={<AuthLayout />}>
							<Route path="forget" element={<Forget />} />
							<Route path="login" element={<Login />} />
							<Route path="logout" element={<Login from="inside" />} />
							<Route path="recover" element={<Recover />} />
							<Route path="register" element={<Register />} />
						</Route>
						<Route path="admin/*" element={<AdminLayout />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</AuthProvider>
			</Suspense>
		</React.StrictMode>
	</BrowserRouter>,
	document.getElementById("root")
);
