import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatString,
	createdByCol,
	translateCell,
	more,
} from "../utils/format";

const HazardTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/hazard/table"
			columns={[
				more(),
				{ key: "number", label: "fields.number", filter: true },
				{
					key: "category",
					label: "hazard.category",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/category/hazard",
					},
					render: (row) => formatString(row, "category.name"),
				},
				{
					key: "location",
					label: "hazard.location",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/location",
					},
					render: (row) => formatString(row, "location.name"),
				},
				{
					key: "probability",
					label: "hazard.probability",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/probability",
					},
					render: (row) => translateCell(row, t, "probability", "probability"),
				},
				{
					key: "damage",
					label: "hazard.damage",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/damage",
					},
					render: (row) => translateCell(row, t, "damage", "damage"),
				},
				{
					key: "assessment",
					label: "hazard.assessment",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "assessment_name",
					label: "hazard.assessment_name",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/assessment",
					},
					render: (row) =>
						translateCell(row, t, "assessment", "assessment_name"),
				},
				{
					key: "status",
					label: "hazard.status",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/hazard_status",
					},
					render: (row) => translateCell(row, t, "hazard_status", "status"),
				},
				{
					key: "deadline",
					label: "hazard.deadline",
					sortable: true,
					filter: { type: "date" },
					width: 220,
					render: (row) => formatDate(row, "deadline"),
				},
				{
					key: "comment_count",
					label: "fields.comment_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				{
					key: "unit",
					label: "fields.unit",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/unit",
					},
					render: (row) => formatString(row, "unit.name"),
				},
				createdByCol,
			]}
		/>
	);
};

export default HazardTable;
