import { useEffect, useState } from "react";
import { Text } from "../inputs";
import { RiSendPlaneFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { Loader, TimeLineItem } from "../template";
import { translateError } from "../utils/alert";
import { mainApi } from "../utils/api";
import { format } from "date-fns";

const Preliminaries = ({ incident, workers }) => {
	const { t } = useTranslation();

	const [info, setInfo] = useState("");
	const [loading, setLoading] = useState(false);
	const [preliminaries, setPreliminaries] = useState([]);
	const [reload, setReload] = useState();

	const send = async () => {
		try {
			if (!info) throw new Error("preliminary.info");

			setLoading(true);

			await mainApi({
				url: "/preliminary",
				method: "POST",
				data: { info, incident, workers },
			});

			setInfo();
			setReload(new Date());
		} catch (err) {
			translateError(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const fetchPreliminaries = async () => {
			try {
				setLoading(true);

				const response = await mainApi({
					url: "/preliminary/table",
					method: "POST",
					data: { find: { incident }, sort: { created: 1 }, limit: 100 },
				});

				setPreliminaries(response.data.items);
			} catch (err) {
				translateError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchPreliminaries();
	}, [incident, reload]);

	return (
		<div className="relative">
			{loading && <Loader />}
			{preliminaries.map((pre, index) => (
				<TimeLineItem key={pre._id} active={index === preliminaries.length - 1}>
					<div className="mb-2 pb-4">
						<div className="flex mt-1">
							<div className="w-48">
								{pre.created &&
									format(new Date(pre.created), "yyyy-MM-dd HH:mm:ss")}
							</div>
							<div>{pre.info}</div>
						</div>
					</div>
				</TimeLineItem>
			))}
			<div>
				<Text
					type="textarea"
					value={info}
					onChange={(e) => {
						setInfo(e);
					}}
				></Text>
			</div>

			<div className="flex justify-end mt-4">
				<div>
					<button
						type="button"
						className="secondary"
						onClick={() => {
							send();
						}}
					>
						<RiSendPlaneFill className="text-2xl mr-2" />
						{t("button.send")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Preliminaries;
