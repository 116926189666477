import * as XLSX from "xlsx";
import objectPath from "object-path";
import { format, parseISO } from "date-fns";
import { t } from "i18next";

export const exportToExcel = async (filename, columns, rows) => {
	columns = columns.filter((column) => !column.nex);

	const header = columns.map((column) => {
		return t(column.label);
	});

	const data = rows.map((row) => {
		return columns.map((column) => adaptRender(row, column));
	});

	let wb = XLSX.utils.book_new();
	let ws = XLSX.utils.json_to_sheet([header, ...data], {
		skipHeader: true,
	});

	ws["!cols"] = columns.map(() => ({
		wch: 25,
	}));

	XLSX.utils.book_append_sheet(wb, ws);

	return XLSX.writeFile(wb, `${filename}.xlsx`);
};

export const adaptRender = (row, { key, render, ex }) => {
	if (ex) return objectPath.get(row, ex);

	if (typeof render === "function") {
		return render(row);
	}

	if (typeof render === "object") {
		const { type, field } = render;
		const value = objectPath.get(row, field);

		if (type === "trans") {
			return t(`${render.tPath}.${value}`);
		}

		if (type === "date" && value) {
			return format(parseISO(value), "yyyy-MM-dd HH:mm:ss");
		}

		return value;
	}

	return objectPath.get(row, key);
};
