import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	createdByCol,
	translateCell,
	nameFormat,
} from "../utils/format";

const LABEL = {
	hazard: "hazard.workers",
};

const TaskTable = ({ type }) => {
	const { t } = useTranslation();

	return (
		<Table
			key={type}
			url="/task/table"
			defaultFind={{ model: type }}
			columns={[
				{ key: "identifier", label: "task.identifier", filter: true },
				{
					key: "status",
					label: "task.status",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/task_status",
					},
					render: (row) => translateCell(row, t, "task_status", "status"),
				},
				{
					key: "workers",
					label: LABEL[type],
					sortable: true,
					filter: {
						type: "select",
						axio: `/const/select/user`,
						labelTemplate:
							// eslint-disable-next-line no-template-curly-in-string
							"${firstname} ${lastname} (${position})",
						nullType: "array",
					},
					width: 220,
					render: ({ workers }) => {
						return (
							Array.isArray(workers) &&
							workers.length > 0 &&
							workers.map(({ firstname, lastname }, index) => (
								<div key={index} className="flex mb-1">
									{nameFormat(firstname, lastname)}
								</div>
							))
						);
					},
				},
				{
					key: "deadline",
					label: "task.deadline",
					sortable: true,
					filter: { type: "date" },
					width: 220,
					render: (row) => formatDate(row, "deadline"),
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default TaskTable;
