import { useTranslation } from "react-i18next";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../list/Table";
import { formatDate, translateCell } from "../utils/format";

const ACTIONS = {
	add_alert: "alert",
	add_checklist: "checklist",
	add_hazard: "hazard",
	add_inspection: "inspection",
	add_permission: "permission",
	add_step: "step",
	add_violation: "violation",
	add_incident: "incident",
	sign_law: "law",
	sign_procedure: "procedure",
	sign_sds: "sds",
	sign_induction: "induction",
	sign_training: "training",
};
const EarnedPoints = ({ id }) => {
	const { t } = useTranslation();

	return (
		<Table
			url="/point/table"
			limit={10}
			height="auto"
			defaultFind={{ user: id }}
			renderItem={({ _id, action, info, created, point, id: modelId }) => (
				<div key={_id} className="mb-2 pb-2 border-b flex flex-col md:flex-row">
					<div className="text-2xl font-bold flex flex-shrink-0">
						+ {point}
						<div className="lowercase ml-1">{t("model.point")}</div>
					</div>
					<div className="flex-grow mx-4">
						<div className="flex justify-between text-sm text-gray-500">
							<div>{translateCell(action, t, "point_action")}</div>
							<div>{formatDate(created)}</div>
						</div>
						<div className="flex">
							<div>{info}</div>
						</div>
					</div>
					<div>
						<Link
							to={`/admin/pnl-user/${ACTIONS[action]}/${modelId}`}
							className="button secondary"
						>
							{t("button.more")}
							<HiArrowNarrowRight className="text-lg ml-1" />
						</Link>
					</div>
				</div>
			)}
		/>
	);
};

export default EarnedPoints;
