import Table from "../list/Table";
import {
	formatDate,
	formatString,
	createdByCol,
	formatLink,
} from "../utils/format";

const AlertTable = () => {
	return (
		<Table
			url="/alert/table"
			columns={[
				{
					key: "info",
					label: "alert.info",
					sortable: true,
					filter: true,
					render: (row) => formatLink(row, "info", `${row._id}`),
				},
				{
					key: "comment_count",
					label: "fields.comment_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				{
					key: "unit",
					label: "fields.unit",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/unit",
					},
					render: (row) => formatString(row, "unit.name"),
				},
				createdByCol,
			]}
		/>
	);
};

export default AlertTable;
