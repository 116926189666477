import objectPath from "object-path";
import { RiMapPin2Line } from "react-icons/ri";
import { CreatedBy, SubStatus } from "../template";

const AlarmView = ({ id, createdby, created, info, location }) => {
	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
				<SubStatus
					right={
						<div className="flex">
							<RiMapPin2Line className="mr-1" />
							{objectPath.get(location, "name")}
						</div>
					}
				></SubStatus>
				<div className="text-sm my-4">{info}</div>
			</div>
		</div>
	);
};

export default AlarmView;
