import Comments from "../comment/View";
import { useTranslation } from "react-i18next";
import { CreatedBy, Gallery, Status, SubStatus } from "../template";
import { formatPrice } from "../utils/format";

const PpeItem = ({
	id,
	createdby,
	created,
	status,
	info,
	item,
	name,
	total,
	quantity,
}) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
				<Status
					color={status === "notreceived" ? "red" : "green"}
					left={`${quantity} ${t("ppe.unit")}`}
					right={t(`const.ppe_status.${status}`)}
				/>
				<SubStatus left={name} right={formatPrice(total)} />
				{info && <div className="mb-4 uppercase font-semibold">{info}</div>}
				<Gallery files={item.media} />
			</div>
			<div>
				<Comments model="ppe" id={id} />
			</div>
		</div>
	);
};

export default PpeItem;
