import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatLink,
	createdByCol,
	translateCell,
} from "../utils/format";

const CateogryTable = ({ type, parentable }) => {
	const { t } = useTranslation();

	return (
		<Table
			key={type}
			url="/category/table"
			defaultFind={{ type }}
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button primary">
								{t("button.create")}
								<HiArrowNarrowRight className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				type
					? null
					: {
							key: "type",
							label: "category.type",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/static/category_type",
							},
							render: (row) => translateCell(row, t, "category_type", "type"),
					  },
				["incident", "cause"].includes(type) && {
					key: "parent",
					label: "category.parent",
					sortable: true,
					filter: { type: "select", axio: `/const/category/${type}` },
					render: (row) => row.parent?.name,
				},
				{
					key: "name",
					label: "category.name",
					sortable: true,
					filter: true,
					render: (row) => formatLink(row, "name", `${row._id}`),
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default CateogryTable;
