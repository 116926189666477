import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import { formatDate, formatLink, createdByCol } from "../utils/format";
import { RiOrganizationChart } from "react-icons/ri";

const UnitTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/unit/table"
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4 flex gap-2">
							<Link to="diagram/view" className="button secondary flex-1">
								{t("button.show_diagram")}
								<RiOrganizationChart className="text-lg ml-1" />
							</Link>
							<div className="w-64">
								<Link to="new" className="button primary">
									{t("button.create")}
									<HiArrowNarrowRight className="text-lg ml-1" />
								</Link>
							</div>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "parent",
					label: "unit.parent",
					sortable: true,
					filter: { type: "select", axio: "/const/select/unit" },
					render: ({ parent }) => parent?.name,
				},
				{
					key: "name",
					label: "unit.name",
					sortable: true,
					filter: true,
					render: (row) => formatLink(row, "name", `${row._id}`),
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default UnitTable;
