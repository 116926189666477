import Comments from "../comment/View";
import { useTranslation } from "react-i18next";
import {
	CreatedBy,
	Gallery,
	Status,
	StatusScore,
	SubStatus,
	Workers,
	History,
} from "../template";
import objectPath from "object-path";
import { RiMapPin2Line } from "react-icons/ri";

const ViolationView = ({
	id,
	createdby,
	created,
	status,
	assessment_name,
	info,
	category,
	location,
	attachments,
	workers,
	worker_count,
	history,
	hidden,
	violator,
	assessment,
}) => {
	const { t } = useTranslation();
	const color = status === "completed" ? "green" : "red";

	return (
		<div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
				<div>
					<CreatedBy
						user={createdby}
						date={created}
						hidden={hidden}
					></CreatedBy>
					<Status
						color={color}
						left={
							<div className="flex items-center">
								<StatusScore color={color} value={assessment} />
								{t(`const.assessment.${assessment_name}`)}
							</div>
						}
						right={t(`const.violation_status.${status}`)}
					></Status>
					<SubStatus
						left={objectPath.get(category, "name")}
						right={
							<div className="flex">
								<RiMapPin2Line className="mr-1" />
								{objectPath.get(location, "name")}
							</div>
						}
					></SubStatus>
					<div className="info">{info}</div>
					<Gallery files={attachments} />
					<Workers
						workers={violator}
						label={`${t("violation.violator")} (${
							violator && violator.length
						}):`}
					/>
					<Workers
						workers={workers}
						label={`${t("violation.workers")} (${worker_count}):`}
					/>
				</div>
				<div>
					<History
						items={history}
						status_root="violation_status"
						hidden={(st) => hidden && st === "pending"}
					/>
				</div>
				<div>
					<Comments model="violation" id={id} />
				</div>
			</div>
		</div>
	);
};

export default ViolationView;
