import * as Yup from "yup";
import { Field, Form } from "../template";
import sha256 from "crypto-js/sha256";
import {
	emailValidation,
	passwordValidation,
	phoneValidation,
	selectValidation,
	stringValidation,
} from "../utils/validations";
import { FileUpload, Select, Text, Toggle } from "../inputs";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { useAuth } from "../utils/auth";
import View from "./View";
import { successAlert } from "../utils/alert";

const UserForm = ({ editable, profile }) => {
	const { user } = useAuth();
	const { id } = useParams();
	const props = {
		editable,
		model: "user",
		id: id || (profile && user.id),
		init: {
			profile: [],
			phone: "",
			lastname: "",
			firstname: "",
			position: "",
			email: "",
			...(user.role === "superior"
				? null
				: {
						role: "",
						unit: "",
				  }),
			...(profile ? { password_new: "", password_verify: "" } : null),
		},
		validationSchema: Yup.object().shape({
			phone: phoneValidation(true),
			lastname: stringValidation(true),
			firstname: stringValidation(true),
			position: stringValidation(true),
			email: emailValidation(true),
			...(user.role === "superior"
				? null
				: {
						role: selectValidation(true),
						unit: selectValidation(true),
				  }),
			...(profile
				? {
						password_new: passwordValidation(false).nullable(),
				  }
				: null),
		}),
		success: profile
			? () => {
					window.location.reload();
			  }
			: null,
		view: ["superior"].includes(user.role)
			? null
			: (values) => (
					<View {...values} key={id} id={id || (profile && user.id)} />
			  ),
		beforeSubmit: (values) => {
			if (values.password_new) {
				if (values.password_new !== values.password_verify)
					throw new Error("user.password_notmatch");
				return {
					...values,
					password: sha256(values.password_new).toString(),
				};
			}
			return values;
		},
	};

	const changePasswordToDefault = async (
		{ setFieldValue, submitForm },
		{ phone }
	) => {
		setFieldValue("password", sha256(phone).toString());
		await successAlert(
			"user.reset_password",
			`<br/>${t("user.password_new")}: ${phone}`
		);
		submitForm();
	};

	return (
		<Form {...props}>
			{({ disabled, submited, values, form }) => {
				const adminAccess =
					disabled || !["superior", "admin"].includes(user.role);
				return (
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
						<div>
							<Field
								name="profile"
								label="user.profile"
								component={FileUpload}
								disabled={disabled}
								submited={submited}
							/>
							{!profile && (
								<div>
									<h1 className="bordered">{t("title.access_config")}</h1>
									<Field
										name="active"
										label="user.active"
										disabled={adminAccess}
										component={Toggle}
										inline
										reverse
									/>
									<Field
										name="role"
										label="user.role"
										disabled={adminAccess}
										component={Select}
										axio="/const/static/role"
									/>
									{values.role === "moderator" && (
										<Field
											name="accesses"
											label="user.accesses"
											disabled={adminAccess}
											component={Select}
											axio="/const/static/accesses"
											isMulti={true}
										/>
									)}
									<button
										className="button primary"
										type="button"
										onClick={() => {
											changePasswordToDefault(form, values);
										}}
									>
										{t("button.reset_password")}
									</button>
								</div>
							)}
						</div>
						<div>
							<Field
								name="lastname"
								label="user.lastname"
								disabled={disabled}
							/>
							<Field
								name="firstname"
								label="user.firstname"
								disabled={disabled}
							/>
							<Field
								name="position"
								label="user.position"
								disabled={disabled}
							/>
							{values.org && (
								<Field
									name="unit"
									label="user.unit"
									disabled={disabled}
									component={Select}
									axio={`/const/select/unit/${values.org}`}
								/>
							)}
							<Field
								name="phone"
								label="user.phone"
								type="number"
								disabled={disabled}
								component={Text}
							/>
							<Field name="email" label="user.email" disabled={disabled} />
						</div>
						{profile && (
							<div className="col-span-2 grid md:grid-cols-2 grid-cols-2 gap-8">
								<h1 className="bordered col-span-2">
									{t("title.change_password")}
								</h1>
								<Field
									name="password_new"
									label="user.password_new"
									type="password"
									disabled={disabled}
								/>
								<Field
									name="password_verify"
									label="user.password_verify"
									type="password"
									disabled={disabled}
								/>
							</div>
						)}
					</div>
				);
			}}
		</Form>
	);
};

export default UserForm;
