import packageJson from "../package.json";

export const DEBUG = process.env.NODE_ENV === "development";
export const VERSION = packageJson.version;
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const LOGO = process.env.REACT_APP_LOGO;
export const ENV = process.env.REACT_APP_ENV;
export const COMPANY = process.env.REACT_APP_COMPANY;
export const SYS = "ADMIN";
export const GOOGLE_TAG = process.env.REACT_APP_GOOGLE_TAG;
export const GOOGLE_MAP_KEY = "AIzaSyAc3kYQN80ZGKS1erezPH-nsVSuQsX8MeY";
export const GOOGLE_MAP_CENTER = {
	lat: 47.917837389284216,
	lng: 106.91883066563094,
};
export const YEAR = () => {
	return new Date().getFullYear();
};
export const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";
export const timeOut = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
export const i18NKEY = "i18next-" + ENV;
export const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG;
