import Table from "../list/Table";
import {
	formatDate,
	formatString,
	createdByCol,
	formatLink,
} from "../utils/format";

const StepTable = () => {
	return (
		<Table
			url="/step/table"
			columns={[
				{
					key: "task",
					label: "step.task",
					sortable: true,
					filter: true,
					render: (row) => formatLink(row, "task", `${row._id}`),
				},
				{
					key: "template",
					label: "step.template",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/step-template",
					},
					render: (row) => formatString(row, "template.name"),
				},
				{
					key: "location",
					label: "step.location",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/location",
					},
					render: (row) => formatString(row, "location.name"),
				},
				{
					key: "total",
					label: "step.total",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "normal_count",
					label: "step.normal_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "issue_count",
					label: "step.issue_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "comment_count",
					label: "fields.comment_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				{
					key: "unit",
					label: "fields.unit",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/unit",
					},
					render: (row) => formatString(row, "unit.name"),
				},
				createdByCol,
			]}
		/>
	);
};

export default StepTable;
