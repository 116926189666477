import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { stringValidation } from "../utils/validations";
import { Text } from "../inputs";
import View from "./View";

const ChecklistForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable: false,
		model: "checklist",
		id,
		init: {
			identifier: "",
		},
		validationSchema: Yup.object().shape({
			identifier: stringValidation(true),
		}),
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props}>
			{({ disabled }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="identifier"
								label="checklist.identifier"
								disabled={disabled}
								component={Text}
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default ChecklistForm;
