import { useTranslation } from "react-i18next";
import { DatePicker, Text } from "../inputs";
import { Media } from "../template";

const PREVIEW = {
	short_text: <Text></Text>,
	long_text: <Text type="textarea"></Text>,
	number: <Text type="number"></Text>,
	date: <DatePicker></DatePicker>,
};

const QuestionPreview = (props) => {
	const { question, media, type, options, hidetitle, number } = props;
	const { t } = useTranslation();

	return (
		<div>
			{!hidetitle && (
				<div className="mb-2 text-md">
					{t("survey-template.question-preview")}
				</div>
			)}
			<div>
				{number}. {question}
			</div>
			<div className="my-2 w-full md:w-1/2">
				<Media files={media}></Media>
			</div>
			{["multiple_choices", "single_choice"].includes(type) ? (
				<div className="mx-4 flex-col">
					{options.map((option) => (
						<div className="flex">
							{
								<input
									className="w-4 mr-2"
									type={type === "multiple_choices" ? "checkbox" : "radio"}
								></input>
							}
							<label>{option}</label>
						</div>
					))}
				</div>
			) : (
				PREVIEW[type]
			)}
		</div>
	);
};

export default QuestionPreview;
