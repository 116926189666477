import * as Yup from "yup";
import { Field, Form } from "../template";
import sha256 from "crypto-js/sha256";
import {
	emailValidation,
	phoneValidation,
	selectValidation,
	stringValidation,
} from "../utils/validations";
import { FileUpload, Select, Text } from "../inputs";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { useAuth } from "../utils/auth";
import { successAlert } from "../utils/alert";

const UserForm = ({ editable, editable_password }) => {
	const { user } = useAuth();
	const { id } = useParams();
	const props = {
		editable,
		model: "user",
		id,
		init: {
			profile: [],
			role: "",
			phone: "",
			lastname: "",
			firstname: "",
			position: "",
			email: "",
		},
		validationSchema: Yup.object().shape({
			role: selectValidation(true),
			phone: phoneValidation(true),
			lastname: stringValidation(true),
			firstname: stringValidation(true),
			position: stringValidation(true),
			email: emailValidation(true),
		}),
		beforeSubmit: (values) => {
			if (values.password_new) {
				if (
					values.password_new !== values.password_verify &&
					!editable_password
				)
					throw new Error("user.password_notmatch");
				return {
					...values,
					password: sha256(values.password_new).toString(),
				};
			}

			return values;
		},
	};

	const changePasswordToDefault = async (
		{ setFieldValue, submitForm },
		{ phone }
	) => {
		setFieldValue("password", sha256(phone).toString());
		await successAlert(
			"user.reset_password",
			`<br/>${t("user.password_new")}: ${phone}`
		);
		submitForm();
	};

	return (
		<>
			<h1 className="bordered">
				{t("title.edit", { param: t("title.manager") })}
			</h1>
			<Form {...props}>
				{({ disabled, submited, values, form }) => {
					return (
						<div className="p-4 bg-white grid grid-cols-1 lg:grid-cols-2 gap-8">
							<div>
								<Field
									name="profile"
									label="user.profile"
									component={FileUpload}
									disabled={disabled}
									submited={submited}
								/>
								<Field
									name="role"
									label="user.role"
									component={Select}
									disabled={disabled}
									axio="/const/static/manager_role"
								/>
								{id && user.role === "superior" && !editable_password && (
									<button
										className="button primary"
										type="button"
										onClick={() => {
											changePasswordToDefault(form, values);
										}}
									>
										{t("button.reset_password")}
									</button>
								)}
							</div>
							<div>
								<Field
									name="lastname"
									label="user.lastname"
									disabled={disabled}
								/>
								<Field
									name="firstname"
									label="user.firstname"
									disabled={disabled}
								/>
								<Field
									name="position"
									label="user.position"
									disabled={disabled}
								/>
								<Field
									name="phone"
									label="user.phone"
									type="number"
									disabled={disabled}
									component={Text}
								/>
								<Field name="email" label="user.email" disabled={disabled} />
								{editable_password && (
									<Field
										name="password_new"
										label="user.password"
										disabled={disabled}
									/>
								)}
							</div>
						</div>
					);
				}}
			</Form>
		</>
	);
};

export default UserForm;
