import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatMedia,
	formatDate,
	formatLink,
	createdByCol,
	translateCell,
} from "../utils/format";

const GroupTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/ppe-item/table"
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button primary">
								{t("button.create")}
								<HiArrowNarrowRight className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "media",
					label: "ppe-item.media",
					render: (row) => formatMedia(row, "media", "name"),
					center: true,
				},
				{
					key: "type",
					label: "ppe-item.type",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/ppe_type",
					},
					render: (row) => translateCell(row, t, "ppe_type", "type"),
				},
				{
					key: "name",
					label: "ppe-item.name",
					sortable: true,
					filter: true,
					render: (row) => formatLink(row, "name", `${row._id}`),
				},
				{
					key: "balance",
					label: "ppe-item.balance",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default GroupTable;
