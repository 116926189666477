import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const TabLayout = ({ items = [], children }) => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="flex gap-2 border-b border-def-primary">
				{items.map(
					({ path, label, icon }) =>
						path && (
							<NavLink
								key={path}
								to={`/admin/${path}`}
								className={({ isActive }) =>
									`${
										isActive
											? "text-white bg-def-primary nav-active"
											: "bg-def-gray"
									} items-center cursor-pointer p-2 pr-4 flex justify-center md:justify-start hover:bg-def-primary hover:text-white text-xl`
								}
							>
								{icon && <span className="mr-2">{icon}</span>}
								<span className="text-sm">{t(`menu.${label}`)}</span>
							</NavLink>
						)
				)}
			</div>
			<div className="p-4 bg-white">{children}</div>
		</div>
	);
};

export default TabLayout;
