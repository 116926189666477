import { Form as FormikForm, Formik } from "formik";
import * as Yup from "yup";
import { emailValidation, stringValidation } from "../utils/validations";
import { DatePicker, Select, Text } from "../inputs";
import { Field, Loader } from "../template";
import { successAlert, translateError } from "../utils/alert";
import { mainApi } from "../utils/api";
import contries from "./contries.json";
import { ENV } from "../defines";
import { useTranslation } from "react-i18next";

const DemoForm = () => {
	const { t } = useTranslation();

	const formik = {
		enableReinitialize: true,
		initialValues: {
			name: "",
			email: "",
			company: "",
			phone: "",
			country: "",
			emp: "",
			info: "",
			date: null,
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			email: emailValidation(true),
			company: stringValidation(true),
			phone: stringValidation(true),
			country: stringValidation(true).nullable(),
			emp: stringValidation(true).nullable(),
			date: Yup.date().required(),
		}),
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				await mainApi({
					url: `/public/demo-request`,
					method: "POST",
					data: {
						...values,
						date: values.date.toString(),
						env: ENV,
					},
				});

				setSubmitting(false);
				resetForm();
				successAlert();
			} catch (error) {
				translateError(error);
			}
		},
	};

	return (
		<Formik {...formik}>
			{({ isSubmitting, values, ...rest }) => {
				return (
					<FormikForm className="relative" autoComplete="off">
						<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
							<div>
								{isSubmitting && <Loader />}
								<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
									<Field
										error={t("landing.demo.validation")}
										name="name"
										placeholder={t("landing.demo.name")}
										component={Text}
									/>
									<Field
										error={t("landing.demo.validation")}
										name="email"
										placeholder={t("landing.demo.email")}
										component={Text}
									/>
								</div>
								<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
									<Field
										error={t("landing.demo.validation")}
										name="company"
										placeholder={t("landing.demo.company")}
										component={Text}
									/>
									<Field
										error={t("landing.demo.validation")}
										name="phone"
										placeholder={t("landing.demo.phone")}
										component={Text}
									/>
								</div>
								<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
									<Field
										error={t("landing.demo.validation")}
										name="country"
										placeholder={t("landing.demo.country")}
										component={Select}
										items={contries.map((v) => ({ value: v, label: v }))}
									/>
									<Field
										error={t("landing.demo.validation")}
										name="emp"
										placeholder={t("landing.demo.emp")}
										component={Select}
										isSearchable={false}
										items={["1-50", "50-500", "500-1000", "1000+"].map((v) => ({
											value: v,
											label: v,
										}))}
									/>
								</div>
								<Field
									name="info"
									placeholder={t("landing.demo.info")}
									component={Text}
									type="textarea"
								/>
							</div>
							<div className="flex flex-col">
								<Field
									error={t("landing.demo.validation")}
									name="date"
									component={DatePicker}
									open={true}
									input={false}
								/>
								<div className="w-32 self-end">
									<button type="submit" className="secondary">
										{t("landing.demo.submit")}
									</button>
								</div>
							</div>
						</div>
					</FormikForm>
				);
			}}
		</Formik>
	);
};

export default DemoForm;
