import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { stringValidation, arrayValidation } from "../utils/validations";
import { Select, Text } from "../inputs";
import { DEBUG } from "../defines";
import { randomData } from "../utils/demo";

const GroupForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "group",
		id,
		init: {
			name: DEBUG ? randomData("group") : "",
			members: [],
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			members: arrayValidation(true),
		}),
	};

	return (
		<Form {...props} containerClass="w-full md:w-1/2">
			{({ disabled }) => {
				return (
					<div>
						<Field
							name="type"
							label="group.type"
							disabled={disabled}
							component={Select}
							axio="/const/static/group_type"
						/>
						<Field
							name="name"
							label="group.name"
							disabled={disabled}
							component={Text}
						/>
						<Field
							name="members"
							label="group.members"
							disabled={disabled}
							component={Select}
							axio="/const/select/user"
							isMulti={true}
							// eslint-disable-next-line no-template-curly-in-string
							labelTemplate="${firstname} ${lastname} (${position})"
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default GroupForm;
