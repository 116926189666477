import { useTranslation } from "react-i18next";
import { Watermark } from "../../template";
import PngExportable from "../PngExportable";
import Table from "../Table";
import Day from "../Day";
import Unit from "../Unit";
import Hour from "../Hour";
import TableExportable from "../TableExportable";
import CreatedBy from "../CreatedBy";

const AlertReport = ({ start, end, day, unit, hour, table, createdby }) => {
	const { t } = useTranslation();

	return (
		<div>
			<PngExportable title={t(`report_title.alert_day`, { start, end })}>
				<div style={{ height: 500 }} className="relative">
					<Watermark />
					<Day
						data={day.map(({ _id, total }) => ({
							_id,
							[t(`total`)]: total,
						}))}
					></Day>
				</div>
			</PngExportable>

			<PngExportable title={t(`report_title.alert_unit`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table data={unit} columns={["total"]} />
				</div>
				<div className="mt-4" style={{ height: 600 }}>
					<Unit
						data={unit.map(({ total, name }) => {
							return {
								name,
								children: [
									{
										name: t(`total`),
										total: total,
										color: "#D90429",
									},
								],
							};
						})}
					></Unit>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.alert_createdby`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<CreatedBy data={createdby}></CreatedBy>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.alert_hour`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Hour
						data={hour}
						renderColor={(value) => {
							if (value < 6) return "bg-def-green text-white";
							if (value > 5 && value < 11) return "bg-def-secondary text-white";
							if (value > 10) return "bg-def-red text-white";
						}}
					/>
				</div>
			</PngExportable>
			<div className="relative">
				<Watermark />
				<TableExportable
					title={t(`report_title.alert_table`, { start, end })}
					find={{ start, end }}
					url="/report/alert/export"
					data={table}
					columns={[
						{
							label: t("fields.unit"),
							render: { type: "string", field: "unit.name" },
						},
						{
							label: t("fields.createdby"),
							render: ({ createdby }) =>
								`${createdby.firstname} ${createdby.lastname} (${createdby.position})`,
						},
						{
							label: t("alert.info"),
							render: { type: "string", field: "info" },
						},
						{
							label: t("fields.created"),
							render: { type: "date", field: "created" },
						},
					]}
				/>
			</div>
		</div>
	);
};

export default AlertReport;
