import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form, FieldArray } from "../template";
import { stringValidation } from "../utils/validations";
import { Text, Select } from "../inputs";
import { t } from "i18next";
import View from "./View";

const StepTemplateForm = ({ editable, role }) => {
	const isPublic = role === "superior";
	const { id } = useParams();
	const props = {
		editable,
		model: "step-template",
		id,
		init: {
			name: "",
			questions: [],
			workers: [],
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			questions: Yup.array().of(
				Yup.object().shape({
					question: stringValidation(true, 5000),
					tip: stringValidation(true, 5000),
				})
			),
		}),
		beforeSubmit: (values) => {
			const { questions } = values;

			if (questions.length < 1)
				throw new Error("step-template.undefined_questions");
			return values;
		},
		view: (values) => <View {...values} key={id} id={id} isPublic={isPublic} />,
	};

	return (
		<Form {...props}>
			{({ disabled }) => {
				return (
					<div>
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div>
								<Field
									name="name"
									label="step-template.name"
									disabled={disabled}
									component={Text}
								/>
								{!isPublic && (
									<Field
										name="point"
										label="step-template.point"
										disabled={disabled}
										component={Select}
										isSearchable={false}
										axio="/const/static/point"
									/>
								)}
							</div>
							{/* {!isPublic && (
								<Field
									name="workers"
									label="step-template.workers"
									disabled={disabled}
									component={Select}
									axio="/const/select/user"
									isMulti={true}
									// eslint-disable-next-line no-template-curly-in-string
									labelTemplate="${firstname} ${lastname} (${position})"
								/>
							)} */}
						</div>
						<FieldArray
							name="questions"
							label="step-template.questions"
							disabled={disabled}
							initial={{ question: "", tip: "" }}
							renderItem={(question, qIndex) => (
								<>
									<Field
										name={`questions.${qIndex}.question`}
										disabled={disabled}
										component={Text}
									/>
									<Field
										name={`questions.${qIndex}.tip`}
										disabled={disabled}
										component={Text}
										type="textarea"
										placeholder={t("step-template.tip")}
									/>
								</>
							)}
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default StepTemplateForm;
