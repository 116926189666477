import { Treemap, ResponsiveContainer, Tooltip } from "recharts";

const UnitCell = (props) => {
	const { depth, x, y, width, height, color, name, total } = props;
	const isUnit = depth === 1;
	const display = typeof name === "string" ? name.toUpperCase() : name;

	return (
		<g>
			{total > 0 ? (
				<rect
					x={x}
					y={y + 22}
					width={width}
					height={height}
					fill={color}
				></rect>
			) : (
				<rect
					x={x}
					y={y}
					width={width}
					height={height}
					style={{
						fill: "#14213D",
						stroke: isUnit && "#fff",
					}}
				/>
			)}
			<text x={x + 4} y={y + 16 + (isUnit ? 0 : 22)} fill="#fff" fontSize={12}>
				{display} {isUnit ? "" : `(${total})`}
			</text>
		</g>
	);
};

const Unit = ({ data }) => {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<Treemap
				width={400}
				height={200}
				data={data}
				dataKey="total"
				nameKey="name"
				ratio={4 / 3}
				fill="#8884d8"
				content={<UnitCell />}
			>
				<Tooltip />
			</Treemap>
		</ResponsiveContainer>
	);
};

export default Unit;
