import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { stringValidation } from "../utils/validations";
import { Select, Text, FileUpload } from "../inputs";
import { DEBUG } from "../defines";

const DocumentForm = ({ editable }) => {
	const { id, parent } = useParams();
	const props = {
		editable,
		model: "document",
		id,
		init: {
			type: DEBUG ? "dir" : "",
			name: DEBUG ? "test" : "",
		},
		validationSchema: Yup.object().shape({
			type: stringValidation(true),
			name: stringValidation(true),
		}),
		beforeSubmit: (values) => {
			if (values.type === "file" && (!values.file || values.file.length === 0))
				throw new Error("document.undefined_file");
			const data = {
				...values,
				parent: parent === "undefined" ? null : parent,
			};
			return data;
		},
	};

	return (
		<Form {...props} containerClass="w-full md:w-1/2">
			{({ disabled, values, submited }) => {
				return (
					<div>
						<Field
							name="type"
							label="document.type"
							disabled={disabled}
							component={Select}
							axio="/const/static/document_type"
						/>
						<Field
							name="name"
							label="document.name"
							disabled={disabled}
							component={Text}
						/>
						{values.type === "file" && (
							<Field
								name="file"
								label="document.file"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								root="document"
								accept="application/pdf"
							/>
						)}
					</div>
				);
			}}
		</Form>
	);
};

export default DocumentForm;
