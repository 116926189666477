import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiStarFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { Back, Loader, Status, Video, CreatedBy } from "../template";
import { mainApi } from "../utils/api";
import ExamDetail from "./ExamDetail";

const TrainingDetail = () => {
	const { t } = useTranslation();
	const { detail } = useParams();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	useEffect(() => {
		const fetch = async (id) => {
			setLoading(true);
			const response = await mainApi(`/training/view/${id}`);
			response && setData(response.data);
			setLoading(false);
		};

		if (detail) fetch(detail);
	}, [detail]);

	return (
		<div className="relative">
			{loading && <Loader />}
			<div className="flex mb-4">
				<div>
					<Back />
				</div>
			</div>
			{data && (
				<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
					<div>
						<CreatedBy user={data.user} date={data.signed}></CreatedBy>
						<Status
							color={data?.training?.type === "exam" ? "red" : "green"}
							left={
								data?.training?.point && (
									<div className="flex items-center">
										<RiStarFill className="mr-1" />
										{data?.training?.point} {t("fields.has-point")}
									</div>
								)
							}
							right={t(`const.training_type.${data?.training?.type}`)}
						></Status>
						{data?.training?.name && (
							<div className="my-4 uppercase font-semibold">
								{data?.training?.name}
							</div>
						)}
						{data?.training?.info && (
							<div className="my-4">{data?.training?.info}</div>
						)}
						<Video file={data?.training?.media} />
					</div>
					{data?.training?.type === "exam" && (
						<ExamDetail {...data} t={t}></ExamDetail>
					)}
				</div>
			)}
		</div>
	);
};

export default TrainingDetail;
