import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatLink,
	createdByCol,
	translateCell,
} from "../utils/format";
import Signature from "../user/Signature";

const PpeTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/ppe/table"
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button primary">
								{t("button.create")}
								<HiArrowNarrowRight className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "item",
					label: "ppe-item.name",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/ppe-item",
					},
					render: (row) => formatLink(row, "item.name", `${row._id}`),
				},
				{
					key: "employee.lastname",
					label: "user.lastname",
					sortable: true,
					filter: true,
				},
				{
					key: "employee.firstname",
					label: "user.firstname",
					sortable: true,
					filter: true,
				},
				{
					key: "employee.position",
					label: "user.position",
					sortable: true,
					filter: true,
				},
				{
					key: "quantity",
					label: "ppe.quantity",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "total",
					label: "ppe.total",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "status",
					label: "ppe.status",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/ppe_status",
					},
					render: (row) => translateCell(row, t, "ppe_status", "status"),
				},
				{
					key: "signature",
					label: "user.signature",
					render: ({ signature }) => <Signature signature={signature} />,
				},
				{
					key: "signed",
					label: "ppe.signed",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "signed"),
				},
				{
					key: "comment_count",
					label: "fields.comment_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default PpeTable;
