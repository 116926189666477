import { useEffect, useState } from "react";
import {
    Radar,
    RadarChart,
    PolarGrid,
    Tooltip,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer,
    LabelList,
} from "recharts";
import { Loader } from "../template";
import { mainApi } from "../utils/api";
import { COLORS } from "./defines";

const GRadarChart = ({
    axio,
    name,
    labelKey,
    dataKey,
    color = "secondary",
    fillOpacity = 0.33,
    height = 300,
    ...restProps
}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const response = await mainApi(axio);
            response && setData(response.data);
            setLoading(false);
        };

        if (axio) fetch();
    }, [axio]);

    if (loading && !data) return <Loader />;

    return (
        <div className="flex w-full text-sm relative" style={{ height }}>
            {loading && <Loader />}
            <ResponsiveContainer>
                <RadarChart data={data}>
                    <PolarGrid radialLines={true} />
                    <PolarAngleAxis dataKey={labelKey} fontSize={10} />
                    <PolarRadiusAxis angle={90} fontSize={10} />
                    <Tooltip />
                    <Radar
                        name={name}
                        dataKey={dataKey}
                        stroke={COLORS[color]}
                        fill={COLORS[color]}
                        fillOpacity={fillOpacity}
                        connectNulls
                        {...restProps}
                    >
                        <LabelList angle={0} fontSize={10} />
                    </Radar>
                </RadarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default GRadarChart;
