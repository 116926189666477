import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatString,
	createdByCol,
	translateCell,
	formatLink,
	nameFormat,
} from "../utils/format";

const InspectionTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/inspection/table"
			columns={[
				{
					key: "category",
					label: "inspection.category",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/category/inspection",
					},
					render: (row) => formatLink(row, "category.name", `${row._id}`),
				},
				{
					key: "location",
					label: "inspection.location",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/location",
					},
					render: (row) => formatString(row, "location.name"),
				},
				{
					key: "workers",
					label: "inspection.workers",
					sortable: true,
					filter: {
						type: "select",
						axio: `/const/select/user`,
						labelTemplate:
							// eslint-disable-next-line no-template-curly-in-string
							"${firstname} ${lastname} (${position})",
						nullType: "array",
					},
					width: 220,
					render: ({ workers }) => {
						return (
							Array.isArray(workers) &&
							workers.length > 0 &&
							workers.map(({ firstname, lastname }, index) => (
								<div key={index} className="flex mb-1">
									{nameFormat(firstname, lastname)}
								</div>
							))
						);
					},
				},
				{
					key: "probability",
					label: "inspection.probability",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/probability",
					},
					render: (row) => translateCell(row, t, "probability", "probability"),
				},
				{
					key: "damage",
					label: "inspection.damage",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/damage",
					},
					render: (row) => translateCell(row, t, "damage", "damage"),
				},
				{
					key: "assessment",
					label: "inspection.assessment",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "assessment_name",
					label: "inspection.assessment_name",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/assessment",
					},
					render: (row) =>
						translateCell(row, t, "assessment", "assessment_name"),
				},
				{
					key: "status",
					label: "inspection.status",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/inspection_status",
					},
					render: (row) => translateCell(row, t, "inspection_status", "status"),
				},
				{
					key: "comment_count",
					label: "fields.comment_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				{
					key: "unit",
					label: "fields.unit",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/unit",
					},
					render: (row) => formatString(row, "unit.name"),
				},
				createdByCol,
			]}
		/>
	);
};

export default InspectionTable;
