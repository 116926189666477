import { useTranslation } from "react-i18next";
import { Back } from "../template";

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <div className="h-full flex flex-col items-center justify-center">
            <div>404 | {t("title.404")}</div>
            <div className="self-center mt-2">
                <Back />
            </div>
        </div>
    );
};

export default NotFound;
