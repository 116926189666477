import { useState } from "react";
import { useEffect } from "react";
import { RiFilePdfFill, RiFolder2Fill } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { Loader } from "../template";
import { mainApi } from "../utils/api";
import Breadcrumb from "./Breadcrumb";

const Explorer = () => {
	const { parent } = useParams();

	const [loading, setLoading] = useState(false);
	const [children, setChildren] = useState([]);
	const [current, setCurrent] = useState();

	useEffect(() => {
		fetchDocuments(parent);
	}, [parent]);

	const fetchDocuments = async (parent) => {
		setLoading(true);
		const response = await mainApi(`/document/${parent}/children`);
		if (response) {
			setChildren(response.data.children);
			setCurrent(response.data.current);
		}
		setLoading(false);
	};

	return (
		<div className="relative">
			{loading && <Loader />}
			<Breadcrumb {...current} />
			<div className="mt-2 grid grid-cols-6 xl:grid-cols-12 gap-4">
				{children.map(({ _id, name, createdby, type }) => {
					return (
						<Link
							key={_id}
							to={`/admin/pnl-document/document/${
								type === "dir" ? "" : `${parent}/edit/`
							}${_id}`}
							className="p-2 cursor border text-xs text-center"
						>
							<div
								className={`justify-center items-center flex text-5xl ${
									type === "dir" ? "text-def-secondary" : "text-gray-400"
								}`}
							>
								{type === "dir" ? <RiFolder2Fill /> : <RiFilePdfFill />}
							</div>
							{name}
						</Link>
					);
				})}
			</div>
		</div>
	);
};

export default Explorer;
