import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatLink,
	createdByCol,
	translateCell,
} from "../utils/format";
import { useAuth } from "../utils/auth";

const ContractTable = () => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const { role } = user;

	return (
		<div>
			<h1 className="bordered">
				{t("title.table", { param: t("title.contract") })}
			</h1>
			<div className="mt-4">
				<Table
					url="/contract/table"
					defaultFind={role === "superior" ? null : { createdby: user.id }}
					columns={[
						{
							key: "org_name",
							label: "model.org",
							sortable: true,
							filter: true,
						},
						{
							key: "number",
							label: "contract.number",
							sortable: true,
							filter: true,
							render: (row) =>
								formatLink(row, "number", `/admin/contract/${row._id}`),
						},
						{
							key: "status",
							label: "contract.status",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/static/contract_status",
							},
							render: (row) =>
								translateCell(row, t, "contract_status", "status"),
						},
						{
							key: "start",
							label: "contract.start",
							sortable: true,
							filter: { type: "date" },
							render: (row) => formatDate(row, "start"),
						},
						{
							key: "end",
							label: "contract.end",
							sortable: true,
							filter: { type: "date" },
							render: (row) => formatDate(row, "end"),
						},
						{
							key: "limit",
							label: "contract.limit",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "price",
							label: "contract.price",
							sortable: true,
							filter: { type: "number" },
							render: ({ price, currency }) =>
								`${price} ${currency ? currency : ""}`,
						},
						{
							key: "total",
							label: "contract.total",
							sortable: true,
							filter: { type: "number" },
							render: ({ total, currency }) =>
								`${total} ${currency ? currency : ""}`,
						},
						{
							key: "storage",
							label: "contract.storage",
							sortable: true,
							filter: { type: "number" },
							render: ({ storage }) => (storage ? `${storage} GB` : ""),
						},
						createdByCol,
					]}
				/>
			</div>
		</div>
	);
};

export default ContractTable;
