import { Loader } from "../template";
import { NotFound } from "../pages";
import { useAuth } from "../utils/auth";
import { useTranslation } from "react-i18next";
import { Routes, Route, NavLink, useLocation } from "react-router-dom";
import routes from "./routes";
import menu from "./menu";
import LanguageSwitcher from "../i18n/Switch";
import ProfileMenu from "./ProfileMenu";
import { useEffect } from "react";
import { LOGO, VERSION } from "../defines";

const moderatorAccess = (user, path) => {
	return (
		path &&
		user.role === "moderator" &&
		user.accesses &&
		!user.accesses.includes(path) &&
		!["profile"].includes(path)
	);
};

const AdminLayout = () => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const location = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [location]);

	if (!user) return <Loader />;
	if (user.role === "user") return <NotFound />;
	if (moderatorAccess(user, location.pathname.split("/")[2]))
		return <NotFound />;

	return (
		<div className="flex flex-col h-full">
			<div className="p-2 bg-white shadow-lg flex justify-between">
				<div className="self-center flex items-end">
					<img src={LOGO} alt="Gafety" className="h-7" />
					<div className="text-xs">v{VERSION}</div>
				</div>
				<div className="flex items-center">
					<LanguageSwitcher />
					<ProfileMenu {...user} />
				</div>
			</div>
			<div className="flex flex-1">
				<div className="bg-def-gray w-10 md:w-[220px]">
					{menu[user.role].access.map(({ path, icon, label }, index) => {
						if (path == null) {
							return (
								<div
									key={index}
									className="flex items-center justify-center md:justify-start text-xs mx-2 mt-3 border-b text-gray-500"
								>
									<div className="hidden md:block">{t(label)}</div>
									<div className="block md:hidden">-</div>
								</div>
							);
						}
						if (moderatorAccess(user, path)) return null;

						return (
							<NavLink
								key={index}
								end={path === ""}
								className={({ isActive }) =>
									`${
										isActive ? "text-white bg-def-primary nav-active" : ""
									} cursor-pointer p-2 flex justify-center md:justify-start hover:bg-def-primary hover:text-white border-b text-xl`
								}
								to={path}
							>
								<div className="self-start">{icon}</div>
								<div className="ml-2 text-sm hidden md:flex">{t(label)}</div>
							</NavLink>
						);
					})}
				</div>
				<div className="flex-1 p-4 overflow-x-auto">
					<Routes>
						{routes[user.role].map(({ path, element }, index) => (
							<Route
								key={index}
								index={index === 0}
								path={path}
								element={element}
							/>
						))}
					</Routes>
				</div>
			</div>
		</div>
	);
};

export default AdminLayout;
