import { RiFileExcel2Fill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { mainApi } from "../utils/api";
import { useState } from "react";
import { Loader } from "../template";
import { DEBUG } from "../defines";
import { adaptRender, exportToExcel } from "../utils/excel";

const OPACITY = {
	1: "opacity-100",
	2: "opacity-90",
	3: "opacity-80",
	4: "opacity-70",
	5: "opacity-60",
	6: "opacity-50",
	7: "opacity-40",
	8: "opacity-30",
	9: "opacity-20",
	10: "opacity-10",
	11: "opacity-5",
};

const TableExportable = ({
	title,
	columns,
	data = { list: [], count: 0 },
	find,
	url,
}) => {
	const { t } = useTranslation();
	const { list, count } = data;
	const [loading, setLoading] = useState(false);

	const onClickDownload = async () => {
		try {
			setLoading(true);
			const response = await mainApi({
				url,
				method: "POST",
				data: { ...find },
			});
			await exportToExcel(title, columns, response.data.list);
		} catch (error) {
			DEBUG && console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="p-10 pt-0 relative">
			{loading && <Loader />}
			<div className="text-center border-b m-2 p-2 border-def-secondary">
				{title}
			</div>
			<div className="w-full overflow-y-auto">
				<table className="report-table w-full">
					<thead>
						<tr>
							<td>#</td>
							{columns.map(({ label }, index) => {
								return <td key={index}>{label}</td>;
							})}
						</tr>
					</thead>
					<tbody>
						{list.map((row, index) => (
							<tr key={index} className={`${OPACITY[index]}`}>
								<td>{index + 1}</td>
								{columns.map((column, index) => {
									return <td key={index}>{adaptRender(row, column)}</td>;
								})}
							</tr>
						))}
					</tbody>
					<tbody>
						<tr>
							<td colSpan={columns.length + 1}>...</td>
						</tr>
						<tr>
							<td colSpan={columns.length + 1}>
								<div className="my-4 underline">
									{t("report_label.table_exportable", { count })}
								</div>
								<div className="flex justify-center mb-4">
									<div>
										<button
											className="secondary"
											onClick={() => {
												onClickDownload();
											}}
										>
											<RiFileExcel2Fill className="text-2xl mr-2" />
											{t("button.download")}
										</button>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default TableExportable;
