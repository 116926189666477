import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GoogleMap from "../map/GoogleMap";

const CoordinateSelect = ({
	form,
	field,
	value,
	onChange,
	disabled,
	hideHint,
}) => {
	const { t } = useTranslation();
	const [map, setMap] = useState();
	const [marker, setMarker] = useState();
	const [coords, setCoords] = useState(form ? field.value : value);
	const [display, setDisplay] = useState();

	const addMarker = (position) => {
		if (map && position && position.lat && position.lng) {
			marker && marker.setMap(null);

			const newMarker = new window.google.maps.Marker({
				styles: [
					{
						featureType: "poi",
						elementType: "labels",
						stylers: [{ visibility: "off" }],
					},
				],
				position,
				map,
			});

			map.panTo(position);
			setMarker(newMarker);
			setDisplay(`${position.lat} ${position.lng}`);
		}
	};

	useEffect(() => {
		if (!disabled) {
			if (form) {
				form.setFieldValue(field.name, coords);
			} else {
				onChange && onChange(coords);
			}

			addMarker(coords);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map, coords]);

	useEffect(() => {
		field && addMarker(field.value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map, field]);

	const props = {
		loaded: (_map) => {
			_map.addListener("click", (e) => {
				setCoords({
					lat: e.latLng.lat(),
					lng: e.latLng.lng(),
				});
			});
			setMap(_map);
		},
	};

	return (
		<div>
			<div className="text-xs flex justify-between">
				{!hideHint && <div>{t("control.map.hint")}</div>}
				{display && <div>{display}</div>}
			</div>
			<GoogleMap {...props} />
		</div>
	);
};

export default CoordinateSelect;
