import { Accordion, Media } from "../template";
import { formatDate } from "../utils/format";

const ExamDetail = ({
    t,
    exam_status,
    best,
    attempt_count,
    attempts,
    training,
}) => {
    const { threshold, duration, questions, question_count } = training;
    return (
        <div>
            <div className="uppercase mb-2 pb-2 border-b">
                {t("training-content.exam")}
            </div>
            <div className="text-sm mb-4">
                {duration && (
                    <div className="flex justify-between">
                        <div>{t("training-content.duration")}:</div>
                        <div>{duration}</div>
                    </div>
                )}
                {question_count && (
                    <div className="flex justify-between">
                        <div>{t("training-content.question_count")}:</div>
                        <div>{question_count}</div>
                    </div>
                )}
                {threshold && (
                    <div className="flex justify-between">
                        <div>{t("training-content.threshold")}:</div>
                        <div>{threshold}%</div>
                    </div>
                )}
                <div className="p-2"></div>
                {exam_status && (
                    <div className="flex justify-between">
                        <div>{t("training.exam_status")}:</div>
                        <div>{t(`const.exam_status.${exam_status}`)}</div>
                    </div>
                )}
                {attempt_count && (
                    <div className="flex justify-between">
                        <div>{t("training.attempt_count")}:</div>
                        <div>{attempt_count}</div>
                    </div>
                )}
                {best && (
                    <div className="flex justify-between">
                        <div>{t("training.best")}:</div>
                        <div>{best}%</div>
                    </div>
                )}
            </div>
            {attempt_count > 0 && (
                <div>
                    <div className="uppercase mb-2 pb-2 border-b">
                        {t("training.attempts")}
                    </div>
                    {Array.isArray(attempts) &&
                        attempts.map(
                            (
                                { date, answers, earned, percent = 10 },
                                index
                            ) => {
                                return (
                                    <div key={index} className="mb-2">
                                        <Accordion
                                            titleClass="text-sm p-2 bg-def-gray"
                                            title={
                                                <div className="flex justify-between flex-1">
                                                    <div>
                                                        {t("training.attempt")}{" "}
                                                        {index + 1} (
                                                        {percent &&
                                                            `${percent}% = `}
                                                        {earned}{" "}
                                                        {t("training.earned")})
                                                    </div>
                                                    <div>
                                                        {formatDate(date)}
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <div className="text-sm p-4">
                                                {Array.isArray(questions) &&
                                                    questions.map(
                                                        (
                                                            {
                                                                _id: question_id,
                                                                question,
                                                                attachment,
                                                                variants,
                                                            },
                                                            index
                                                        ) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="mb-2 pb-2 border-b border-dashed"
                                                                >
                                                                    {index + 1}.{" "}
                                                                    {question}
                                                                    <Media
                                                                        files={
                                                                            attachment
                                                                        }
                                                                    />
                                                                    <ul className="ml-8 my-2 list-outside">
                                                                        {Array.isArray(
                                                                            variants
                                                                        ) &&
                                                                            variants.map(
                                                                                (
                                                                                    {
                                                                                        _id: variant_id,
                                                                                        variant,
                                                                                        point,
                                                                                    },
                                                                                    index
                                                                                ) => {
                                                                                    const respond =
                                                                                        answers[
                                                                                            question_id
                                                                                        ] ===
                                                                                        variant_id;
                                                                                    return (
                                                                                        <li
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            className={`${
                                                                                                respond
                                                                                                    ? "font-semibold"
                                                                                                    : ""
                                                                                            }`}
                                                                                        >
                                                                                            {`${variant} ${
                                                                                                respond
                                                                                                    ? `(${point} 
                                                                                ${t(
                                                                                    "training.earned"
                                                                                )})`
                                                                                                    : ""
                                                                                            }`}
                                                                                        </li>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </ul>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </Accordion>
                                    </div>
                                );
                            }
                        )}
                </div>
            )}
        </div>
    );
};

export default ExamDetail;
