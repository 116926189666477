const AppPrivacy = () => {
	return (
		<div className="p-4 w-full md:w-1/2 mx-auto">
			<div className="p-4 mb-4 bg-white">
				<h1 className="mt-5">Privacy Policy</h1>
				<p>Last updated: April 24, 2023</p>
				<p>
					This Privacy Policy describes Our policies and procedures on the
					collection, use and disclosure of Your information when You use the
					Service and tells You about Your privacy rights and how the law
					protects You.
				</p>
				<p>
					We use Your Personal data to provide and improve the Service. By using
					the Service, You agree to the collection and use of information in
					accordance with this Privacy Policy.
				</p>
				<h1 className="mt-5">Interpretation and Definitions</h1>
				<h2>Interpretation</h2>
				<p>
					The words of which the initial letter is capitalized have meanings
					defined under the following conditions. The following definitions
					shall have the same meaning regardless of whether they appear in
					singular or in plural.
				</p>
				<h2>Definitions</h2>
				<p>For the purposes of this Privacy Policy:</p>
				<ul>
					<li>
						<p>
							<strong>Account</strong> means a unique account created for You to
							access our Service or parts of our Service.
						</p>
					</li>
					<li>
						<p>
							<strong>Application</strong> refers to Gafety, the safety software
							program provided by the Company.
						</p>
					</li>
					<li>
						<p>
							<strong>Company</strong> (referred to as either &quot;the
							Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
							in this Agreement) refers to Gafety Soft LLC, Sakura Tower ,
							Bayanzurkh district, Ulaanbaatar city, Mongolia.
						</p>
					</li>
					<li>
						<p>
							<strong>Country</strong> refers to: Mongolia
						</p>
					</li>
					<li>
						<p>
							<strong>Device</strong> means any device that can access the
							Service such as a computer, a cellphone or a digital tablet.
						</p>
					</li>
				</ul>
				<h1 className="mt-5">Collecting and Using Your Personal Data</h1>
				<h2>Types of Data Collected</h2>
				<h3>Personal Data</h3>
				<p>
					Personally identifiable information may include, but is not limited
					to:
				</p>
				<ul>
					<li>
						<p>First name and last name</p>
					</li>
					<li>
						<p>Phone number</p>
					</li>
					<li>
						<p>Usage Data</p>
					</li>
				</ul>
				<h3>Usage Data</h3>
				<p>Usage Data is not collected automatically when using the Service.</p>
				<h3>Information Collected while Using the Application</h3>
				<p>
					While using Our Application, in order to provide features of Our
					Application, We may collect, with Your prior permission:
				</p>
				<ul>
					<li>
						Pictures and other information from your Device's camera and photo
						library
					</li>
				</ul>
				<p>
					The information may be uploaded to the Company's servers and/or a
					Service Provider's server or it may be simply stored on Your device.
				</p>
				<p>
					You can enable or disable access to this information at any time,
					through Your Device settings.
				</p>
				<h2>Use of Your Personal Data</h2>
				<p>The Company may use Personal Data for the following purposes:</p>
				<ul>
					<li>
						<p>
							<strong>To manage Your Account:</strong> to manage Your
							registration as a user of the Service. The Personal Data You
							provide can give You access to different functionalities of the
							Service that are available to You as a registered user.
						</p>
					</li>
					<li>
						<p>
							<strong>To contact You:</strong>To contact You by email, or other
							equivalent forms of electronic communication, such as a mobile
							application's push notifications regarding updates or informative
							communications related to the functionalities, products or
							contracted services, including the security updates, when
							necessary or reasonable for their implementation.
						</p>
					</li>
					<li>
						<p>
							<strong>To manage Your requests:</strong> To attend and manage
							Your requests to Us.
						</p>
					</li>
				</ul>
				<h2>Retention of Your Personal Data</h2>
				<p>
					The Company will retain Your Personal Data only for as long as is
					necessary for the purposes set out in this Privacy Policy. We will
					retain and use Your Personal Data to the extent necessary to comply
					with our legal obligations (for example, if we are required to retain
					your data to comply with applicable laws), resolve disputes, and
					enforce our legal agreements and policies.
				</p>
				<h2>Security of Your Personal Data</h2>
				<p>
					The security of Your Personal Data is important to Us, but remember
					that no method of transmission over the Internet, or method of
					electronic storage is 100% secure. While We strive to use commercially
					acceptable means to protect Your Personal Data, We cannot guarantee
					its absolute security.
				</p>
				<h1 className="mt-5">Links to Other Websites</h1>
				<p>
					Our Service may contain links to other websites that are not operated
					by Us. If You click on a third party link, You will be directed to
					that third party's site. We strongly advise You to review the Privacy
					Policy of every site You visit.
				</p>
				<p>
					We have no control over and assume no responsibility for the content,
					privacy policies or practices of any third party sites or services.
				</p>
				<h1 className="mt-5">Changes to this Privacy Policy</h1>
				<p>
					We may update Our Privacy Policy from time to time. We will notify You
					of any changes by posting the new Privacy Policy on this page.
				</p>
				<p>
					We will let You know via email and/or a prominent notice on Our
					Service, prior to the change becoming effective and update the
					&quot;Last updated&quot; date at the top of this Privacy Policy.
				</p>
				<p>
					You are advised to review this Privacy Policy periodically for any
					changes. Changes to this Privacy Policy are effective when they are
					posted on this page.
				</p>
				<h1 className="mt-5">Contact Us</h1>
				<p>
					If you have any questions about this Privacy Policy, You can contact
					us:
				</p>
				<ul>
					<li>
						<p>By email: info@gafety.com</p>
					</li>
					<li>
						<p>
							By visiting this page on our website:{" "}
							<a href="https://gafety.com/" rel="noreferrer" target="_blank">
								https://gafety.com/
							</a>
						</p>
					</li>
					<li>
						<p>By phone number: +976 94017651</p>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default AppPrivacy;
