import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiCheckFill, RiEraserFill } from "react-icons/ri";
import SignatureCanvas from "react-signature-canvas";
import { mainApi } from "../utils/api";
import { Loader } from "../template";
import { translateError } from "../utils/alert";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { format } from "date-fns";

const SignaturePad = ({ onVerified, root, label }) => {
	const padRef = useRef();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const upload = async (pad) => {
		try {
			setLoading(true);

			const blob = dataURLtoBlob(pad.toDataURL("image/png"));
			const data = new FormData();
			data.append(
				"upload",
				blob,
				`${root} ${format(new Date(), "yyyy-MM-dd HH:mm:ss")}.png`
			);

			const response = await mainApi({
				url: `/file/fs/${root}`,
				method: "POST",
				data,
			});

			if (onVerified && response && pad) onVerified(response.data, pad);
		} catch (err) {
			translateError(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="relative">
			{loading && <Loader />}
			{label && <div>{t(label)}</div>}
			<SignatureCanvas
				ref={padRef}
				canvasProps={{ height: 500, className: "w-full flex-1 border" }}
			/>

			<div className="flex justify-end mt-4">
				<div>
					<button
						type="button"
						className="secondary"
						onClick={() => {
							if (padRef.current) padRef.current.clear();
						}}
					>
						<RiEraserFill className="text-2xl mr-2" />
						{t("button.clear")}
					</button>
				</div>
				<div className="ml-2">
					<button
						type="button"
						className="primary"
						onClick={() => {
							upload(padRef.current);
						}}
					>
						<RiCheckFill className="text-2xl mr-2" />
						{t("button.verify")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SignaturePad;
