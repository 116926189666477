import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { stringValidation } from "../utils/validations";
import { Text } from "../inputs";
import { DEBUG } from "../defines";
import { randomData } from "../utils/demo";

const EmergencyForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "emergency",
		id,
		init: {
			name: DEBUG ? randomData("emergency") : "",
			phone: DEBUG ? randomData("phone") : "",
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			phone: stringValidation(true),
		}),
	};

	return (
		<Form {...props} containerClass="w-full md:w-1/2">
			{({ disabled }) => {
				return (
					<div>
						<Field
							name="name"
							label="emergency.name"
							disabled={disabled}
							component={Text}
						/>
						<Field
							name="phone"
							label="emergency.phone"
							disabled={disabled}
							component={Text}
							type="phone"
						/>
						<Field
							name="priority"
							label="emergency.priority"
							disabled={disabled}
							component={Text}
							type="number"
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default EmergencyForm;
