import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { Select, Text } from "../inputs";
import View from "./View";
import { selectValidation, stringValidation } from "../utils/validations";

const AlarmForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "alarm",
		id,
		init: {
			location: null,
			info: "",
		},
		validationSchema: Yup.object().shape({
			location: selectValidation(true),
			info: stringValidation(true, 5000),
		}),
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props} containerClass="w-full md:w-1/2">
			{({ disabled, submited }) => {
				return (
					<div>
						<Field
							name="location"
							label="alarm.location"
							disabled={disabled}
							component={Select}
							axio="/const/select/location"
							closeOnSelect={true}
						/>
						<Field
							name="info"
							label="alarm.info"
							disabled={disabled}
							component={Text}
							type="textarea"
							rows={10}
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default AlarmForm;
