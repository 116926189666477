import { useTranslation } from "react-i18next";
import { RiPriceTag3Line } from "react-icons/ri";
import { CreatedBy, Status, Gallery } from "../template";
import { formatPrice } from "../utils/format";

const PpeItemView = ({
	createdby,
	created,
	name,
	price,
	balance,
	media,
	type,
}) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
				<Status
					color={balance < 1 ? "red" : "green"}
					left={
						<div className="flex items-center">
							<RiPriceTag3Line className="mr-1" />
							{formatPrice(price)}
						</div>
					}
					right={balance}
				></Status>
				{type && (
					<div className="mt-4 text-sm">{t(`const.ppe_type.${type}`)}</div>
				)}
				{name && <div className="mb-4 uppercase font-semibold">{name}</div>}
				<Gallery files={media} />
			</div>
		</div>
	);
};

export default PpeItemView;
