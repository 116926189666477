import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatString,
	createdByCol,
	translateCell,
	formatLink,
} from "../utils/format";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";

const IncidentTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/incident/table"
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button primary">
								{t("button.create")}
								<HiArrowNarrowRight className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "category",
					label: "incident.category",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/category/incident",
					},
					render: (row) => formatLink(row, "category.name", `${row._id}`),
				},
				{
					key: "location",
					label: "incident.location",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/location",
					},
					render: (row) => formatString(row, "location.name"),
				},
				{
					key: "date",
					label: "incident.date",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "date"),
				},

				{
					key: "status",
					label: "incident.status",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/incident_status",
					},
					render: (row) => translateCell(row, t, "incident_status", "status"),
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default IncidentTable;
