import { useTranslation } from "react-i18next";
import {
	ComposedChart,
	Line,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";

const Day = ({ data, statuses = [], tPath }) => {
	const { t } = useTranslation();

	return (
		<ResponsiveContainer width="100%" height="100%">
			<ComposedChart
				width={500}
				height={300}
				data={data}
				margin={{
					top: 20,
					right: 50,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="2 5" />
				<XAxis dataKey="_id" />
				<YAxis />
				<Tooltip />
				<Legend />
				<Area
					type="monotone"
					dataKey={t(`total`)}
					stroke={"#14213D"}
					fill={"#14213D"}
					fillOpacity={0.1}
				/>
				{statuses.map(({ status, color }) => (
					<Line
						type="monotone"
						dataKey={t(`${tPath}.${status}`)}
						stroke={color}
						strokeWidth={3}
						key={color}
					/>
				))}
			</ComposedChart>
		</ResponsiveContainer>
	);
};

export default Day;
