import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form } from "../template";
import { DatePicker, FileUpload, Select, Text, Toggle } from "../inputs";
import View from "./View";
import { useTranslation } from "react-i18next";
import HumanAnatomy from "./anatomy/HumanAnatomy";

const IncidentForm = ({ editable }) => {
	const { t } = useTranslation();
	const { id } = useParams();
	const props = {
		editable,
		model: "incident",
		id,
		init: {
			status: "notverified",
		},
		validationSchema: Yup.object().shape({}),
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props}>
			{({ disabled, values }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="category"
								label="incident.category"
								disabled={disabled}
								component={Select}
								axio="/const/category/incident"
							/>
							{values.category?._id ||
								(values.category && (
									<Field
										key={values.category}
										name="sub_category"
										label="incident.sub_category"
										disabled={disabled}
										component={Select}
										axio={`/const/category/incident/${values.category}`}
									/>
								))}
							<Field
								name="location"
								label="incident.location"
								disabled={disabled}
								component={Select}
								axio="/const/select/location"
							/>
							<Field
								name="date"
								label="incident.date"
								disabled={disabled}
								component={DatePicker}
								time={true}
							/>
							<Field
								name="company_type"
								label="incident.company_type"
								disabled={disabled}
								component={Select}
								axio="/const/static/company_type"
							/>
							<Field
								name="company"
								label="incident.company"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="damage"
								label="incident.damage"
								disabled={disabled}
								component={Text}
								type="number"
							/>
						</div>
						<div>
							<div className="p-5 bg-def-gray mb-5">
								<Field
									name="status"
									label="incident.status"
									disabled={!id || disabled}
									component={Select}
									axio="/const/static/incident_status"
								/>
								<Field
									name="workers"
									label="incident.workers"
									disabled={disabled}
									component={Select}
									isMulti={true}
									axio="/const/select/user"
									labelTemplate="${firstname} ${lastname} (${position})"
								/>
							</div>
						</div>
						<Field
							name="media"
							label="incident.media"
							root="incident"
							disabled={disabled}
							component={FileUpload}
							accept={"image/*,video/mp4"}
							count={10}
						/>
						<Field
							name="attachments"
							label="incident.attachments"
							root="incident"
							disabled={disabled}
							component={FileUpload}
							accept={"application/pdf"}
							count={10}
						/>
						<div className="col-span-2 p-5 bg-def-gray grid grid-cols-1 md:grid-cols-2 gap-8">
							<div>
								<Field
									name="human_injury"
									label="incident.human_injury"
									disabled={disabled}
									component={Toggle}
								/>
								{values.human_injury && (
									<Field
										name="human_injuries"
										label="incident.human_injuries"
										disabled={disabled}
										component={Select}
										isMulti={true}
										axio="/const/static/human_injuries"
									/>
								)}
							</div>
							{values.human_injury && (
								<div className="flex justify-center">
									<HumanAnatomy
										highlights={values.human_injuries}
									></HumanAnatomy>
								</div>
							)}
						</div>
						<div className="col-span-2">
							<Field
								name="info"
								label="incident.info"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows={10}
							/>
							<FieldArray
								name="causes"
								label="incident.causes"
								disabled={disabled}
								renderHeader={(item) => t("incident.cause")}
								initial={{
									category: null,
									description: "",
									action: "",
									responsible: [],
									deadline: null,
									status: "notfixed",
								}}
								renderItem={(cause, index) => {
									return (
										<div key={index}>
											<div className="grid grid-cols-1 md:grid-cols-3 gap-2">
												<div>
													<Field
														name={`causes.${index}.category`}
														label="incident.cause_category"
														disabled={disabled}
														component={Select}
														axio="/const/category/cause"
													/>

													{cause.category?._id ||
														(cause.category && (
															<Field
																key={cause.category}
																name={`causes.${index}.sub_category`}
																label="incident.cause_subcategory"
																disabled={disabled}
																component={Select}
																axio={`/const/category/cause/${cause.category}`}
															/>
														))}
												</div>
												<div className="col-span-2">
													<Field
														name={`causes.${index}.description`}
														label="incident.cause_description"
														disabled={disabled}
														component={Text}
														type="textarea"
														rows={10}
													/>
												</div>
												<div>
													<Field
														name={`causes.${index}.status`}
														label="incident.cause_status"
														disabled={disabled}
														component={Select}
														axio="/const/static/cause_status"
													/>
													<Field
														name={`causes.${index}.responsible`}
														label="incident.cause_responsible"
														disabled={disabled}
														component={Select}
														isMulti={true}
														axio="/const/select/user"
														labelTemplate="${firstname} ${lastname} (${position})"
													/>
													<Field
														name={`causes.${index}.deadline`}
														label="incident.cause_deadline"
														disabled={disabled}
														component={DatePicker}
													/>
												</div>
												<div className="col-span-2">
													<Field
														name={`causes.${index}.action`}
														label="incident.cause_action"
														disabled={disabled}
														component={Text}
														type="textarea"
														rows={10}
													/>
												</div>
											</div>
										</div>
									);
								}}
							/>
							<Field
								name="learnt"
								label="incident.learnt"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows={10}
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default IncidentForm;
