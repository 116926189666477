import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form } from "../template";
import { selectValidation, stringValidation } from "../utils/validations";
import { Select, Text } from "../inputs";
import View from "./View";

const PermissionTemplateForm = ({ editable, role }) => {
	const isPublic = role === "superior";
	const { id } = useParams();
	const props = {
		editable,
		model: "permission-template",
		id,
		init: {
			name: "",
			questions: [],
			workers: [],
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			questions: Yup.array().of(
				Yup.object().shape({
					question: stringValidation(true, 5000),
					type: selectValidation(true),
				})
			),
		}),
		beforeSubmit: (values) => {
			const { questions } = values;

			if (questions.length < 1)
				throw new Error("permission-template.undefined_questions");
			return values;
		},
		view: (values) => <View {...values} key={id} id={id} isPublic={isPublic} />,
	};

	return (
		<Form {...props}>
			{({ disabled }) => {
				return (
					<div>
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div>
								<Field
									name="name"
									label="permission-template.name"
									disabled={disabled}
									component={Text}
								/>
								{!isPublic && (
									<Field
										name="point"
										label="permission-template.point"
										disabled={disabled}
										component={Select}
										isSearchable={false}
										axio="/const/static/point"
									/>
								)}
							</div>
							{/* {!isPublic && (
                                        <Field
                                            name="workers"
                                            label="permission-template.workers"
                                            disabled={disabled}
                                            component={Select}
                                            axio="/const/select/user"
                                            isMulti={true}
                                            // eslint-disable-next-line no-template-curly-in-string
                                            labelTemplate="${firstname} ${lastname} (${position})"
                                        />
                                    )} */}
						</div>
						<FieldArray
							name="questions"
							label="permission-template.questions"
							disabled={disabled}
							initial={{ question: "", type: "text" }}
							renderItem={(question, qIndex) => (
								<div>
									<div className="grid grid-cols-1 md:grid-cols-3 gap-2">
										<div className="col-span-2">
											<Field
												name={`questions.${qIndex}.question`}
												label="permission-template.question"
												disabled={disabled}
												component={Text}
												type="textarea"
											/>
										</div>
										<Field
											name={`questions.${qIndex}.type`}
											label="permission-template.question_type"
											disabled={disabled}
											component={Select}
											axio="/const/static/field_type"
										/>
									</div>
								</div>
							)}
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default PermissionTemplateForm;
