import { format } from "date-fns";
import SignaturePad from "../signature/Pad";
import { useAuth } from "../utils/auth";
import { mainApi } from "../utils/api";
import { getAbsolutePath } from "../utils/format";
import { translateError } from "../utils/alert";

const Review = ({ id, worker, reviews }) => {
	const { user } = useAuth();
	const review = reviews.findLast((rev) => rev.user === worker._id);

	const { firstname, lastname, position } = review || worker;

	const signVerify = async (field, pad) => {
		try {
			const response = await mainApi({
				url: "/incident/verify/" + id,
				method: "POST",
				data: { signature: field },
			});

			response && window.location.reload();
		} catch (err) {
			translateError(err);
		} finally {
			pad.clear();
		}
	};

	return (
		<div className="relative mb-4 pb-4">
			{firstname} {lastname} ({position})
			{review && (
				<div className="mb-4">
					<div className="text-xs">
						{format(new Date(review.date), "yyyy-MM-dd HH:mm:ss")}
					</div>
					<img
						alt="signature"
						className="h-32"
						crossOrigin="annonymous"
						src={getAbsolutePath(review.signature)}
					/>
				</div>
			)}
			{user.id === worker._id && !review && (
				<SignaturePad
					onVerified={(field, pad) => {
						signVerify(field, pad);
					}}
					root="incident_signature"
					label="title.signature"
				></SignaturePad>
			)}
		</div>
	);
};

export default Review;
