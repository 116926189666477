import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { fileValidation, stringValidation } from "../utils/validations";
import { DatePicker, FileUpload, Select, Text } from "../inputs";
import View from "./View";

const SdsForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "sds",
		id,
		init: {
			name: "",
			file: [],
			start: null,
			end: null,
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			file: fileValidation(true),
		}),
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props}>
			{({ disabled, submited }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="name"
								label="sds.name"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="start"
								label="sds.start"
								disabled={disabled}
								component={DatePicker}
							/>
							<Field
								name="end"
								label="sds.end"
								disabled={disabled}
								component={DatePicker}
							/>
						</div>
						<div>
							<Field
								name="point"
								label="sds.point"
								disabled={disabled}
								component={Select}
								isSearchable={false}
								axio="/const/static/point"
							/>
							<Field
								name="file"
								label="sds.file"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								root="sds"
								accept="application/pdf"
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default SdsForm;
