import { CreatedBy } from "../template";
import QuestionPreview from "./QuestionPreview";

const SurveyTemplateView = ({
	createdby,
	created,
	name,
	description,
	questions,
}) => {
	return (
		<div className="w-full md:w-1/2 mx-auto">
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
			</div>
			{name && <div className="mt-4 uppercase font-semibold">{name}</div>}
			{description && <div className="info">{description}</div>}
			<div className="text-sm flex flex-col gap-12">
				{questions.map((question, index) => (
					<QuestionPreview
						{...question}
						hidetitle
						number={index + 1}
					></QuestionPreview>
				))}
			</div>
		</div>
	);
};

export default SurveyTemplateView;
