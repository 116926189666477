import { FaStar } from "react-icons/fa";
import { getAbsolutePath } from "../utils/format";

const Brief = ({
	profile,
	firstname,
	lastname,
	position,
	point,
	rank,
	size = "w-16 h-16",
}) => {
	return (
		<div className="flex self-start items-center">
			<img
				src={getAbsolutePath(profile, { placeholder: "/images/profile.png" })}
				alt={firstname}
				className={`${size} rounded-full mr-2 border border-def-secondary object-contain`}
				crossOrigin="anonymous"
			/>
			<div className="flex flex-grow justify-between items-center">
				<div>
					<div className="font-semibold text-sm uppercase">
						{firstname} {lastname}
					</div>
					<div className="text-sm">{position}</div>
				</div>
				<div>
					<div className="text-def-secondary text-center">#{rank}</div>
					<div className="bg-def-secondary p-1 text-white px-4 rounded-lg flex items-center">
						<div>{point}</div>
						<FaStar className="text-lg ml-1"></FaStar>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Brief;
