import { useTranslation } from "react-i18next";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../list/Table";
import { formatDate, createdByCol, formatLink } from "../utils/format";

const AlarmTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/alarm/table"
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button primary">
								{t("button.create")}
								<HiArrowNarrowRight className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "location",
					label: "fields.location",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/location",
					},
					render: (row) => formatLink(row, "location.name", `${row._id}`),
				},
				{
					key: "info",
					label: "alarm.info",
					sortable: true,
					filter: true,
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default AlarmTable;
