import GoogleMapReact from "google-map-react";
import { GOOGLE_MAP_CENTER, GOOGLE_MAP_KEY } from "../defines";

const GoogleMap = ({ children, center, loaded, height = 400, ...rest }) => {
	return (
		<div style={{ height, width: "100%" }}>
			<GoogleMapReact
				{...rest}
				bootstrapURLKeys={{ key: GOOGLE_MAP_KEY, libraries: ["visualization"] }}
				defaultCenter={center || GOOGLE_MAP_CENTER}
				defaultZoom={12}
				yesIWantToUseGoogleMapApiInternals={true}
				onGoogleApiLoaded={({ map, maps }) => loaded && loaded(map, maps)}
			>
				{children}
			</GoogleMapReact>
		</div>
	);
};

export default GoogleMap;
