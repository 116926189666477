import { useEffect, useState } from "react";
import { Loader, Media } from "../template";
import { mainApi } from "../utils/api";
import { t } from "i18next";

const PpeBrief = ({ item }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (item) fetch(item);
        else {
            setData(null);
        }
    }, [item]);

    const fetch = async (item) => {
        setLoading(true);

        const response = await mainApi(`/ppe-item/${item}`);
        response && setData(response.data);

        setLoading(false);
    };

    return (
        <div className="relative leading-tight text-gray-500">
            {loading && <Loader />}
            {data && (
                <div className="text-sm">
                    {t("ppe-item.balance")}: {data.balance}
                    <div className="mb-2">
                        <Media files={data.media} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PpeBrief;
