import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form } from "../template";
import { fileValidation, stringValidation } from "../utils/validations";
import { FileUpload, Select, Text, Toggle } from "../inputs";
import { t } from "i18next";
import View from "./View";

const calculateTotal = (questions) => {
	return (
		Array.isArray(questions) &&
		questions.reduce((prev, question) => {
			const max =
				(Array.isArray(question.variants) &&
					question.variants.reduce(
						(prev, variant) => (variant.point > prev ? variant.point : prev),
						0
					)) ||
				0;
			return parseFloat(prev) + parseFloat(max);
		}, 0)
	);
};

const TrainingContentForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "training-content",
		id,
		init: {
			type: "",
			name: "",
			info: "",
			media: [],
		},
		validationSchema: Yup.object().shape({
			type: stringValidation(true),
			name: stringValidation(true),
			info: stringValidation(true, 5000),
			questions: Yup.array().of(
				Yup.object().shape({
					question: stringValidation(true, 5000),
				})
			),
			media: fileValidation(true),
		}),
		beforeSubmit: (values) => {
			if (values.type === "exam") {
				if (!values.threshold)
					throw new Error("training-content.undefined_threshold");
				if (!values.duration)
					throw new Error("training-content.undefined_duration");
				if (!values.questions || values.questions < 1)
					throw new Error("training-content.undefined_questions");
			}

			return { ...values, total: calculateTotal(values.questions) };
		},
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props}>
			{({ disabled, submited, values }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="name"
								label="training-content.name"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="type"
								label="training-content.type"
								disabled={disabled}
								component={Select}
								axio="/const/static/training_type"
							/>
							{values.type !== "guest" && (
								<Field
									name="point"
									label="training-content.point"
									disabled={disabled}
									component={Select}
									isSearchable={false}
									axio="/const/static/point"
								/>
							)}
							{values.type === "exam" && (
								<>
									<Field
										name="duration"
										label="training-content.duration"
										disabled={disabled}
										component={Text}
										type="number"
									/>
									<Field
										name="shuffle"
										label="training-content.shuffle"
										disabled={disabled}
										component={Toggle}
									/>
									<Field
										name="threshold"
										label="training-content.threshold"
										disabled={disabled}
										component={Text}
										type="number"
									/>
									<Field
										name="attempts"
										label="training-content.attempts"
										disabled={disabled}
										component={Text}
										type="number"
									/>
								</>
							)}
						</div>
						<div>
							<Field
								name="media"
								label="training-content.media"
								disabled={disabled}
								component={FileUpload}
								submited={submited}
								root="training"
								accept="image/*,video/mp4,application/pdf"
								limit={500}
							/>
							<Field
								name="info"
								label="training-content.info"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows={10}
							/>
						</div>
						{["exam", "guest"].includes(values.type) && (
							<div className="col-span-2">
								<div className="text-lg">
									{t("training-content.total")}:{" "}
									{calculateTotal(values.questions)}
								</div>
								<FieldArray
									name="questions"
									label="training-content.questions"
									disabled={disabled}
									renderHeader={(item) => {
										if (Array.isArray(item.variants))
											return `${t(
												"training-content.question_max"
											)} ${item.variants.reduce(
												(prev, variant) =>
													variant.point > prev ? variant.point : prev,
												0
											)}`;
									}}
									initial={{ question: "", variants: [] }}
									renderItem={(question, qIndex) => (
										<div>
											<div className="grid grid-cols-1 md:grid-cols-3 gap-2">
												<div className="col-span-2">
													<Field
														name={`questions.${qIndex}.question`}
														label="training-content.question"
														disabled={disabled}
														component={Text}
														type="textarea"
														rows={7}
													/>
												</div>
												<Field
													name={`questions.${qIndex}.attachment`}
													label="training-content.attachment"
													disabled={disabled}
													component={FileUpload}
													root="exam"
												/>
											</div>
											<div className="p-8 border-l-2 border-def-secondary">
												<FieldArray
													name={`questions.${qIndex}.variants`}
													label="training-content.variants"
													disabled={disabled}
													initial={{
														variant: "",
														point: 0,
													}}
													itemClass="p-4 border"
													renderItem={(variant, aIndex) => (
														<div className="grid grid-cols-4 gap-2">
															<div className="col-span-3">
																<Field
																	name={`questions.${qIndex}.variants.${aIndex}.variant`}
																	label="training-content.variant"
																	disabled={disabled}
																	component={Text}
																/>
															</div>
															<Field
																name={`questions.${qIndex}.variants.${aIndex}.point`}
																label="training-content.answer_point"
																disabled={disabled}
																component={Text}
																type="number"
															/>
														</div>
													)}
												/>
											</div>
										</div>
									)}
								/>
							</div>
						)}
					</div>
				);
			}}
		</Form>
	);
};

export default TrainingContentForm;
