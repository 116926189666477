import { useTranslation } from "react-i18next";

const Table = ({
	data = [],
	columns,
	nameField = "name",
	tPath,
	renderRowName,
}) => {
	const { t } = useTranslation();

	return (
		<table className="report-table table-auto w-full uppercase">
			<thead>
				<tr>
					<td></td>
					{columns.map((col, index) => {
						return (
							<td
								key={col}
								className={index === columns.length - 1 ? "focus" : ""}
							>
								{t(col === "total" ? "total" : `${tPath}.${col}`)}
							</td>
						);
					})}
				</tr>
			</thead>
			<tbody>
				{data.map((row, index) => {
					return (
						<tr key={index}>
							<td className="name">
								{renderRowName ? renderRowName(row) : row[nameField]}
							</td>
							{columns.map((col, index) => {
								return (
									<td
										key={col}
										className={index === columns.length - 1 ? "focus" : ""}
									>
										{row[col]}
									</td>
								);
							})}
						</tr>
					);
				})}
				<tr>
					<td className="focus name">{t("total")}</td>
					{columns.map((col) => {
						return (
							<td key={col} className="focus">
								{data.reduce((acc, cur) => {
									return (acc += cur[col]);
								}, 0)}
							</td>
						);
					})}
				</tr>
			</tbody>
		</table>
	);
};

export default Table;
