import { FieldArray as FormikFieldArray, useField } from "formik";
import { useTranslation } from "react-i18next";
import {
	RiAddLine,
	RiArrowDownLine,
	RiArrowUpLine,
	RiDeleteBinLine,
	RiFileCopyLine,
} from "react-icons/ri";

const buttonClass =
	"h-8 w-8 flex items-center justify-center text-white cursor";

const Add = ({ helper, initial, index }) => {
	return (
		<div
			className={`${buttonClass} bg-def-secondary`}
			onClick={() => {
				helper.insert(index, initial);
			}}
		>
			<RiAddLine />
		</div>
	);
};

const FieldArray = ({
	helper,
	label,
	disabled,
	initial,
	renderItem,
	renderHeader,
	itemClass = "p-4 border shadow-lg",
	minimal,
	duplicatable,
	...props
}) => {
	const { t } = useTranslation();
	const [field, meta, helpers] = useField(props.name);

	return (
		<div className={`field`}>
			{label && <label className="label order-1">{t(label)}</label>}
			<FormikFieldArray
				{...props}
				render={(arrayHelpers) => {
					if (minimal)
						return (
							<>
								{field.value &&
									field.value.map((item, index) => {
										return (
											<div key={index} className="flex w-full">
												{renderItem(item, index)}
												<div
													onClick={() => {
														arrayHelpers.remove(index);
													}}
													className={`${buttonClass} bg-def-red ml-2 mt-1`}
												>
													<RiDeleteBinLine />
												</div>
											</div>
										);
									})}
								<div className="mb-4">
									<Add
										helper={arrayHelpers}
										initial={initial}
										index={field.value.length}
									/>
								</div>
							</>
						);

					return (
						<div>
							{!disabled && (
								<div className="mb-4">
									<Add helper={arrayHelpers} initial={initial} index={0} />
								</div>
							)}
							<div className="flex flex-col gap-8">
								{field.value &&
									field.value.map((item, index) => {
										return (
											<div key={index}>
												<div className={itemClass}>
													<div className="border-b pb-2 mb-2 flex items-center justify-between">
														<div className="self-center">
															#{index + 1}. {renderHeader && renderHeader(item)}
														</div>
														{!disabled && (
															<div className="flex items-center gap-2">
																{index !== 0 && (
																	<div
																		onClick={() => {
																			arrayHelpers.swap(index, index - 1);
																		}}
																		className={`${buttonClass} bg-def-primary`}
																	>
																		<RiArrowUpLine />
																	</div>
																)}
																{index !== field.value.length - 1 && (
																	<div
																		onClick={() => {
																			arrayHelpers.swap(index, index + 1);
																		}}
																		className={`${buttonClass} bg-def-primary`}
																	>
																		<RiArrowDownLine />
																	</div>
																)}
																<div
																	onClick={() => {
																		arrayHelpers.remove(index);
																	}}
																	className={`${buttonClass} bg-def-red`}
																>
																	<RiDeleteBinLine />
																</div>
																{duplicatable && (
																	<div
																		onClick={() => {
																			arrayHelpers.insert(index, item);
																		}}
																		className={`${buttonClass} bg-def-secondary`}
																	>
																		<RiFileCopyLine />
																	</div>
																)}
															</div>
														)}
													</div>
													{renderItem(item, index)}
												</div>
												{!disabled && (
													<div className="mt-4">
														<Add
															helper={arrayHelpers}
															initial={initial}
															index={index + 1}
														/>
													</div>
												)}
											</div>
										);
									})}
							</div>
						</div>
					);
				}}
			/>
			{helper && (
				<div className={`text-sm my-2 leading-none`}>
					{helper && helper({ field, meta, helpers })}
				</div>
			)}
		</div>
	);
};

export default FieldArray;
