import { t } from "i18next";
import { formatSize } from "../utils/format";

const FileStats = ({ sizes = [] }) => {
	const totalSize = sizes.reduce((prev, cur) => (prev += cur.value), 0);

	return (
		<table className="w-full table-fixed">
			<tbody>
				<tr>
					<td>{t("file_stats.type")}</td>
					<td className="text-right">{t("file_stats.size")}</td>
				</tr>
				{sizes.map(({ value, name }) => (
					<tr key={name} className="border-b">
						<td className="py-2">{name}</td>
						<td className="py-2 text-right">{formatSize(value)}</td>
					</tr>
				))}
				<tr className="font-bold uppercase">
					<td className="py-2">{t("file_stats.total")}</td>
					<td className="py-2 text-right">{formatSize(totalSize)}</td>
				</tr>
			</tbody>
		</table>
	);
};

export default FileStats;
