import AdminDashboard from "../dashboard/Admin";
import SuperiorDashboard from "../dashboard/Superior";
import ManagerDashboard from "../dashboard/Manager";

import OrgForm from "../org/Form";
import OrgTable from "../org/Table";

import ContractForm from "../contract/Form";
import ContractTable from "../contract/Table";

import UserForm from "../user/Form";
import UserTable from "../user/Table";

import ManagerForm from "../manager/Form";
import ManagerTable from "../manager/Table";

import UnitForm from "../unit/Form";
import UnitTable from "../unit/Table";
import UnitDiagram from "../unit/Diagram";

import EmergencyForm from "../emergency/Form";
import EmergencyTable from "../emergency/Table";

import LocationForm from "../location/Form";
import LocationTable from "../location/Table";

import GroupForm from "../group/Form";
import GroupTable from "../group/Table";

import CategoryForm from "../category/Form";
import CategoryTable from "../category/Table";

import TaskForm from "../task/Form";
import TaskTable from "../task/Table";

import OrgSettings from "../org/Settings";
import PointSettings from "../org/PointSettings";

import ProcedureForm from "../procedure/Form";
import ProcedureTable from "../procedure/Table";

import LawForm from "../law/Form";
import LawTable from "../law/Table";

import SdsForm from "../sds/Form";
import SdsTable from "../sds/Table";

import TrainingContentForm from "../training-content/Form";
import TrainingContentTable from "../training-content/Table";

import InductionTemplateForm from "../induction-template/Form";
import InductionTemplateTable from "../induction-template/Table";

import ChecklistTemplateForm from "../checklist-template/Form";
import ChecklistTemplateTable from "../checklist-template/Table";

import SurveyTemplateForm from "../survey-template/Form";
import SurveyTemplateTable from "../survey-template/Table";

import StepTemplateForm from "../step-template/Form";
import StepTemplateTable from "../step-template/Table";

import PermissionTemplateForm from "../permission-template/Form";
import PermissionTemplateTable from "../permission-template/Table";

import PpeItemForm from "../ppe-item/Form";
import PpeItemTable from "../ppe-item/Table";

import TrainingForm from "../training/Form";
import TrainingTable from "../training/Table";
import TrainingDetail from "../training/Detail";

import InductionForm from "../induction/Form";
import InductionTable from "../induction/Table";

import PpeForm from "../ppe/Form";
import PpeTable from "../ppe/Table";

import NewsForm from "../news/Form";
import NewsTable from "../news/Table";

import HazardForm from "../hazard/Form";
import HazardTable from "../hazard/Table";

import AlarmForm from "../alarm/Form";
import AlarmTable from "../alarm/Table";

import AlertForm from "../alert/Form";
import AlertTable from "../alert/Table";

import ViolationForm from "../violation/Form";
import ViolationTable from "../violation/Table";

import ChecklistForm from "../checklist/Form";
import ChecklistTable from "../checklist/Table";

import SurveyForm from "../survey/Form";
import SurveyTable from "../survey/Table";

import StepForm from "../step/Form";
import StepTable from "../step/Table";

import PointForm from "../point/Form";
import PointTable from "../point/Table";

import PermissionForm from "../permission/Form";
import PermissionTable from "../permission/Table";

import InspectionForm from "../inspection/Form";
import InspectionTable from "../inspection/Table";

import IncidentForm from "../incident/Form";
import IncidentTable from "../incident/Table";

import Explorer from "../document/Explorer";
import DocumentForm from "../document/Form";
import { Navigate } from "react-router-dom";
import {
	hazardPanel,
	alertPanel,
	violationPanel,
	trainingPanel,
	inductionPanel,
	checklistPanel,
	stepPanel,
	permissionPanel,
	ppePanel,
	inspectionPanel,
	incidentPanel,
	userPanel,
	orgPanel,
	documentPanel,
	surveyPanel,
} from "./TabPanels";
import ReportPanel from "../report/Panel";

const AdminRouter = [
	{ path: "/", element: <AdminDashboard /> },

	{
		path: "pnl-hazard",
		element: <Navigate from="pnl-hazard" to="/admin/pnl-hazard/hazard" />,
	},
	{
		path: "pnl-hazard/hazard",
		element: hazardPanel(<HazardTable />),
	},
	{
		path: "pnl-hazard/hazard/:id",
		element: hazardPanel(<HazardForm editable={true} />),
	},
	{
		path: "pnl-hazard/category",
		element: hazardPanel(<CategoryTable type="hazard" />),
	},
	{
		path: "pnl-hazard/category/new",
		element: hazardPanel(<CategoryForm type="hazard" editable={true} />),
	},
	{
		path: "pnl-hazard/category/:id",
		element: hazardPanel(<CategoryForm type="hazard" editable={true} />),
	},
	{
		path: "pnl-hazard/report",
		element: hazardPanel(<ReportPanel type="hazard" />),
	},
	{
		path: "pnl-hazard/task",
		element: hazardPanel(<TaskTable type="hazard" />),
	},
	{
		path: "pnl-hazard/task/:id",
		element: hazardPanel(<TaskForm />),
	},

	{
		path: "pnl-alert",
		element: <Navigate from="pnl-alert" to="/admin/pnl-alert/alert" />,
	},
	{
		path: "pnl-alert/alert",
		element: alertPanel(<AlertTable />),
	},
	{
		path: "pnl-alert/alert/:id",
		element: alertPanel(<AlertForm editable={true} />),
	},
	{
		path: "pnl-org/alarm",
		element: orgPanel(<AlarmTable />),
	},
	{
		path: "pnl-org/alarm/new",
		element: orgPanel(<AlarmForm editable={true} />),
	},
	{
		path: "pnl-org/alarm/:id",
		element: orgPanel(<AlarmForm editable={true} />),
	},
	{
		path: "pnl-alert/category",
		element: alertPanel(<CategoryTable type="alert" />),
	},
	{
		path: "pnl-alert/category/new",
		element: alertPanel(<CategoryForm type="alert" editable={true} />),
	},
	{
		path: "pnl-alert/category/:id",
		element: alertPanel(<CategoryForm type="alert" editable={true} />),
	},
	{
		path: "pnl-alert/report",
		element: alertPanel(<ReportPanel type="alert" />),
	},

	{
		path: "pnl-violation",
		element: (
			<Navigate from="pnl-violation" to="/admin/pnl-violation/violation" />
		),
	},
	{
		path: "pnl-violation/violation",
		element: violationPanel(<ViolationTable />),
	},
	{
		path: "pnl-violation/violation/:id",
		element: violationPanel(<ViolationForm editable={true} />),
	},
	{
		path: "pnl-violation/category",
		element: violationPanel(<CategoryTable type="violation" />),
	},
	{
		path: "pnl-violation/category/new",
		element: violationPanel(<CategoryForm type="violation" editable={true} />),
	},
	{
		path: "pnl-violation/category/:id",
		element: violationPanel(<CategoryForm type="violation" editable={true} />),
	},
	{
		path: "pnl-violation/report",
		element: violationPanel(<ReportPanel type="violation" />),
	},

	{
		path: "pnl-training",
		element: <Navigate from="pnl-training" to="/admin/pnl-training/training" />,
	},
	{ path: "pnl-training/training", element: trainingPanel(<TrainingTable />) },
	{
		path: "pnl-training/training/new",
		element: trainingPanel(<TrainingForm editable={true} />),
	},
	{
		path: "pnl-training/training/:id",
		element: trainingPanel(<TrainingForm editable={true} />),
	},
	{
		path: "pnl-training/training/:id/:detail",
		element: trainingPanel(<TrainingDetail editable={true} />),
	},

	{
		path: "pnl-training/training-content",
		element: trainingPanel(<TrainingContentTable />),
	},
	{
		path: "pnl-training/training-content/new",
		element: trainingPanel(<TrainingContentForm editable={true} />),
	},
	{
		path: "pnl-training/training-content/:id",
		element: trainingPanel(<TrainingContentForm editable={true} />),
	},
	{
		path: "pnl-training/report",
		element: trainingPanel(<ReportPanel type="training" />),
	},

	{
		path: "pnl-induction",
		element: (
			<Navigate from="pnl-induction" to="/admin/pnl-induction/induction" />
		),
	},
	{
		path: "pnl-induction/induction-template",
		element: inductionPanel(<InductionTemplateTable />),
	},
	{
		path: "pnl-induction/induction-template/new",
		element: inductionPanel(<InductionTemplateForm editable={true} />),
	},
	{
		path: "pnl-induction/induction-template/:id",
		element: inductionPanel(<InductionTemplateForm editable={true} />),
	},
	{
		path: "pnl-induction/induction",
		element: inductionPanel(<InductionTable />),
	},
	{
		path: "pnl-induction/induction/new",
		element: inductionPanel(<InductionForm editable={true} />),
	},
	{
		path: "pnl-induction/induction/:id",
		element: inductionPanel(<InductionForm editable={true} />),
	},
	{
		path: "pnl-induction/report",
		element: inductionPanel(<ReportPanel type="induction" />),
	},

	{
		path: "pnl-checklist",
		element: (
			<Navigate from="pnl-checklist" to="/admin/pnl-checklist/checklist" />
		),
	},
	{
		path: "pnl-checklist/checklist",
		element: checklistPanel(<ChecklistTable />),
	},
	{
		path: "pnl-checklist/checklist/:id",
		element: checklistPanel(<ChecklistForm editable={true} />),
	},
	{
		path: "pnl-checklist/checklist-template",
		element: checklistPanel(<ChecklistTemplateTable />),
	},
	{
		path: "pnl-checklist/checklist-template/new",
		element: checklistPanel(<ChecklistTemplateForm editable={true} />),
	},
	{
		path: "pnl-checklist/checklist-template/:id",
		element: checklistPanel(<ChecklistTemplateForm editable={true} />),
	},
	{
		path: "pnl-checklist/category",
		element: checklistPanel(<CategoryTable type="checklist" />),
	},
	{
		path: "pnl-checklist/category/new",
		element: checklistPanel(<CategoryForm type="checklist" editable={true} />),
	},
	{
		path: "pnl-checklist/category/:id",
		element: checklistPanel(<CategoryForm type="checklist" editable={true} />),
	},
	{
		path: "pnl-checklist/report",
		element: checklistPanel(<ReportPanel type="checklist" />),
	},

	{
		path: "pnl-survey",
		element: <Navigate from="pnl-survey" to="/admin/pnl-survey/survey" />,
	},
	{
		path: "pnl-survey/survey",
		element: surveyPanel(<SurveyTable />),
	},
	{
		path: "pnl-survey/survey/new",
		element: surveyPanel(<SurveyForm editable={true} />),
	},
	{
		path: "pnl-survey/survey/:id",
		element: surveyPanel(<SurveyForm editable={true} />),
	},
	{
		path: "pnl-survey/survey-template",
		element: surveyPanel(<SurveyTemplateTable />),
	},
	{
		path: "pnl-survey/survey-template/new",
		element: surveyPanel(<SurveyTemplateForm editable={true} />),
	},
	{
		path: "pnl-survey/survey-template/:id",
		element: surveyPanel(<SurveyTemplateForm editable={true} />),
	},
	{
		path: "pnl-survey/report",
		element: surveyPanel(<ReportPanel type="survey" />),
	},

	{
		path: "pnl-step",
		element: <Navigate from="pnl-step" to="/admin/pnl-step/step" />,
	},
	{ path: "pnl-step/step", element: stepPanel(<StepTable />) },
	{
		path: "pnl-step/step/:id",
		element: stepPanel(<StepForm editable={true} />),
	},
	{ path: "pnl-step/step-template", element: stepPanel(<StepTemplateTable />) },
	{
		path: "pnl-step/step-template/new",
		element: stepPanel(<StepTemplateForm editable={true} />),
	},
	{
		path: "pnl-step/step-template/:id",
		element: stepPanel(<StepTemplateForm editable={true} />),
	},
	{
		path: "pnl-step/report",
		element: stepPanel(<ReportPanel type="step" />),
	},

	{
		path: "pnl-permission",
		element: (
			<Navigate from="pnl-permission" to="/admin/pnl-permission/permission" />
		),
	},
	{
		path: "pnl-permission/permission",
		element: permissionPanel(<PermissionTable />),
	},
	{
		path: "pnl-permission/permission/:id",
		element: permissionPanel(<PermissionForm editable={true} />),
	},
	{
		path: "pnl-permission/permission-template",
		element: permissionPanel(<PermissionTemplateTable />),
	},
	{
		path: "pnl-permission/permission-template/new",
		element: permissionPanel(<PermissionTemplateForm editable={true} />),
	},
	{
		path: "pnl-permission/permission-template/:id",
		element: permissionPanel(<PermissionTemplateForm editable={true} />),
	},
	{
		path: "pnl-permission/report",
		element: permissionPanel(<ReportPanel type="permission" />),
	},

	{
		path: "pnl-ppe",
		element: <Navigate from="pnl-ppe" to="/admin/pnl-ppe/ppe" />,
	},
	{ path: "pnl-ppe/ppe", element: ppePanel(<PpeTable />) },
	{ path: "pnl-ppe/ppe/new", element: ppePanel(<PpeForm editable={true} />) },
	{ path: "pnl-ppe/ppe/:id", element: ppePanel(<PpeForm editable={true} />) },
	{ path: "pnl-ppe/ppe-item", element: ppePanel(<PpeItemTable />) },
	{
		path: "pnl-ppe/ppe-item/new",
		element: ppePanel(<PpeItemForm editable={true} />),
	},
	{
		path: "pnl-ppe/ppe-item/:id",
		element: ppePanel(<PpeItemForm editable={true} />),
	},
	{
		path: "pnl-ppe/report",
		element: ppePanel(<ReportPanel type="ppe" />),
	},

	{
		path: "pnl-inspection",
		element: (
			<Navigate from="pnl-inspection" to="/admin/pnl-inspection/inspection" />
		),
	},
	{
		path: "pnl-inspection/inspection",
		element: inspectionPanel(<InspectionTable />),
	},
	{
		path: "pnl-inspection/inspection/:id",
		element: inspectionPanel(<InspectionForm editable={true} />),
	},
	{
		path: "pnl-inspection/category",
		element: inspectionPanel(<CategoryTable type="inspection" />),
	},
	{
		path: "pnl-inspection/category/new",
		element: inspectionPanel(
			<CategoryForm type="inspection" editable={true} />
		),
	},
	{
		path: "pnl-inspection/category/:id",
		element: inspectionPanel(
			<CategoryForm type="inspection" editable={true} />
		),
	},
	{
		path: "pnl-inspection/report",
		element: inspectionPanel(<ReportPanel type="inspection" />),
	},

	{
		path: "pnl-incident",
		element: <Navigate from="pnl-incident" to="/admin/pnl-incident/incident" />,
	},
	{
		path: "pnl-incident/incident",
		element: incidentPanel(<IncidentTable />),
	},
	{
		path: "pnl-incident/incident/new",
		element: incidentPanel(<IncidentForm editable={true} />),
	},
	{
		path: "pnl-incident/incident/:id",
		element: incidentPanel(<IncidentForm editable={true} />),
	},
	{
		path: "pnl-incident/category",
		element: incidentPanel(<CategoryTable type="incident" />),
	},
	{
		path: "pnl-incident/category/new",
		element: incidentPanel(<CategoryForm type="incident" editable={true} />),
	},
	{
		path: "pnl-incident/category/:id",
		element: incidentPanel(<CategoryForm type="incident" editable={true} />),
	},
	{
		path: "pnl-incident/cause",
		element: incidentPanel(<CategoryTable type="cause" />),
	},
	{
		path: "pnl-incident/cause/new",
		element: incidentPanel(<CategoryForm type="cause" editable={true} />),
	},
	{
		path: "pnl-incident/cause/:id",
		element: incidentPanel(<CategoryForm type="cause" editable={true} />),
	},
	{
		path: "pnl-incident/report",
		element: incidentPanel(<ReportPanel type="incident" />),
	},

	{
		path: "pnl-user",
		element: <Navigate from="pnl-user" to="/admin/pnl-user/user" />,
	},
	{ path: "pnl-user/user", element: userPanel(<UserTable />) },
	{
		path: "pnl-user/user/:id",
		element: userPanel(<UserForm editable={true} />),
	},
	{ path: "pnl-user/group", element: userPanel(<GroupTable />) },
	{
		path: "pnl-user/group/new",
		element: userPanel(<GroupForm editable={true} />),
	},
	{
		path: "pnl-user/group/:id",
		element: userPanel(<GroupForm editable={true} />),
	},
	{ path: "pnl-user/point", element: userPanel(<PointTable />) },
	{
		path: "pnl-user/point/:id",
		element: userPanel(<PointForm editable={true} />),
	},
	{
		path: "pnl-user/point-settings",
		element: userPanel(<PointSettings editable={true} />),
	},
	{
		path: "pnl-user/alert/:id",
		element: userPanel(<AlertForm />),
	},
	{
		path: "pnl-user/checklist/:id",
		element: userPanel(<ChecklistForm />),
	},
	{
		path: "pnl-user/hazard/:id",
		element: userPanel(<HazardForm />),
	},
	{
		path: "pnl-user/inspection/:id",
		element: userPanel(<InspectionForm />),
	},
	{
		path: "pnl-user/permission/:id",
		element: userPanel(<PermissionForm />),
	},
	{
		path: "pnl-user/step/:id",
		element: userPanel(<StepForm />),
	},
	{
		path: "pnl-user/violation/:id",
		element: userPanel(<ViolationForm />),
	},
	{
		path: "pnl-user/incident/:id",
		element: userPanel(<IncidentForm />),
	},
	{
		path: "pnl-user/law/:id",
		element: userPanel(<LawForm />),
	},
	{
		path: "pnl-user/procedure/:id",
		element: userPanel(<ProcedureForm />),
	},
	{
		path: "pnl-user/sds/:id",
		element: userPanel(<SdsForm />),
	},
	{
		path: "pnl-user/induction/:id",
		element: userPanel(<InductionForm />),
	},
	{
		path: "pnl-user/training/:id",
		element: userPanel(<TrainingForm />),
	},

	{
		path: "pnl-org",
		element: <Navigate from="pnl-org" to="/admin/pnl-org/news" />,
	},
	{ path: "pnl-org/news", element: orgPanel(<NewsTable />) },
	{ path: "pnl-org/news/new", element: orgPanel(<NewsForm editable={true} />) },
	{ path: "pnl-org/news/:id", element: orgPanel(<NewsForm editable={true} />) },
	{ path: "pnl-org/emergency", element: orgPanel(<EmergencyTable />) },
	{
		path: "pnl-org/emergency/new",
		element: orgPanel(<EmergencyForm editable={true} />),
	},
	{
		path: "pnl-org/emergency/:id",
		element: orgPanel(<EmergencyForm editable={true} />),
	},
	{ path: "pnl-org/location", element: orgPanel(<LocationTable />) },
	{
		path: "pnl-org/location/new",
		element: orgPanel(<LocationForm editable={true} />),
	},
	{
		path: "pnl-org/location/:id",
		element: orgPanel(<LocationForm editable={true} />),
	},
	{ path: "pnl-org/unit", element: orgPanel(<UnitTable />) },
	{ path: "pnl-org/unit/new", element: orgPanel(<UnitForm editable={true} />) },
	{ path: "pnl-org/unit/:id", element: orgPanel(<UnitForm editable={true} />) },
	{ path: "pnl-org/unit/diagram/view", element: orgPanel(<UnitDiagram />) },
	{
		path: "pnl-org/settings",
		element: orgPanel(<OrgSettings editable={true} />),
	},

	{
		path: "pnl-document",
		element: (
			<Navigate from="pnl-document" to="/admin/pnl-document/procedure" />
		),
	},
	{
		path: "pnl-document/procedure",
		element: documentPanel(<ProcedureTable />),
	},
	{
		path: "pnl-document/procedure/new",
		element: documentPanel(<ProcedureForm editable={true} />),
	},
	{
		path: "pnl-document/procedure/:id",
		element: documentPanel(<ProcedureForm editable={true} />),
	},
	{ path: "pnl-document/law", element: documentPanel(<LawTable />) },
	{
		path: "pnl-document/law/new",
		element: documentPanel(<LawForm editable={true} />),
	},
	{
		path: "pnl-document/law/:id",
		element: documentPanel(<LawForm editable={true} />),
	},
	{ path: "pnl-document/sds", element: documentPanel(<SdsTable />) },
	{
		path: "pnl-document/sds/new",
		element: documentPanel(<SdsForm editable={true} />),
	},
	{
		path: "pnl-document/sds/:id",
		element: documentPanel(<SdsForm editable={true} />),
	},
	{
		path: "pnl-document/document/:parent",
		element: documentPanel(<Explorer />),
	},
	{
		path: "pnl-document/document/:parent/new",
		element: documentPanel(<DocumentForm />),
	},
	{
		path: "pnl-document/document/:parent/edit/:id",
		element: documentPanel(<DocumentForm />),
	},
	{
		path: "pnl-document/report",
		element: documentPanel(<ReportPanel type="document" />),
	},

	{
		path: "profile",
		element: (
			<div className="p-4 bg-white">
				<UserForm editable={true} profile />
			</div>
		),
	},
];

const SuperiorRouter = [
	{ path: "/", element: <SuperiorDashboard /> },
	{ path: "org", element: <OrgTable /> },
	{ path: "org/new", element: <OrgForm editable={true} /> },
	{ path: "org/:id", element: <OrgForm editable={true} /> },
	{ path: "org/:id/dashboard", element: <SuperiorDashboard /> },

	{ path: "contract", element: <ContractTable /> },
	{
		path: "contract/create/:org_id",
		element: <ContractForm editable={true} />,
	},
	{ path: "contract/:id", element: <ContractForm editable={true} /> },

	{ path: "manager", element: <ManagerTable editable={true} /> },
	{
		path: "manager/new",
		element: <ManagerForm editable={true} editable_password={true} />,
	},
	{
		path: "manager/:id",
		element: <ManagerForm editable={true} editable_password={true} />,
	},

	{
		path: "profile",
		element: (
			<div className="p-4 bg-white">
				<UserForm editable={true} profile />
			</div>
		),
	},

	{
		path: "checklist-template",
		element: <ChecklistTemplateTable role="superior" />,
	},
	{
		path: "checklist-template/new",
		element: <ChecklistTemplateForm role="superior" editable={true} />,
	},
	{
		path: "checklist-template/:id",
		element: <ChecklistTemplateForm role="superior" editable={true} />,
	},

	{
		path: "survey-template",
		element: <SurveyTemplateTable role="superior" />,
	},
	{
		path: "survey-template/new",
		element: <SurveyTemplateForm role="superior" editable={true} />,
	},
	{
		path: "survey-template/:id",
		element: <SurveyTemplateForm role="superior" editable={true} />,
	},

	{ path: "step-template", element: <StepTemplateTable role="superior" /> },
	{
		path: "step-template/new",
		element: <StepTemplateForm role="superior" editable={true} />,
	},
	{
		path: "step-template/:id",
		element: <StepTemplateForm role="superior" editable={true} />,
	},

	{
		path: "permission-template",
		element: <PermissionTemplateTable role="superior" />,
	},
	{
		path: "permission-template/new",
		element: <PermissionTemplateForm role="superior" editable={true} />,
	},
	{
		path: "permission-template/:id",
		element: <PermissionTemplateForm role="superior" editable={true} />,
	},
];

const ManagerRouter = [
	{ path: "/", element: <ManagerDashboard /> },
	{ path: "org", element: <OrgTable /> },
	{ path: "org/new", element: <OrgForm editable={true} /> },
	{ path: "org/:id", element: <OrgForm editable={true} /> },
	{ path: "org/:id/dashboard", element: <SuperiorDashboard /> },

	{ path: "contract", element: <ContractTable /> },
	{
		path: "contract/create/:org_id",
		element: <ContractForm editable={true} />,
	},
	{ path: "contract/:id", element: <ContractForm editable={true} /> },

	{
		path: "profile",
		element: (
			<div className="p-4 bg-white">
				<UserForm editable={true} profile />
			</div>
		),
	},
];

const routes = {
	admin: AdminRouter,
	moderator: AdminRouter,
	superior: SuperiorRouter,
	manager: ManagerRouter,
};

export default routes;
