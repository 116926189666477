import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { stringValidation } from "../utils/validations";
import { FileUpload, Select, Text } from "../inputs";
import View from "./View";

const HazardForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable: ({ status }) => editable && status !== "completed",
		model: "hazard",
		id,
		init: {
			location: "",
			category: "",
			probability: "",
			damage: "",
			info: "",
			attachments: [],
		},
		validationSchema: Yup.object().shape({
			location: stringValidation(true),
			category: stringValidation(true),
			probability: stringValidation(true),
			damage: stringValidation(true),
			info: stringValidation(false, 5000),
		}),
		view: (values) => <View {...values} key={id} id={id} />,
		isDeletable: true,
	};

	return (
		<Form {...props}>
			{({ disabled, submited }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="location"
								label="hazard.location"
								disabled={disabled}
								component={Select}
								axio="/const/select/location"
							/>
							<Field
								name="category"
								label="hazard.category"
								disabled={disabled}
								component={Select}
								axio="/const/category/hazard"
							/>
							<Field
								name="probability"
								label="hazard.probability"
								disabled={disabled}
								component={Select}
								axio="/const/static/probability"
							/>
							<Field
								name="damage"
								label="hazard.damage"
								disabled={disabled}
								component={Select}
								axio="/const/static/damage"
							/>
						</div>
						<div>
							<Field
								name="info"
								label="hazard.info"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows={10}
							/>
							<Field
								name="attachments"
								label="hazard.attachments"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								root="hazard"
								accept="image/*,video/*"
								limit={500}
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default HazardForm;
