import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatLink,
	createdByCol,
	translateCell,
} from "../utils/format";

const TrainingTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/training-plan/table"
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button primary">
								{t("button.create")}
								<HiArrowNarrowRight className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "content",
					label: "training-plan.content",
					sortable: true,
					filter: {
						type: "select",
						axio: "const/select/training-content",
					},
					render: (row) => formatLink(row, "content.name", `${row._id}`),
				},
				{
					key: "status",
					label: "training-plan.status",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/plan_status",
					},
					render: (row) => translateCell(row, t, "plan_status", "status"),
				},
				{
					key: "user_count",
					label: "training-plan.user_count",
					sortable: true,
					filter: {
						type: "number",
					},
				},
				{
					key: "signature_count",
					label: "training-plan.signature_count",
					sortable: true,
					filter: {
						type: "number",
					},
				},
				{
					key: "schedule",
					label: "training-plan.schedule",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "schedule"),
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default TrainingTable;
