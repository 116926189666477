import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatMedia,
	formatLink,
	createdByCol,
	translateCell,
} from "../utils/format";
import { useAuth } from "../utils/auth";

const OrgTable = () => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const { role } = user;

	return (
		<div>
			<h1 className="bordered">
				{t("title.table", { param: t("title.org") })}
			</h1>
			<div className="mt-4">
				<Table
					url="/org/table"
					renderHeader={() => {
						return (
							<div className="flex justify-end">
								<div className="mb-4">
									<Link to="new" className="button primary">
										{t("button.create")}
										<HiArrowNarrowRight className="text-lg ml-1" />
									</Link>
								</div>
							</div>
						);
					}}
					defaultFind={role === "superior" ? null : { createdby: user.id }}
					columns={[
						{
							key: "logo",
							label: "org.logo",
							render: (row) => formatMedia(row, "logo", "name"),
							center: true,
						},
						{
							key: "region",
							label: "org.region",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/static/region",
							},
						},
						{
							key: "register",
							label: "org.register",
							sortable: true,
							filter: true,
							render: (row) => formatLink(row, "register", `${row._id}`),
						},
						// {
						// 	key: "register",
						// 	label: "org.stats",
						// 	render: (row) =>
						// 		formatLink(t("button.view"), null, `${row._id}/dashboard`),
						// },
						{
							key: "name",
							label: "org.name",
							sortable: true,
							filter: true,
						},
						{
							key: "user_count",
							label: "org.user_count",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "contract_number",
							label: "contract.number",
							sortable: true,
							filter: true,
							render: (row) =>
								formatLink(
									row,
									"contract_number",
									`/admin/contract/${row.contract}`
								),
						},
						{
							key: "contract_status",
							label: "contract.status",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/static/contract_status",
							},
							render: (row) =>
								translateCell(row, t, "contract_status", "contract_status"),
						},
						{
							key: "contact",
							label: "org.contact",
							sortable: true,
							filter: true,
						},
						{
							key: "contract_price",
							label: "contract.price",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "contract_start",
							label: "contract.start",
							sortable: true,
							filter: { type: "date" },
							render: (row) => formatDate(row, "contract_start"),
						},
						{
							key: "contract_end",
							label: "contract.end",
							sortable: true,
							filter: { type: "date" },
							render: (row) => formatDate(row, "contract_end"),
						},
						createdByCol,
					]}
				/>
			</div>
		</div>
	);
};

export default OrgTable;
