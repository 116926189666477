import { Formik, Form as FormikForm } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Loader, Back } from "../template";
import { useEffect } from "react";
import { mainApi } from "../utils/api";
import { confirmPopup, successAlert, translateError } from "../utils/alert";
import { useState } from "react";
import {
	RiCheckLine,
	RiCloseLine,
	RiDeleteBin2Fill,
	RiEdit2Fill,
} from "react-icons/ri";

const Form = ({
	id,
	editable,
	children,
	model,
	init,
	success,
	validationSchema,
	beforeSubmit,
	containerClass = "",
	view,
	isDeletable,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(id);
	const [submited, setSubmited] = useState();
	const [initialValues, setInitialValues] = useState(init);

	useEffect(() => {
		const fetch = async (id) => {
			setLoading(true);
			const response = await mainApi(
				disabled && view ? `/${model}/view/${id}` : `/${model}/${id}`
			);
			response && setInitialValues(response.data);
			setLoading(false);
		};

		if (id) fetch(id);
	}, [model, id, disabled, view]);

	const formik = {
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const data = beforeSubmit ? beforeSubmit(values) : values;
				const response = await mainApi({
					url: `/${model}/${id ?? ""}`,
					method: id ? "PUT" : "POST",
					data,
				});

				setSubmitting(false);

				if (response) {
					setSubmited(new Date());

					if (success) {
						setDisabled(true);
						success && success({ response: response.data });
					} else {
						navigate(-1);
						successAlert();
					}
				}
			} catch (error) {
				translateError(error);
			}
		},
	};

	const deleteItem = async () => {
		let resonse = await mainApi({
			url: `/${model}/${id}`,
			method: "DELETE",
		});

		if (resonse) {
			successAlert();
			navigate(-1);
		}
	};

	return (
		<div className="relative">
			<Formik {...formik}>
				{({ isSubmitting, values, ...rest }) => {
					const isLoading = loading || isSubmitting;
					const isEditable =
						typeof editable === "function" ? editable(values) : editable;

					return (
						<FormikForm className={containerClass} autoComplete="off">
							{isLoading && <Loader />}
							<div className="flex justify-between items-center mb-4">
								<div>
									<Back />
								</div>
								<div className="flex flex-row">
									{isEditable && !disabled && id && (
										<button
											type="button"
											className="mr-2"
											onClick={() => {
												setDisabled(true);
											}}
										>
											<RiCloseLine className="mr-1 text-lg" />
											{t("button.cancel")}
										</button>
									)}
									{isEditable && isDeletable && disabled && (
										<div className="mr-2">
											<button
												type="button"
												className="red"
												onClick={() => {
													confirmPopup("message.delete").then((result) => {
														if (result.isConfirmed) {
															deleteItem();
														}
													});
												}}
											>
												<RiDeleteBin2Fill className="mr-1 text-lg" />
												{t("button.delete")}
											</button>
										</div>
									)}
									{isEditable && disabled && (
										<div>
											<button
												type="button"
												className="primary"
												onClick={() => {
													setDisabled(!disabled);
												}}
											>
												<RiEdit2Fill className="mr-1 text-lg" />
												{t("button.edit")}
											</button>
										</div>
									)}
									{!disabled && (
										<button type="submit" className="secondary">
											{id ? t("button.update") : t("button.create")}
											<RiCheckLine className="ml-1 text-lg" />
										</button>
									)}
								</div>
							</div>
							{disabled && view
								? view(values)
								: children &&
								  children({
										loading: isLoading,
										submited,
										values,
										id,
										disabled,
										t,
										form: rest,
								  })}
						</FormikForm>
					);
				}}
			</Formik>
		</div>
	);
};

export default Form;
