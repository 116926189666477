import Comments from "../comment/View";
import { CreatedBy, Gallery } from "../template";

const NewsView = ({ id, createdby, created, info, attachments }) => {
	return (
		<div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
				<div>
					<CreatedBy user={createdby} date={created}></CreatedBy>
					<div className="text-sm my-4">{info}</div>
					<Gallery files={attachments} />
				</div>
				<div>
					<Comments model="news" id={id} />
				</div>
			</div>
		</div>
	);
};

export default NewsView;
