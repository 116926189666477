import { useTranslation } from "react-i18next";
import { CreatedBy, Status, Watermark } from "../template";
import objectPath from "object-path";
import { formatDate, formatPrice } from "../utils/format";
import { renderMedia } from "../inputs/FileUpload";
import HumanAnatomy from "./anatomy/HumanAnatomy";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import { RiPrinterLine } from "react-icons/ri";
import Preliminaries from "./Preliminaries";
import Review from "./Review";

export const LabelValue = ({ inline, path, value }) => {
	const { t } = useTranslation();

	return (
		<div className={`text-sm mb-2 ${inline ? "flex" : ""}`}>
			<div className={`font-bold ${inline ? "w-64 flex-shrink-0" : ""}`}>
				{t(path)}
			</div>
			<div className="whitespace-pre-line flex-1">{value}</div>
		</div>
	);
};

const Cause = (props) => {
	const {
		category,
		sub_category,
		description,
		action,
		responsible,
		deadline,
		status,
	} = props;
	const { t } = useTranslation();

	return (
		<div className="border p-2 shadow-md mt-5">
			<Status
				color={status === "fixed" ? "green" : "red"}
				left={category?.name}
				right={t(`const.cause_status.${status}`)}
			/>
			<div className="px-2">
				<LabelValue
					inline
					path={"incident.cause_category"}
					value={category?.name}
				></LabelValue>
				{sub_category && (
					<LabelValue
						inline
						path={"incident.cause_subcategory"}
						value={sub_category?.name}
					></LabelValue>
				)}
				<div className="p-2"></div>
				<LabelValue
					inline
					path={"incident.cause_description"}
					value={description}
				></LabelValue>
				<LabelValue
					inline
					path={"incident.cause_responsible"}
					value={responsible.map(
						({ firstname, lastname, position }) =>
							`${firstname} ${lastname} (${position})`
					)}
				></LabelValue>
				<LabelValue
					inline
					path={"incident.cause_deadline"}
					value={formatDate(deadline)}
				></LabelValue>
				<LabelValue
					inline
					path={"incident.cause_action"}
					value={action}
				></LabelValue>
			</div>
		</div>
	);
};

const IncidentView = ({
	id,
	createdby,
	created,
	status,
	category,
	sub_category,
	location,
	date,
	company_type,
	company,
	human_injury,
	human_injuries = [],

	info,
	learnt,

	attachments = [],
	media = [],
	causes = [],

	workers = [],
	worker_count,

	reviews = [],

	...props
}) => {
	const { t } = useTranslation();
	const printRef = useRef();

	return (
		<div>
			<div className="flex justify-end">
				<ReactToPrint
					trigger={() => (
						<div>
							<button className="secondary" type="button">
								<RiPrinterLine className="text-2xl mr-2" />
								{t("button.print")}
							</button>
						</div>
					)}
					content={() => printRef.current}
				/>
			</div>
			<div className="container mx-auto bg-white p-5" ref={printRef}>
				<div className="uppercase text-2xl text-center">
					{t("title.incident")}
				</div>
				<div className="my-5">
					<CreatedBy user={createdby} date={created}></CreatedBy>
				</div>
				<Status
					color={status === "verified" ? "green" : "red"}
					right={t(`const.incident_status.${status}`)}
				/>
				<div className="flex flex-wrap gap-5 relative">
					<Watermark />
					<div className="flex-1 flex-shrink-0">
						<LabelValue
							inline
							path={"incident.date"}
							value={formatDate(date)}
						></LabelValue>
						<LabelValue
							inline
							path={"incident.location"}
							value={objectPath.get(location, "name")}
						></LabelValue>
						{company_type && (
							<LabelValue
								inline
								path={"incident.company_type"}
								value={t(`const.company_type.${company_type}`)}
							></LabelValue>
						)}
						{company && (
							<LabelValue
								inline
								path={"incident.company"}
								value={company}
							></LabelValue>
						)}
						{props.damage && (
							<LabelValue
								inline
								path={"incident.damage"}
								value={formatPrice(props, "damage")}
							></LabelValue>
						)}
						<LabelValue
							inline
							path={"incident.category"}
							value={objectPath.get(category, "name")}
						></LabelValue>
						<LabelValue
							inline
							path={"incident.sub_category"}
							value={objectPath.get(sub_category, "name")}
						></LabelValue>
					</div>

					{human_injury && (
						<LabelValue
							value={
								<div className="flex justify-end gap-5">
									<div className="">
										<div className="font-bold">
											{t("incident.human_injuries")}:
										</div>
										{human_injuries.map((i) => (
											<div key={i}>{t(`const.human_injuries.${i}`)}</div>
										))}
									</div>
									<HumanAnatomy
										highlights={human_injuries}
										height={300}
									></HumanAnatomy>
								</div>
							}
						></LabelValue>
					)}
				</div>
				<div className="border-b my-5"></div>
				<LabelValue path={"incident.info"} value={info}></LabelValue>
				<div className="border-b my-5"></div>
				<LabelValue
					value={
						<div className="flex flex-wrap gap-5">
							{media.map((m, index) => (
								<div key={index} className="w-64">
									{renderMedia(m, true, true)}
								</div>
							))}
						</div>
					}
				></LabelValue>
				<div className="border-b my-5"></div>
				<LabelValue
					path={"incident.attachments"}
					value={
						<div className="flex flex-wrap gap-5">
							{attachments.map((m, index) => (
								<div key={index} className="w-64">
									{renderMedia(m, false)}
								</div>
							))}
						</div>
					}
				></LabelValue>
				<div className="border-b my-5"></div>
				<LabelValue path={"incident.learnt"} value={learnt}></LabelValue>
				<div className="border-b my-5"></div>
				<div className="relative">
					<Watermark />
					<LabelValue
						path={"incident.causes"}
						value={causes.map((cause, index) => {
							return <Cause key={index} {...cause}></Cause>;
						})}
					></LabelValue>
				</div>
				<div className="mt-5">
					<div className="p-5 border shadow-md mb-5">
						<LabelValue
							inline
							path={"incident.workers"}
							value={workers.map((worker) => (
								<Review
									id={id}
									key={worker._id}
									worker={worker}
									reviews={reviews}
								></Review>
							))}
						></LabelValue>
						<LabelValue
							inline
							path={"incident.preliminary"}
							value={
								<Preliminaries
									incident={id}
									workers={workers.map(({ _id }) => _id)}
								/>
							}
						></LabelValue>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IncidentView;
