import Comments from "../comment/View";
import UserSignatures from "../user/Signatures";
import { useTranslation } from "react-i18next";
import { translateCell } from "../utils/format";
import { Pie } from "../charts";
import { CreatedBy, Media, Status } from "../template";
import { RiStarFill } from "react-icons/ri";

const InductionPlanView = ({
	id,
	_id,
	createdby,
	created,
	template,
	type,
	user_count,
	signature_count,
	text,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
				<div>
					<CreatedBy user={createdby} date={created}></CreatedBy>
					<Status
						color={"green"}
						left={
							template.point && (
								<div className="flex items-center">
									<RiStarFill className="mr-1" />
									{template.point} {t("fields.has-point")}
								</div>
							)
						}
						right={t(`const.induction_type.${type}`)}
					></Status>
					{template.name && (
						<div className="my-4 uppercase font-semibold">{template.name}</div>
					)}
					{text && <div className="info">{text}</div>}
					{template.info && <div className="info">{template.info}</div>}
					<Media files={template.media} />
					{_id && (
						<Pie
							data={[
								{
									value: signature_count,
									name: t("const.sign_status.signed"),
								},
								{
									value: user_count - signature_count,
									name: t("const.sign_status.notsigned"),
								},
							]}
							height={300}
							renderLabel={({ name, value }) => `${name}: ${value}`}
						/>
					)}
				</div>
				<div>
					<Comments model="induction" id={id} />
				</div>
			</div>
			<div className="mt-4">
				<UserSignatures
					model="induction"
					id={id}
					title={t("title.signature-common")}
					statusColumn={[
						{
							key: "status",
							label: "fields.status",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/static/sign_status",
							},
							render: (row) => {
								return translateCell(row, t, "sign_status", "status");
							},
						},
					]}
				></UserSignatures>
			</div>
		</div>
	);
};

export default InductionPlanView;
