import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatString,
	createdByCol,
	translateCell,
	formatLink,
} from "../utils/format";

const ChecklistTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/checklist/table"
			columns={[
				{
					key: "category",
					label: "checklist.category",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/category/checklist",
					},
					render: (row) => formatString(row, "category.name"),
				},
				{
					key: "template",
					label: "checklist.template",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/checklist-template",
					},
					render: (row) => formatLink(row, "template.name", `${row._id}`),
				},
				{
					key: "identifier",
					label: "checklist.identifier",
					sortable: true,
					filter: true,
				},
				{
					key: "location",
					label: "checklist.location",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/location",
					},
					render: (row) => formatString(row, "location.name"),
				},
				{
					key: "total",
					label: "checklist.total",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "normal_count",
					label: "checklist.normal_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "issue_count",
					label: "checklist.issue_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "status",
					label: "checklist.status",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/checklist_status",
					},
					render: (row) => translateCell(row, t, "checklist_status", "status"),
				},
				{
					key: "comment_count",
					label: "fields.comment_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				{
					key: "unit",
					label: "fields.unit",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/unit",
					},
					render: (row) => formatString(row, "unit.name"),
				},
				createdByCol,
			]}
		/>
	);
};

export default ChecklistTable;
