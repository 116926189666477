import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatString,
	translateCell,
	formatLink,
	nameFormat,
} from "../utils/format";

const ViolationTable = () => {
	const { t } = useTranslation();

	return (
		<div>
			{/* <h1 className="bordered">
				{t("title.table", { param: t("title.violation") })}
			</h1> */}
			<div className="">
				<Table
					url="/violation/table"
					columns={[
						{
							key: "category",
							label: "violation.category",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/category/violation",
							},
							render: (row) => formatLink(row, "category.name", `${row._id}`),
						},
						{
							key: "location",
							label: "violation.location",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/select/location",
							},
							render: (row) => formatString(row, "location.name"),
						},
						{
							key: "status",
							label: "violation.status",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/static/violation_status",
							},
							render: (row) =>
								translateCell(row, t, "violation_status", "status"),
						},
						{
							key: "violator",
							label: "violation.violator",
							sortable: true,
							filter: {
								type: "select",
								axio: `/const/select/user`,
								labelTemplate:
									// eslint-disable-next-line no-template-curly-in-string
									"${firstname} ${lastname} (${position})",
								nullType: "array",
							},
							width: 220,
							render: ({ violator }) => {
								return (
									Array.isArray(violator) &&
									violator.length > 0 &&
									violator.map(({ firstname, lastname }, index) => (
										<div key={index} className="flex mb-1">
											{nameFormat(firstname, lastname)}
										</div>
									))
								);
							},
						},
						{
							key: "comment_count",
							label: "fields.comment_count",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "created",
							label: "fields.created",
							sortable: true,
							filter: { type: "date" },
							render: (row) => formatDate(row, "created"),
						},
					]}
				/>
			</div>
		</div>
	);
};

export default ViolationTable;
