import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatString,
	createdByCol,
	formatLink,
	translateCell,
} from "../utils/format";

const PermissionTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/permission/table"
			columns={[
				{
					key: "template",
					label: "permission.template",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/permission-template",
					},
					render: (row) => formatLink(row, "template.name", `${row._id}`),
				},
				{
					key: "location",
					label: "permission.location",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/location",
					},
					render: (row) => formatString(row, "location.name"),
				},
				{
					key: "employee.lastname",
					label: "user.lastname",
					sortable: true,
					filter: true,
				},
				{
					key: "employee.firstname",
					label: "user.firstname",
					sortable: true,
					filter: true,
				},
				{
					key: "employee.phone",
					label: "user.phone",
					sortable: true,
					filter: true,
				},
				{
					key: "employee.position",
					label: "user.position",
					sortable: true,
					filter: true,
				},
				{
					key: "status",
					label: "permission.status",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/permission_status",
					},
					render: (row) => translateCell(row, t, "permission_status", "status"),
				},
				{
					key: "comment_count",
					label: "fields.comment_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				{
					key: "unit",
					label: "fields.unit",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/unit",
					},
					render: (row) => formatString(row, "unit.name"),
				},
				createdByCol,
			]}
		/>
	);
};

export default PermissionTable;
