import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { fileValidation, stringValidation } from "../utils/validations";
import { CoordinateSelect, FileUpload, Text } from "../inputs";
import { DEBUG } from "../defines";
import { randomData } from "../utils/demo";

const LocationForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "location",
		id,
		init: {
			name: DEBUG ? randomData("location") : "",
			address: DEBUG ? randomData("address") : "",
			exit_schema: [],
			coordinate: null,
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			address: stringValidation(true, 5000),
			exit_schema: fileValidation(),
			// coordinate: coordinateValidation(true),
		}),
	};

	return (
		<Form {...props}>
			{({ disabled, submited }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="name"
								label="location.name"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="address"
								label="location.address"
								disabled={disabled}
								component={Text}
								type="textarea"
							/>
							<Field
								name="coordinate"
								label="location.coordinate"
								disabled={disabled}
								component={CoordinateSelect}
							/>
						</div>
						<Field
							name="exit_schema"
							label="location.exit_schema"
							disabled={disabled}
							submited={submited}
							component={FileUpload}
							count={10}
							root="exit_schema"
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default LocationForm;
