import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { numberValidation, stringValidation } from "../utils/validations";
import { FileUpload, Select, Text } from "../inputs";
import View from "./View";

const PpeItemForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "ppe-item",
		id,
		init: {
			type: "",
			name: "",
			price: 0,
			balance: 0,
			media: [],
		},
		validationSchema: Yup.object().shape({
			type: stringValidation(true),
			name: stringValidation(true),
			price: numberValidation(true),
		}),
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props}>
			{({ disabled, submited }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="type"
								label="ppe-item.type"
								disabled={disabled}
								component={Select}
								axio="/const/static/ppe_type"
							/>
							<Field
								name="name"
								label="ppe-item.name"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="price"
								label="ppe-item.price"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="balance"
								label="ppe-item.balance"
								disabled={disabled}
								component={Text}
							/>
						</div>
						<Field
							name="media"
							label="ppe-item.media"
							disabled={disabled}
							submited={submited}
							component={FileUpload}
							root="ppe"
							accept="image/*,video/mp4,application/pdf"
							count={5}
							limit={500}
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default PpeItemForm;
