import Comments from "../comment/View";
import { useTranslation } from "react-i18next";
import { CreatedBy, QuestionType, Status, SubStatus } from "../template";
import { RiCheckLine, RiMapPin2Line } from "react-icons/ri";
import objectPath from "object-path";

const StepView = ({
	id,
	createdby,
	created,
	issue_count,
	answers,
	template,
	location,
	task,
}) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
				<Status
					color={issue_count > 0 ? "red" : "green"}
					left={
						<div className="flex items-center">
							<RiCheckLine className="mr-1" />
							{issue_count}/{answers?.length}
						</div>
					}
					right={issue_count === 0 ? t(`step.normal`) : t(`step.issue`)}
				/>
				<SubStatus
					left={objectPath.get(template, "name")}
					right={
						<div className="flex">
							<RiMapPin2Line className="mr-1" />
							{objectPath.get(location, "name")}
						</div>
					}
				></SubStatus>
				<div className="my-4 font-semibold">{task}</div>
				{Array.isArray(answers) &&
					answers.map(({ question, value }, index) => {
						return (
							<div key={index} className="mb-2 pb-2 border-b border-dashed">
								<div className="flex justify-between">
									<div>
										{index + 1}. {question}
									</div>
									<QuestionType type={"check"} value={value} />
								</div>
							</div>
						);
					})}
			</div>
			<div>
				<Comments model="step" id={id} />
			</div>
		</div>
	);
};

export default StepView;
