import { useEffect } from "react";
import { useState } from "react";
import GoogleMap from "../map/GoogleMap";

const Location = ({ data }) => {
	const [map, setMap] = useState();
	const [maps, setMaps] = useState();
	const [heatmap, setHeatmap] = useState();

	useEffect(() => {
		setHeatmap({
			positions: data
				.filter(({ coordinate }) => coordinate.lat && coordinate.lng)
				.map(({ coordinate, total }) => {
					return {
						...coordinate,
						weight: total,
					};
				}),
			options: {
				radius: 20,
				opacity: 1,
			},
		});

		if (map && maps) {
			const bounds = new maps.LatLngBounds();

			data.forEach(({ coordinate }) => {
				if (coordinate.lat && coordinate.lng)
					bounds.extend(new maps.LatLng(coordinate.lat, coordinate.lng));
			});

			map.fitBounds(bounds);
		}
	}, [data, map, maps]);

	return (
		<GoogleMap
			height={600}
			heatmap={heatmap}
			loaded={(map, maps) => {
				setMap(map);
				setMaps(maps);
			}}
		></GoogleMap>
	);
};

export default Location;
