import * as Yup from "yup";
import { useParams, Link } from "react-router-dom";
import { Field, TimeLineItem, Form } from "../template";
import {
	emailValidation,
	fileValidation,
	phoneValidation,
	stringValidation,
} from "../utils/validations";
import { FileUpload, Radios, Select, Text } from "../inputs";
import { DEBUG } from "../defines";
import { randomData, randomNumber } from "../utils/demo";
import { t } from "i18next";
import { HiArrowNarrowRight } from "react-icons/hi";
import ContractItem from "./ContractItem";
import { useAuth } from "../utils/auth";
import Admins from "../user/Admins";

const OrgForm = ({ editable }) => {
	const { id } = useParams();
	const { user } = useAuth();

	const props = {
		editable,
		model: "org",
		id,
		init: {
			logo: [],
			register: DEBUG ? randomNumber(7) : "",
			region: "",
			contact: "",
			name: DEBUG ? randomData("org") + " ХХК" : "",
			pin: DEBUG ? randomNumber(4) : "",
			...(id
				? null
				: {
						admin: {
							lastname: DEBUG ? randomData("name") : "",
							firstname: DEBUG ? randomData("name") : "",
							position: DEBUG ? "ахлах мэргэжилтэн" : "",
							unit: DEBUG ? "захиргаа удирдлагын газар" : "",
							phone: DEBUG ? randomNumber(8) : "",
							email: DEBUG ? randomData("email") : "",
						},
				  }),
		},
		validationSchema: Yup.object().shape({
			logo: fileValidation(!DEBUG),
			register: stringValidation(true),
			name: stringValidation(true),
			pin: stringValidation(true),
			region: stringValidation(true),
			contact: stringValidation(true),
			...(id
				? null
				: {
						admin: Yup.object().shape({
							lastname: stringValidation(true),
							firstname: stringValidation(true),
							position: stringValidation(true),
							unit: stringValidation(true),
							phone: phoneValidation(true),
							email: emailValidation(true),
						}),
				  }),
		}),
	};

	return (
		<>
			<h1 className="bordered">
				{t(id ? "title.edit" : "title.create", {
					param: t("title.org"),
				})}
			</h1>
			<Form {...props}>
				{({ disabled, submited, t }) => {
					return (
						<div
							className={`p-4 bg-white grid grid-cols-1 ${
								id ? "lg:grid-cols-3" : "lg:grid-cols-2"
							} gap-8`}
						>
							<div className="">
								<Field
									name="logo"
									label="org.logo"
									component={FileUpload}
									disabled={disabled}
									submited={submited}
								/>
								<Field
									name="register"
									label="org.register"
									type="number"
									disabled={disabled}
									component={Text}
								/>
								<Field
									name="region"
									label="org.region"
									disabled={disabled}
									component={Select}
									axio={"/const/static/region"}
									disableTranslate={true}
								/>
								<Field name="name" label="org.name" disabled={disabled} />
								<Field
									name="pin"
									label="org.pin"
									type="number"
									disabled={disabled}
									component={Text}
								/>
								<Field
									name="contact"
									label="org.contact"
									type="textarea"
									disabled={disabled}
									component={Text}
								/>
							</div>
							{id ? (
								<div className="col-span-2">
									<Field
										name="contract"
										label="org.contract"
										component={Radios}
										disabled={disabled}
										axio={`/contract/org/${id}`}
										renderItem={({ _id, ...contract }, selected, index) => (
											<TimeLineItem key={index} active={selected === _id}>
												<ContractItem {...contract} t={t} disabled={disabled} />
											</TimeLineItem>
										)}
									/>
									{!disabled && (
										<Link
											to={`/admin/contract/create/${id}`}
											className="button gray"
										>
											{t("contract.create")}
											<HiArrowNarrowRight className="text-lg ml-1" />
										</Link>
									)}
								</div>
							) : (
								<div>
									<div className="mb-8 p-4 bg-def-gray border shadow-lg">
										<h1 className="bordered">{t(`org.contents`)}</h1>
										<Field
											name="contents.checklist"
											label="title.checklist-template"
											disabled={disabled}
											component={Select}
											isMulti={true}
											axio="/const/select/checklist-template"
										/>
										<Field
											name="contents.step"
											label="title.step-template"
											disabled={disabled}
											component={Select}
											isMulti={true}
											axio="/const/select/step-template"
										/>
										<Field
											name="contents.permission"
											label="title.permission-template"
											disabled={disabled}
											component={Select}
											isMulti={true}
											axio="/const/select/permission-template"
										/>
										<Field
											name="contents.survey"
											label="title.survey-template"
											disabled={disabled}
											component={Select}
											isMulti={true}
											axio="/const/select/survey-template"
										/>
									</div>
									<div className="p-4 bg-def-gray border shadow-lg">
										<h1 className="bordered">{t(`role.admin`)}</h1>
										<Field
											name="admin.lastname"
											label="user.lastname"
											disabled={disabled}
										/>
										<Field
											name="admin.firstname"
											label="user.firstname"
											disabled={disabled}
										/>
										<Field
											name="admin.position"
											label="user.position"
											disabled={disabled}
										/>
										<Field
											name="admin.unit"
											label="user.unit"
											disabled={disabled}
										/>
										<Field
											name="admin.phone"
											label="user.phone"
											type="number"
											component={Text}
											disabled={disabled}
										/>
										<Field
											name="admin.email"
											label="user.email"
											disabled={disabled}
										/>
										<Field
											name="admin.password"
											label="user.password"
											disabled={disabled}
										/>
									</div>
								</div>
							)}
						</div>
					);
				}}
			</Form>
			{id && user.role === "superior" && (
				<div className="mt-8">
					<Admins org_id={id} />
				</div>
			)}
		</>
	);
};

export default OrgForm;
