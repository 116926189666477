import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import { formatDate, formatLink, createdByCol } from "../utils/format";

const PermissionTamplateTable = ({ role }) => {
	const isPublic = role === "superior";
	const { t } = useTranslation();

	return (
		<Table
			url="/permission-template/table"
			defaultFind={{
				...(isPublic ? { org: null } : null),
			}}
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button primary">
								{t("button.create")}
								<HiArrowNarrowRight className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "name",
					label: "permission-template.name",
					sortable: true,
					filter: true,
					width: 250,
					render: (row) => formatLink(row, "name", `${row._id}`),
				},
				{
					key: "question_count",
					label: "permission-template.question_count",
					sortable: true,
					filter: { type: "number" },
				},
				...(isPublic
					? []
					: [
							{
								key: "point",
								label: "permission-template.point",
								width: 280,
								sortable: true,
								filter: { type: "number" },
							},
							{
								key: "worker_count",
								label: "permission-template.worker_count",
								sortable: true,
								width: 200,
								filter: { type: "number" },
							},
					  ]),
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default PermissionTamplateTable;
