import Comments from "../comment/View";
import { useTranslation } from "react-i18next";
import {
	CreatedBy,
	Gallery,
	History,
	QuestionType,
	Status,
	SubStatus,
} from "../template";
import { RiCheckLine, RiMapPin2Line } from "react-icons/ri";
import objectPath from "object-path";

const renderExtra = (extra) => {
	if (Array.isArray(extra)) return <Gallery files={extra} />;

	return extra;
};

const ChecklistView = ({
	id,
	createdby,
	created,
	history,
	status,
	issue_count,
	answers,
	total,
	template,
	location,
	identifier,
}) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
				<Status
					color={["normal", "completed"].includes(status) ? "green" : "red"}
					left={
						<div className="flex items-center">
							<RiCheckLine className="mr-1" />
							{issue_count}/{total}
						</div>
					}
					right={t(`const.checklist_status.${status}`)}
				/>
				<SubStatus
					left={objectPath.get(template, "name")}
					right={
						<div className="flex">
							<RiMapPin2Line className="mr-1" />
							{objectPath.get(location, "name")}
						</div>
					}
				></SubStatus>
				<div className="my-4 font-semibold">{identifier}</div>
				{Array.isArray(answers) &&
					answers.map(
						({ question, issue, type, value, extra, extraInfo }, index) => {
							return (
								<div key={index} className="mb-2 pb-2 border-b border-dashed">
									<div className="flex justify-between">
										<div className="font-bold">
											{index + 1}. {question}
										</div>
										<QuestionType type={type} value={value} />
									</div>
									{typeof value !== "boolean" && <div>{value}</div>}
									{extraInfo && <div>{extraInfo}</div>}
									{extra && (
										<div className="flex justify-between my-2">
											{renderExtra(extra)}
										</div>
									)}
									{value === false && issue && (
										<div>
											<div className="text-sm">{issue.desc}</div>
											<Gallery files={issue.attachments} />
										</div>
									)}
								</div>
							);
						}
					)}
			</div>
			<div>
				<History items={history} status_root="checklist_status" />
			</div>
			<div>
				<Comments model="checklist" id={id} />
			</div>
		</div>
	);
};

export default ChecklistView;
