import { useTranslation } from "react-i18next";
import { RiQuestionFill, RiStarFill } from "react-icons/ri";
import { CreatedBy, Status, QuestionType, Workers } from "../template";

const PermissionTemplateView = ({
	isPublic,
	createdby,
	created,
	name,
	workers,
	worker_count,
	point,
	questions,
	question_count,
}) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
				<Status
					color={"green"}
					left={
						point && (
							<div className="flex items-center">
								<RiStarFill className="mr-1" />
								{point} {t("fields.has-point")}
							</div>
						)
					}
					right={question_count + " " + t("fields.has-question")}
				></Status>
				{name && <div className="my-4 uppercase font-semibold">{name}</div>}
				{!isPublic && (
					<Workers
						workers={workers}
						label={`${t("permission-template.workers")} (${worker_count}):`}
					/>
				)}
			</div>
			<div>
				<div className="uppercase mb-2 pb-2 border-b">
					{t("permission-template.questions")}
				</div>
				<div className="text-sm">
					{Array.isArray(questions) &&
						questions.map(({ question, type, tip }, index) => {
							return (
								<div key={index} className="mb-2 pb-2 border-b border-dashed">
									<div className="flex justify-between">
										<div>
											{index + 1}. {question}
										</div>
										<QuestionType type={type} />
									</div>
									{tip && (
										<div className="flex p-2 bg-def-gray mt-2">
											<RiQuestionFill className="text-xl mr-1" />
											{tip}
										</div>
									)}
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default PermissionTemplateView;
