import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatMedia,
	formatLink,
	translateCell,
} from "../utils/format";
import { useAuth } from "../utils/auth";
import { confirmPopup, successAlert } from "../utils/alert";
import { mainApi } from "../utils/api";

const Admins = ({ org_id }) => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const { role } = user;

	return (
		<>
			<h1 className="bordered">{t("role.admin")}</h1>
			<Table
				url={`/user/table/${org_id}`}
				defaultFind={{ role: { $in: ["admin", "moderator"] } }}
				columns={[
					{
						key: "profile",
						label: "user.profile",
						render: (row) => formatMedia(row, "profile", "name"),
					},
					{
						key: "phone",
						label: "user.phone",
						sortable: true,
						filter: true,
						render:
							role === "admin"
								? (row) => formatLink(row, "phone", `${row._id}`)
								: null,
					},
					{
						key: "role",
						label: "user.role",
						sortable: true,
						filter: {
							type: "select",
							axio: "/const/static/role",
						},
						render: (row) => translateCell(row, t, "role", "role"),
					},
					{
						key: "lastname",
						label: "user.lastname",
						sortable: true,
						filter: true,
					},
					{
						key: "firstname",
						label: "user.firstname",
						sortable: true,
						filter: true,
					},

					{
						key: "created",
						label: "fields.created",
						sortable: true,
						filter: { type: "date" },
						render: (row) => formatDate(row, "created"),
					},
					{
						key: "",
						label: "",
						render: (row) => (
							<button
								className="button"
								type="button"
								onClick={async () => {
									const { isConfirmed } = await confirmPopup(
										"button.reset_password"
									);
									if (isConfirmed) {
										const response = await mainApi(
											`/user/reset-password/${row._id}`
										);
										if (response) successAlert();
									}
								}}
							>
								{t("button.reset_password")}
							</button>
						),
					},
				]}
			/>
		</>
	);
};

export default Admins;
