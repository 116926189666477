import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { stringValidation } from "../utils/validations";
import { Select, Text } from "../inputs";
import { DEBUG } from "../defines";
import { randomData } from "../utils/demo";

const UnitForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "unit",
		id,
		init: {
			name: DEBUG ? randomData("unit") : "",
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
		}),
	};

	return (
		<Form {...props} containerClass="w-full md:w-1/2">
			{({ disabled }) => {
				return (
					<div>
						<Field
							name="parent"
							label="unit.parent"
							disabled={disabled}
							component={Select}
							axio="/const/select/unit"
						/>
						<Field
							name="name"
							label="unit.name"
							disabled={disabled}
							component={Text}
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default UnitForm;
