import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import { formatDate, formatString, translateCell } from "../utils/format";

const PointTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/point/table"
			columns={[
				{
					key: "unit",
					label: "fields.unit",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/unit",
					},
					render: (row) => formatString(row, "unit.name"),
				},
				{
					key: "employee.lastname",
					label: "user.lastname",
					sortable: true,
					filter: true,
				},
				{
					key: "employee.firstname",
					label: "user.firstname",
					sortable: true,
					filter: true,
				},
				{
					key: "employee.phone",
					label: "user.phone",
					sortable: true,
					filter: true,
				},
				{
					key: "employee.position",
					label: "user.position",
					sortable: true,
					filter: true,
				},
				{
					key: "action",
					label: "point.action",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/point_action",
					},
					render: (row) => translateCell(row, t, "point_action", "action"),
				},
				{
					key: "info",
					label: "point.info",
					sortable: true,
					filter: true,
				},
				{
					key: "point",
					label: "point.point",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
			]}
		/>
	);
};

export default PointTable;
