import { format } from "date-fns";
import objectPath from "object-path";
import { DATE_FORMAT } from "../defines";
import { getAbsolutePath, nameFormat } from "../utils/format";

const CreatedBy = ({ user, date, hidden }) => {
	let firstname = objectPath.get(user, "firstname");
	let lastname = objectPath.get(user, "lastname");
	let position = objectPath.get(user, "position");

	let src = hidden
		? "/images/profile.png"
		: getAbsolutePath(user?.profile, { placeholder: "/images/profile.png" });

	return (
		<div className="flex">
			<img
				className="w-10 h-10 rounded-full border object-contain mr-2"
				src={src}
				crossOrigin="anonymous"
				alt={hidden ? "anonymous" : firstname}
			/>
			<div className="flex-grow">
				<div className="text-sm font-semibold">
					{hidden ? "************" : nameFormat(firstname, lastname)}
				</div>
				<div className="text-xs text-def-light flex justify-between items-center">
					<div>{hidden ? "**************" : position}</div>
					{date && <div>{format(new Date(date), DATE_FORMAT)}</div>}
				</div>
			</div>
		</div>
	);
};

export default CreatedBy;
