import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Form } from "../template";

const PointForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable: ({ status }) => editable && status !== "completed",
		model: "point",
		id,
		init: {},
		validationSchema: Yup.object().shape({}),
	};

	return (
		<Form {...props}>
			{({ disabled }) => {
				return <div className="grid grid-cols-1 md:grid-cols-2 gap-8"></div>;
			}}
		</Form>
	);
};

export default PointForm;
