import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Form } from "../template";
import View from "./View";

const StepForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable: false,
		model: "step",
		id,
		init: {},
		validationSchema: Yup.object().shape({}),
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props}>
			{({ disabled }) => {
				return <div className="grid grid-cols-1 md:grid-cols-2 gap-8"></div>;
			}}
		</Form>
	);
};

export default StepForm;
