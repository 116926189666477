import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ENV } from "../defines";
import LanguageSwitch from "../i18n/Switch";
import { Back } from "../template";

const POLICES = [
	{
		children: [0, 1, 2],
	},
	{
		children: [[0, 1, 2, 3, 4, 5, 6]],
	},
];

const Privacy = () => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	return (
		<div className="p-4 w-full md:w-1/2 mx-auto">
			<div className="p-4 mb-4 bg-white">
				<div className="flex justify-end">
					<LanguageSwitch />
				</div>
				<div className="text-sm text-justify">
					{POLICES.map(({ children }, index) => {
						return (
							<div key={index} className="mb-8">
								<div className="font-bold uppercase my-2 text-center">
									{t(`privacy.${index}.title`)}
								</div>
								<ul className="mx-4">
									{children.map((item, childIndex) => {
										if (Array.isArray(item))
											return item.map((child, grandIndex) => {
												return (
													<li key={grandIndex} className="mb-2">
														{t(
															`privacy.${index}.children.${childIndex}.${grandIndex}`,
															{
																param: ENV.toUpperCase(),
															}
														)}
													</li>
												);
											});
										return (
											<li key={childIndex} className="mb-2">
												{t(`privacy.${index}.children.${childIndex}`, {
													param: ENV.toUpperCase(),
												})}
											</li>
										);
									})}
								</ul>
							</div>
						);
					})}
				</div>
			</div>
			<div className="flex">
				<div>
					<Back></Back>
				</div>
			</div>
		</div>
	);
};

export default Privacy;
