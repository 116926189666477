import { Field, Form } from "../template";
import { Select } from "../inputs";
import { useAuth } from "../utils/auth";
import { successAlert } from "../utils/alert";

const point_settings = [
	"hazard",
	"alert",
	"violation",
	// "inspection",
	"incident",
];

const PointSettingsForm = ({ editable }) => {
	const { user } = useAuth();
	const props = {
		editable,
		model: "org",
		id: user.org,
		init: {},
		success: () => {
			successAlert();
		},
	};

	return (
		<>
			<Form {...props}>
				{({ disabled, submited, t, values }) => {
					return (
						<div>
							<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
								{point_settings.map((setting, index) => (
									<Field
										key={index}
										name={`point_settings.${setting}`}
										label={`point_settings.${setting}`}
										disabled={disabled}
										component={Select}
										isSearchable={false}
										axio="/const/static/point"
									/>
								))}
							</div>
						</div>
					);
				}}
			</Form>
		</>
	);
};

export default PointSettingsForm;
