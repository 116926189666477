import {
	RiOrganizationChart,
	RiUserLine,
	RiDashboardLine,
	RiUserSettingsLine,
	RiLogoutBoxRLine,
	RiBallPenLine,
	RiSurveyLine,
	RiAlertLine,
	RiShieldStarLine,
	RiCheckboxMultipleLine,
	RiDirectionLine,
	RiIndeterminateCircleLine,
	RiMeteorLine,
	RiUserFollowLine,
	RiEditBoxLine,
	RiBroadcastLine,
	RiFolder2Line,
	RiListOrdered,
	RiAwardFill,
	RiChatCheckLine,
} from "react-icons/ri";

const AdminMenu = {
	access: [
		{ label: "menu.activity" },
		{
			label: "menu.dashboard",
			path: "",
			icon: <RiDashboardLine />,
		},
		{
			label: "menu.hazard",
			path: "pnl-hazard",
			icon: <RiAlertLine />,
		},
		{
			label: "menu.alert",
			path: "pnl-alert",
			icon: <RiBroadcastLine />,
		},
		{
			label: "menu.violation",
			path: "pnl-violation",
			icon: <RiIndeterminateCircleLine />,
		},
		{
			label: "menu.training",
			path: "pnl-training",
			icon: <RiAwardFill />,
		},
		{
			label: "menu.induction",
			path: "pnl-induction",
			icon: <RiDirectionLine />,
		},
		{
			label: "menu.checklist",
			path: "pnl-checklist",
			icon: <RiChatCheckLine />,
		},
		{
			label: "menu.step",
			path: "pnl-step",
			icon: <RiListOrdered />,
		},
		{
			label: "menu.permission",
			path: "pnl-permission",
			icon: <RiUserFollowLine />,
		},
		{
			label: "menu.ppe",
			path: "pnl-ppe",
			icon: <RiShieldStarLine />,
		},
		{
			label: "menu.survey",
			path: "pnl-survey",
			icon: <RiSurveyLine />,
		},
		{
			label: "menu.incident",
			path: "pnl-incident",
			icon: <RiMeteorLine />,
		},

		{ label: "menu.settings" },
		{ label: "menu.user", path: "pnl-user", icon: <RiUserLine /> },
		{
			label: "menu.document",
			path: "pnl-document",
			icon: <RiFolder2Line />,
		},
		{
			label: "menu.org",
			path: "pnl-org",
			icon: <RiOrganizationChart />,
		},
	],
	profile: [
		{
			label: "menu.profile",
			path: "profile",
			icon: <RiUserSettingsLine />,
		},
		{
			label: "menu.logout",
			path: "/auth/logout",
			icon: <RiLogoutBoxRLine />,
		},
	],
};

const SuperiorMenu = {
	access: [
		{ label: "menu.activity" },
		{ label: "menu.dashboard", path: "", icon: <RiDashboardLine /> },
		{ label: "menu.org", path: "org", icon: <RiOrganizationChart /> },
		{ label: "menu.contract", path: "contract", icon: <RiBallPenLine /> },
		{ label: "menu.manager", path: "manager", icon: <RiUserLine /> },

		{ label: "menu.content" },
		{
			label: "menu.checklist-template",
			path: "checklist-template",
			icon: <RiChatCheckLine />,
		},
		{
			label: "menu.step-template",
			path: "step-template",
			icon: <RiCheckboxMultipleLine />,
		},
		{
			label: "menu.survey-template",
			path: "survey-template",
			icon: <RiSurveyLine />,
		},
		{
			label: "menu.permission-template",
			path: "permission-template",
			icon: <RiEditBoxLine />,
		},
	],
	profile: [
		{
			label: "menu.profile",
			path: "profile",
			icon: <RiUserSettingsLine />,
		},
		{
			label: "menu.logout",
			path: "/auth/logout",
			icon: <RiLogoutBoxRLine />,
		},
	],
};

const ManagerMenu = {
	access: [
		{ label: "menu.activity" },
		{ label: "menu.dashboard", path: "", icon: <RiDashboardLine /> },
		{ label: "menu.org", path: "org", icon: <RiOrganizationChart /> },
		{ label: "menu.contract", path: "contract", icon: <RiBallPenLine /> },
	],
	profile: [
		{
			label: "menu.profile",
			path: "profile",
			icon: <RiUserSettingsLine />,
		},
		{
			label: "menu.logout",
			path: "/auth/logout",
			icon: <RiLogoutBoxRLine />,
		},
	],
};

const menu = {
	admin: AdminMenu,
	moderator: AdminMenu,
	superior: SuperiorMenu,
	manager: ManagerMenu,
};

export default menu;
