import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatLink,
	createdByCol,
	translateCell,
} from "../utils/format";

const InductionTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/induction-plan/table"
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button primary">
								{t("button.create")}
								<HiArrowNarrowRight className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "type",
					label: "induction-template.type",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/induction_type",
					},
					render: (row) => translateCell(row, t, "induction_type", "type"),
				},
				{
					key: "template",
					label: "induction-plan.template",
					sortable: true,
					filter: {
						type: "select",
						axio: "const/select/induction-template",
					},
					render: (row) => formatLink(row, "template.name", `${row._id}`),
				},
				{
					key: "text",
					label: "induction-plan.text",
					sortable: true,
					filter: true,
				},
				{
					key: "status",
					label: "induction-plan.status",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/plan_status",
					},
					render: (row) => translateCell(row, t, "plan_status", "status"),
				},
				{
					key: "user_count",
					label: "induction-plan.user_count",
					sortable: true,
					filter: {
						type: "number",
					},
				},
				{
					key: "signature_count",
					label: "induction-plan.signature_count",
					sortable: true,
					filter: {
						type: "number",
					},
				},
				{
					key: "schedule",
					label: "induction-plan.schedule",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "schedule"),
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default InductionTable;
