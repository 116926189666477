import { RiAddCircleFill, RiHome2Fill } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";

const Breadcrumb = ({ ancestors = [], id, name }) => {
	const { parent } = useParams();
	const list = [
		{
			path: "/admin/pnl-document/document/undefined",
			icon: <RiHome2Fill className="text-2xl" />,
			id: "undefined",
		},
		...ancestors.map(({ _id, name }) => {
			return {
				path: `/admin/pnl-document/document/${_id}`,
				name,
				id: _id,
			};
		}),
		id === undefined
			? null
			: { path: `/admin/pnl-document/document/${id}`, name, id },
		{
			path: `/admin/pnl-document/document/${id}/new`,
			icon: <RiAddCircleFill className="text-2xl" />,
		},
	].filter((value) => value !== null);

	return (
		<div className="flex gap-2 border-b mb-4 pb-4">
			{list.map(({ path, icon, name, id }) => (
				<Link
					to={path}
					key={path}
					className={`px-2 py-1 cursor rounded border ${
						id === parent ? "border-def-secondary" : "border-def-gray"
					}`}
				>
					{icon ? icon : name}
				</Link>
			))}
		</div>
	);
};

export default Breadcrumb;
