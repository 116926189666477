import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { RiCheckLine } from "react-icons/ri";
import sha256 from "crypto-js/sha256";
import { Loader, Field } from "../template";
import {
    phoneValidation,
    passwordValidation,
    stringValidation,
    emailValidation,
} from "../utils/validations";
import { mainApi } from "../utils/api";
import { successAlert, translateError } from "../utils/alert";
import { DEBUG } from "../defines";
import { useState } from "react";
import { Select, Text } from "../inputs";
import { randomData, randomNumber } from "../utils/demo";

const Register = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [org, setOrg] = useState();

    const registerProps = {
        enableReinitialize: true,
        initialValues: {
            unit: "",
            position: DEBUG ? randomData("position") : "",
            lastname: DEBUG ? randomData("name") : "",
            firstname: DEBUG ? randomData("name") : "",
            phone: DEBUG ? randomNumber(8) : "",
            email: DEBUG ? randomData("email") : "",
            password: DEBUG ? "asdasd" : "",
        },
        validationSchema: Yup.object().shape({
            unit: stringValidation(true).nullable(),
            lastname: stringValidation(true),
            firstname: stringValidation(true),
            phone: phoneValidation(true),
            email: emailValidation(true),
            password: passwordValidation(true),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const data = {
                    ...values,
                    org: org._id,
                    password: sha256(values.password).toString(),
                };
                const response = await mainApi({
                    url: "/user/register",
                    method: "POST",
                    data,
                });

                setSubmitting(false);

                if (response) {
                    successAlert("user.registered", `<div></div>`);
                    navigate("/auth/login");
                }
            } catch (error) {
                translateError(error);
            }
        },
    };

    const checkProps = {
        enableReinitialize: true,
        initialValues: {
            register: DEBUG ? "9914769" : "",
            pin: "",
        },
        validationSchema: Yup.object().shape({
            register: stringValidation(true),
            pin: stringValidation(true),
        }),
        onSubmit: async ({ register, pin }, { setSubmitting }) => {
            try {
                const response = await mainApi({
                    url: "/public/org/check",
                    method: "POST",
                    data: { register, pin },
                });

                setSubmitting(false);
                response && setOrg(response.data);
            } catch (error) {
                translateError(error);
            }
        },
    };

    return (
        <div className="flex flex-col justify-center">
            {org ? (
                <Formik {...registerProps}>
                    {({ isSubmitting }) => {
                        return (
                            <Form autoComplete="off">
                                {isSubmitting && <Loader />}
                                <div className="bg-white rounded p-4">
                                    <div className="uppercase font-bold my-4 pb-4 border-b text-center">
                                        {org.name}
                                    </div>
                                    <Field
                                        label="user.unit"
                                        name="unit"
                                        component={Select}
                                        axio={`/public/org/units/${org._id}`}
                                    />
                                    <Field
                                        label="user.position"
                                        name="position"
                                        component={Text}
                                    />
                                    <Field
                                        label="user.lastname"
                                        name="lastname"
                                        component={Text}
                                    />
                                    <Field
                                        label="user.firstname"
                                        name="firstname"
                                        component={Text}
                                    />
                                    <Field
                                        label="user.phone"
                                        name="phone"
                                        type="number"
                                        component={Text}
                                    />
                                    <Field
                                        label="user.email"
                                        name="email"
                                        component={Text}
                                    />
                                    <Field
                                        label="user.password"
                                        name="password"
                                        type="password"
                                        component={Text}
                                    />
                                    <button type="submit" className="secondary">
                                        {t("button.register")}
                                        <RiCheckLine className="text-lg ml-1" />
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                <Formik {...checkProps}>
                    {({ isSubmitting }) => {
                        return (
                            <Form className="bg-white rounded p-4 mb-8 relative">
                                {isSubmitting && <Loader />}

                                <Field name="register" label="org.register" />
                                <Field
                                    name="pin"
                                    label="org.pin"
                                    button={() => (
                                        <button
                                            className="primary w-12 ml-2 text-lg"
                                            type="submit"
                                        >
                                            <HiArrowNarrowRight />
                                        </button>
                                    )}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </div>
    );
};

export default Register;
