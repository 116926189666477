import { Link, Outlet } from "react-router-dom";
import { VERSION } from "../defines";
import LanguageSwitcher from "../i18n/Switch";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useTranslation } from "react-i18next";

const AuthLayout = () => {
	const { t } = useTranslation();

	return (
		<div className="h-full grid grid-cols-1 md:grid-cols-2">
			<div className="h-full bg-def-secondary hidden md:flex items-center justify-center relative">
				<div className="text-def-primary text-sm text-center">
					<img
						src="/images/logo/hsct.svg"
						alt="HSCT LLC"
						className="h-32 mx-auto"
					/>
					<div className="text-4xl font-bold mt-2">HSCT</div>
					<div>v{VERSION}</div>
				</div>
				<img
					src="/images/bg-pattern.png"
					alt="background"
					className="h-full object-cover opacity-30 absolute"
				/>
			</div>
			<div className="h-full flex flex-col">
				<div className="p-4 bg-white mb-2 flex justify-between">
					<Link to="/" className="flex items-center cursor">
						<HiArrowNarrowLeft className="text-lg mr-2" />
						<span className="text-sm">{t(`title.home`)}</span>
					</Link>
					<LanguageSwitcher />
				</div>
				<div className="self-center justify-self-center w-full">
					<div className="w-full lg:w-2/3 mx-auto px-4 py-8 relative">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuthLayout;
