import { t } from "i18next";
import { Radar } from "../charts";
import Brief from "./Breif";
import EarnedPoints from "./EarnedPoints";
import { useTranslation } from "react-i18next";

const UserView = ({ id, ...rest }) => {
	const { i18n } = useTranslation();
	return (
		<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2">
			<div className="md:border-r md:pr-2 border-r-0 pr-0">
				<Brief {...rest} />
			</div>
			<Radar
				key={i18n.language}
				axio={`/user/stats/${id}`}
				name={t("user.stats_total")}
				labelKey="translated"
				dataKey="total"
				height={300}
			/>
			<div className="col-span-2 mt-4">
				<EarnedPoints id={id} />
			</div>
		</div>
	);
};

export default UserView;
