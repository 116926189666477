import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetFromStorage, RemoveFromStorage, SaveToStorage } from "./storage";
import { mainApi } from "./api";
import { translateError } from "./alert";
import { ENV, i18NKEY } from "../defines";
import { useTranslation } from "react-i18next";

window.addEventListener("storage", function (event) {
	if (event.key === "logout-event") {
		window.location = "/auth/login";
		window.close();
	}
});

const PUBLIC_PAGES = [
	"/auth/login",
	"/auth/register",
	"/terms",
	"/privacy",
	"/app-privacy",
	"/",
	"",
];

const AuthContext = React.createContext({
	user: null,
	login: () => {},
	logout: () => {},
});

const AuthProvider = ({ children }) => {
	const navigate = useNavigate();
	const [user, setUser] = useState();
	const { i18n } = useTranslation();

	const login = async (instance) => {
		setInstance(instance);
		navigate("/admin");
	};

	const logout = () => {
		setInstance();
	};

	const setInstance = (instance) => {
		if (instance) {
			SaveToStorage("refreshToken", instance.refreshToken);
			SaveToStorage("accessToken", instance.accessToken);
			setUser(instance.user);
		} else {
			RemoveFromStorage("refreshToken");
			RemoveFromStorage("accessToken");
			setUser(null);
		}
	};

	useEffect(() => {
		const refreshUserToken = async (token) => {
			if (token) {
				try {
					const response = await mainApi({
						method: "POST",
						url: "/user/refresh",
						data: {
							token,
						},
					});
					response && setInstance(response.data);
				} catch (error) {
					translateError("error.network");
				}
			} else {
				if (!PUBLIC_PAGES.includes(window.location.pathname))
					window.location = "/auth/login";
			}
		};

		if (!GetFromStorage(i18NKEY)) {
			i18n.changeLanguage(ENV === "bukhbat" ? "MN" : "US");
		}
		refreshUserToken(GetFromStorage("refreshToken"));
	}, [i18n]);

	return (
		<AuthContext.Provider value={{ user, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => React.useContext(AuthContext);
export default AuthProvider;
