import { Link } from "react-router-dom";
import { formatDate } from "../utils/format";

const ContractItem = ({
    t,
    disabled,
    number,
    start,
    end,
    price,
    limit,
    id,
}) => {
    return (
        <div className="mb-2 pb-4 border-b">
            <div className="flex justify-between items-center">
                <div>
                    <label>{t("contract.number")}:</label> {number}
                </div>
                {!disabled && (
                    <Link
                        to={`/admin/contract/${id}`}
                        className="text-sm link"
                    >
                        {t("button.edit")}
                    </Link>
                )}
            </div>
            <div className="text-sm font-normal grid grid-cols-2 gap-8 mt-2">
                <div className="flex">
                    <div className="mr-1">{t("contract.start")}:</div>
                    <div>{formatDate(start)}</div>
                </div>
                <div className="flex">
                    <div className="mr-1">{t("contract.end")}:</div>
                    <div>{formatDate(end)}</div>
                </div>
                <div className="flex">
                    <div className="mr-1">{t("contract.price")}:</div>
                    <div>{price}</div>
                </div>
                <div className="flex">
                    <div className="mr-1">{t("contract.limit")}:</div>
                    <div>{limit}</div>
                </div>
            </div>
        </div>
    );
};

export default ContractItem;
