import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { FileUpload, Text } from "../inputs";
import View from "./View";
import { stringValidation } from "../utils/validations";

const AlertForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "alert",
		id,
		init: {
			info: "",
			attachments: [],
		},
		validationSchema: Yup.object().shape({
			info: stringValidation(false, 5000),
		}),
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props}>
			{({ disabled, submited }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="info"
								label="alert.info"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows={10}
							/>
						</div>
						<div>
							<Field
								name="attachments"
								label="alert.attachments"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								root="alert"
								accept="image/*,video/*"
								limit={500}
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default AlertForm;
