import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import Brief from "./Brief";
import {
	numberValidation,
	selectValidation,
	stringValidation,
} from "../utils/validations";
import { Text, Select } from "../inputs";
import View from "./View";

const PpeForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable: ({ status }) => editable && status === "notreceived",
		model: "ppe",
		id,
		init: {
			item: "",
			user: "",
			info: "",
			quantity: 0,
		},
		validationSchema: Yup.object().shape({
			item: selectValidation(true),
			user: selectValidation(true),
			quantity: numberValidation(true, { min: 1 }),
			info: stringValidation(false, 5000),
		}),
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props}>
			{({ disabled, values }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="item"
								label="ppe.item"
								disabled={disabled}
								component={Select}
								axio="/const/select/ppe-item"
							/>
							{typeof values.item === "string" && <Brief item={values.item} />}
						</div>
						<div>
							<Field
								name="info"
								label="ppe.info"
								disabled={disabled}
								component={Text}
								type="textarea"
							/>
							<Field
								name="quantity"
								label="ppe.quantity"
								disabled={disabled}
								component={Text}
								type="number"
							/>
							<Field
								name="user"
								label="ppe.user"
								disabled={disabled}
								component={Select}
								axio="/const/select/user"
								// eslint-disable-next-line no-template-curly-in-string
								labelTemplate="${firstname} ${lastname} (${position})"
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default PpeForm;
