import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import sha256 from "crypto-js/sha256";
import { Link } from "react-router-dom";
import { Loader, Field } from "../template";
import { phoneValidation, passwordValidation } from "../utils/validations";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useAuth } from "../utils/auth";
import { useEffect } from "react";
import { mainApi } from "../utils/api";
import { translateError } from "../utils/alert";
import { DEBUG, LOGO, VERSION } from "../defines";
import { Text } from "../inputs";

const Login = ({ from }) => {
	const { t } = useTranslation();
	const { login, logout } = useAuth();

	useEffect(() => {
		logout();
		if (from === "inside") {
			localStorage.setItem("logout-event", "logout" + Math.random());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const formik = {
		enableReinitialize: true,
		initialValues: {
			register: DEBUG ? "9914769" : "",
			phone: DEBUG ? "99124773" : "",
			password: DEBUG ? "Asdqwe123!" : "",
		},
		validationSchema: Yup.object().shape({
			phone: phoneValidation(true),
			password: passwordValidation(true),
		}),
		onSubmit: async ({ register, phone, password }, { setSubmitting }) => {
			try {
				const data = {
					register,
					phone,
					password: sha256(password).toString(),
				};
				const response = await mainApi({
					url: "/user/login",
					method: "POST",
					data,
				});

				setSubmitting(false);
				response && login(response.data);
			} catch (error) {
				translateError(error);
			}
		},
	};

	return (
		<div className="flex flex-col justify-center">
			<div className="my-10 self-center flex items-end">
				<img src={LOGO} className="h-10" alt="logo" />
				<div className="text-center text-xs">v{VERSION}</div>
			</div>
			<Formik {...formik}>
				{({ isSubmitting }) => {
					const isLoading = isSubmitting;

					return (
						<Form>
							{isLoading && <Loader />}
							<Field
								name="register"
								label="org.register"
								type="number"
								component={Text}
							/>
							<Field
								name="phone"
								label="user.phone"
								type="number"
								component={Text}
							/>
							<Field
								name="password"
								label="user.password"
								type="password"
								component={Text}
							/>
							<Link to="/terms" className="link text-right my-8 text-sm block">
								{t("button.terms")}
							</Link>
							<button type="submit" className="secondary">
								{t("button.login")}
							</button>
						</Form>
					);
				}}
			</Formik>
			<div className="my-8 text-center text-sm">{t("user.use_code")}</div>
			<Link to="/auth/register" className="button">
				{t("button.register")}
				<HiArrowNarrowRight className="text-lg ml-1" />
			</Link>
		</div>
	);
};

export default Login;
