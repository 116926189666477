import { Progress } from "../template";
import { useTranslation } from "react-i18next";

const PlanProgress = ({
	data,
	tPath,
	notcompletedTKey = "notcompleted",
	completedTKey = "completed",
}) => {
	const { t } = useTranslation();

	return data.map(({ name, percent, date, total, ...props }, index) => (
		<div
			key={index}
			className={`flex items-center p-4 ${
				index % 2 === 0 ? "bg-def-gray" : ""
			}`}
		>
			<div className="flex-1 flex items-center justify-between">
				<div className="">
					{index + 1}. {name}
					<div className="text-xs">{date}</div>
				</div>
				<div className="text-sm flex">
					<div className="text-right">
						<div>
							{t(`${tPath}.${notcompletedTKey}`)}: {props[notcompletedTKey]}
						</div>
						<div>
							{t(`${tPath}.${completedTKey}`)}: {props[completedTKey]}
						</div>
					</div>
					<div className="text-4xl font-bold w-32 text-center">{total}</div>
				</div>
			</div>
			<div className="flex-1">
				<Progress progress={percent * 100} hideLabel />
			</div>
		</div>
	));
};

export default PlanProgress;
