import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form } from "../template";
import { selectValidation, stringValidation } from "../utils/validations";
import { Select, Text, FileUpload, Toggle } from "../inputs";
import View from "./View";
import { useTranslation } from "react-i18next";
import QuestionPreview from "./QuestionPreview";

const SurveyTemplateForm = ({ editable, role }) => {
	const isPublic = role === "superior";
	const { id } = useParams();

	const { t } = useTranslation();

	const props = {
		editable,
		model: "survey-template",
		id,
		init: {
			name: "",
			description: "",
			questions: [],
			instruction: [],
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			questions: Yup.array().of(
				Yup.object().shape({
					question: stringValidation(true, 5000),
					type: selectValidation(true),
				})
			),
		}),
		beforeSubmit: (values) => {
			const { questions } = values;

			if (questions.length < 1)
				throw new Error("survey-template.undefined_questions");
			return values;
		},
		view: (values) => <View {...values} key={id} id={id} isPublic={isPublic} />,
	};

	return (
		<Form {...props}>
			{({ disabled, submited }) => {
				return (
					<div>
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div>
								<Field
									name="name"
									label="survey-template.name"
									disabled={disabled}
									component={Text}
								/>
								<Field
									name="description"
									label="survey-template.description"
									disabled={disabled}
									component={Text}
									type="textarea"
								/>
							</div>
							<div>
								<Field
									name="instruction"
									label="survey-template.instruction"
									disabled={disabled}
									submited={submited}
									component={FileUpload}
									accept="video/mp4"
									root="survey"
									limit={500}
								/>
							</div>
						</div>
						<FieldArray
							name="questions"
							label="survey-template.questions"
							disabled={disabled}
							initial={{
								question: "",
								media: [],
								type: "short_text",
								required: true,
								options: [],
							}}
							duplicatable={true}
							renderItem={(question, qIndex) => (
								<div>
									<div className="grid grid-cols-1 md:grid-cols-3 gap-2">
										<div className="col-span-2">
											<Field
												name={`questions.${qIndex}.question`}
												label="survey-template.question"
												disabled={disabled}
												component={Text}
												type="textarea"
												rows={7}
											/>
										</div>
										<Field
											name={`questions.${qIndex}.media`}
											label="survey-template.question_media"
											disabled={disabled}
											submited={submited}
											component={FileUpload}
											accept="image/*,video/mp4,application/pdf"
											root="survey"
										/>
										<div className="col-span-2">
											<Field
												name={`questions.${qIndex}.required`}
												label="survey-template.question_required"
												disabled={disabled}
												component={Toggle}
											/>
											<Field
												name={`questions.${qIndex}.type`}
												label="survey-template.question_type"
												disabled={disabled}
												component={Select}
												axio="/const/static/survey_question_type"
											/>
											{["multiple_choices", "single_choice"].includes(
												question.type
											) && (
												<FieldArray
													name={`questions.${qIndex}.options`}
													label="survey-template.options"
													disabled={disabled}
													initial={""}
													minimal={true}
													renderItem={(option, oIndex) => (
														<div className="w-full">
															<Field
																name={`questions.${qIndex}.options.${oIndex}`}
																placeholder={t("survey-template.option")}
																disabled={disabled}
																component={Text}
															/>
														</div>
													)}
												/>
											)}
										</div>
										<div className="bg-def-gray p-5">
											<QuestionPreview {...question} number={qIndex + 1} />
										</div>
									</div>
								</div>
							)}
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default SurveyTemplateForm;
