import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form } from "../template";
import { selectValidation, stringValidation } from "../utils/validations";
import { Select, Text, FileUpload, Toggle } from "../inputs";
import View from "./View";

const ChecklistTemplateForm = ({ editable, role }) => {
	const isPublic = role === "superior";
	const { id } = useParams();
	const props = {
		editable,
		model: "checklist-template",
		id,
		init: {
			name: "",
			questions: [],
			workers: [],
			instruction: [],
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			questions: Yup.array().of(
				Yup.object().shape({
					question: stringValidation(true, 5000),
					type: selectValidation(true),
				})
			),
		}),
		beforeSubmit: (values) => {
			const { questions } = values;

			if (questions.length < 1)
				throw new Error("checklist-template.undefined_questions");
			return values;
		},
		view: (values) => <View {...values} key={id} id={id} isPublic={isPublic} />,
	};

	return (
		<Form {...props}>
			{({ disabled, submited }) => {
				return (
					<div>
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div>
								<Field
									name="category"
									label="checklist.category"
									disabled={disabled}
									component={Select}
									axio="/const/category/checklist"
								/>
								<Field
									name="name"
									label="checklist-template.name"
									disabled={disabled}
									component={Text}
								/>
								{!isPublic && (
									<Field
										name="point"
										label="checklist-template.point"
										disabled={disabled}
										component={Select}
										isSearchable={false}
										axio="/const/static/point"
									/>
								)}
								{!isPublic && (
									<Field
										name="required"
										label="checklist-template.required"
										disabled={disabled}
										component={Toggle}
									/>
								)}
							</div>
							<div>
								<Field
									name="instruction"
									label="checklist-template.instruction"
									disabled={disabled}
									submited={submited}
									component={FileUpload}
									root="instruction"
									limit={500}
									accept="video/mp4"
								/>
							</div>
						</div>
						<FieldArray
							name="questions"
							label="checklist-template.questions"
							disabled={disabled}
							initial={{ question: "", type: "check" }}
							renderItem={(question, qIndex) => (
								<div>
									<div className="grid grid-cols-1 md:grid-cols-3 gap-2">
										<div className="col-span-2">
											<Field
												name={`questions.${qIndex}.question`}
												label="checklist-template.question"
												disabled={disabled}
												component={Text}
												type="textarea"
											/>
										</div>
										<Field
											name={`questions.${qIndex}.type`}
											label="checklist-template.question_type"
											disabled={disabled}
											component={Select}
											axio="/const/static/field_type"
										/>
									</div>
								</div>
							)}
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default ChecklistTemplateForm;
