import Comments from "../comment/View";
import { useTranslation } from "react-i18next";
import {
	CreatedBy,
	Gallery,
	History,
	QuestionType,
	Status,
	SubStatus,
} from "../template";
import { RiMapPin2Line } from "react-icons/ri";
import objectPath from "object-path";

const renderExtra = (extra) => {
	if (Array.isArray(extra)) return <Gallery files={extra} />;

	return extra;
};

const PermissionView = ({
	id,
	createdby,
	created,
	history,
	status,
	answers,
	template,
	location,
}) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
				<Status
					color={status === "approved" ? "green" : "red"}
					left={<div className="flex items-center"></div>}
					right={t(`const.permission_status.${status}`)}
				/>
				<SubStatus
					left={objectPath.get(template, "name")}
					right={
						<div className="flex">
							<RiMapPin2Line className="mr-1" />
							{objectPath.get(location, "name")}
						</div>
					}
				></SubStatus>
				{Array.isArray(answers) &&
					answers.map(({ question, type, value, extra, extraInfo }, index) => {
						return (
							<div key={index} className="mb-2 pb-2 border-b border-dashed">
								<div className="flex justify-between">
									<div className="font-bold">
										{index + 1}. {question}
									</div>
									<QuestionType type={type} value={value} />
								</div>
								{typeof value !== "boolean" && <div>{value}</div>}
								{extraInfo && <div>{extraInfo}</div>}
								{extra && (
									<div className="flex justify-between my-2">
										{renderExtra(extra)}
									</div>
								)}
							</div>
						);
					})}
			</div>
			<div>
				<History items={history} status_root="permission_status" />
			</div>
			<div>
				<Comments model="permission" id={id} />
			</div>
		</div>
	);
};

export default PermissionView;
