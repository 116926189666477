import { useEffect, useRef, useState } from "react";
import { Back, Loader, Watermark } from "../template";
import { mainApi } from "../utils/api";
import { Tree, TreeNode } from "react-organizational-chart";
import { useAuth } from "../utils/auth";
import { RiInstallLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";

const Leaf = ({ name, total }) => {
	return (
		<div className="p-4 pt-2 border border-def-primary rounded-md uppercase text-xs bg-def-gray bg-opacity-20">
			{name}
			<br />
			{total ? `(${total})` : ""}
		</div>
	);
};

const TreeBranch = (item) => {
	const { name, children } = item;

	if (children.length > 0)
		return (
			<TreeNode label={<Leaf name={name} total={children.length} />}>
				{children.length > 0 &&
					children.map((child) => <TreeBranch key={child.id} {...child} />)}
			</TreeNode>
		);

	return <TreeNode label={<Leaf name={name} />} />;
};

const createNode = (elm) => {
	const node = {
		id: elm.id,
		name: elm.name,
		children: [],
		parent: elm.parent,
	};
	return node;
};

const fillElements = (elements, node) => {
	elements.forEach((elm) => {
		if (elm.parent === node.id) {
			const child = node.children.find((child) => child.id === elm.id);

			if (!child) {
				node.children.push(createNode(elm));
			}
		}
	});
};

const fillTree = (elements, node) => {
	fillElements(elements, node);

	if (node.children.length > 0) {
		node.children.forEach((n) => {
			fillElements(elements, n);

			fillTree(elements, n);
		});
	}
};

const UnitDiagram = () => {
	const { user } = useAuth();
	const { t } = useTranslation();

	const [isLoading, setLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [total, setTotal] = useState(0);
	const exportRef = useRef();

	const { org_name } = user;

	const fetchUnits = async () => {
		setLoading(true);
		const units = await mainApi("/const/select/unit");

		const nodes = [];
		units.data.forEach((elm) => {
			if (!elm.parent) {
				const root = createNode(elm);
				fillTree(units.data, root);
				nodes.push(root);
			}
		});

		units && setItems(nodes);
		setTotal(units.data.length);
		setLoading(false);
	};

	useEffect(() => {
		fetchUnits();
	}, []);

	const exportToPng = async () => {
		const element = exportRef.current;
		const canvas = await html2canvas(element);
		const data = canvas.toDataURL("image/png");

		var a = document.createElement("a");
		a.href = data;
		a.download = `${org_name}.png`;
		a.click();
	};

	return (
		<div className="relative">
			<div className="flex justify-between items-center mb-4">
				<div>
					<Back />
				</div>

				<div>
					<button
						className="secondary"
						onClick={() => {
							exportToPng();
						}}
					>
						<RiInstallLine className="text-2xl mr-2" />
						{t("button.download")}
					</button>
				</div>
			</div>
			{isLoading && <Loader />}
			<div className="w-full overflow-auto relative p-5" ref={exportRef}>
				<Watermark />
				{items && (
					<Tree
						lineColor="#FCA311"
						lineWidth="3px"
						label={
							<div className="bg-def-secondary inline-block p-4 pt-2 text-white uppercase">
								{org_name}
								<br />({total})
							</div>
						}
					>
						{items.map((item) => (
							<TreeBranch key={item.id} {...item} />
						))}
					</Tree>
				)}
			</div>
		</div>
	);
};

export default UnitDiagram;
