import Comments from "../comment/View";
import { useTranslation } from "react-i18next";
import {
	CreatedBy,
	Gallery,
	Status,
	StatusScore,
	SubStatus,
	Workers,
	History,
} from "../template";
import objectPath from "object-path";
import { RiMapPin2Line } from "react-icons/ri";

const InspectionView = ({
	id,
	createdby,
	created,
	status,
	assessment_name,
	assessment,
	info,
	category,
	location,
	attachments,
	workers,
	worker_count,
	history,
}) => {
	const { t } = useTranslation();
	const color = status === "fixed" ? "green" : "red";

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
				<Status
					color={color}
					left={
						<div className="flex items-center">
							<StatusScore color={color} value={assessment} />
							{t(`const.assessment.${assessment_name}`)}
						</div>
					}
					right={t(`const.inspection_status.${status}`)}
				></Status>
				<SubStatus
					left={objectPath.get(category, "name")}
					right={
						<div className="flex">
							<RiMapPin2Line className="mr-1" />
							{objectPath.get(location, "name")}
						</div>
					}
				></SubStatus>
				<div className="text-sm my-4">{info}</div>
				<Gallery files={attachments} />
				<Workers
					workers={workers}
					label={`${t("inspection.workers")} (${worker_count}):`}
				/>
			</div>
			<div>
				<History items={history} status_root="inspection_status" />
			</div>
			<div>
				<Comments model="inspection" id={id} />
			</div>
		</div>
	);
};

export default InspectionView;
