import { useTranslation } from "react-i18next";

const DAYS = [2, 3, 4, 5, 6, 7, 1];

const Dow = ({ data, renderCell }) => {
	const { t } = useTranslation();

	return (
		<div className="w-full overflow-y-auto">
			<div className="grid grid-cols-7 gap-2">
				{DAYS.map((day) => (
					<div key={day} className="text-center">
						<div className="border bg-def-gray">{t(`days.${day}`)}</div>
						<div>{renderCell && data[day] && renderCell(data[day])}</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Dow;
