import Comments from "../comment/View";
import UserSignatures from "../user/Signatures";
import { useTranslation } from "react-i18next";
import { formatDate, translateCell } from "../utils/format";
import { Pie } from "../charts";
import { CreatedBy, Pdf } from "../template";

const ProcedureView = ({
	id,
	_id,
	createdby,
	created,
	file,
	number,
	name,
	start,
	point,
	end,
	user_count,
	signature_count,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
				<div>
					<CreatedBy user={createdby} date={created}></CreatedBy>
					<div className="text-sm my-4">
						{number && (
							<div className="flex justify-between">
								<div>{t("law.number")}:</div>
								<div>{number}</div>
							</div>
						)}
						{start && (
							<div className="flex justify-between">
								<div>{t("law.start")}:</div>
								<div>{formatDate(start)}</div>
							</div>
						)}
						{end && (
							<div className="flex justify-between">
								<div>{t("law.end")}:</div>
								<div>{formatDate(end)}</div>
							</div>
						)}
						{point && (
							<div className="flex justify-between">
								<div>{t("law.point")}:</div>
								<div>{point}</div>
							</div>
						)}
					</div>
					<Pdf file={file} name={name}></Pdf>
					{_id && (
						<Pie
							data={[
								{
									value: signature_count,
									name: t("const.sign_status.signed"),
								},
								{
									value: user_count - signature_count,
									name: t("const.sign_status.notsigned"),
								},
							]}
							height={300}
							renderLabel={({ name, value }) => `${name}: ${value}`}
						/>
					)}
				</div>
				<div>
					<Comments model="law" id={id} />
				</div>
			</div>
			<div className="mt-4">
				<UserSignatures
					model="law"
					id={id}
					title={t("title.signature-common")}
					statusColumn={[
						{
							key: "status",
							label: "fields.status",
							sortable: true,
							filter: {
								type: "select",
								axio: "/const/static/sign_status",
							},
							render: (row) => translateCell(row, t, "sign_status", "status"),
						},
					]}
				></UserSignatures>
			</div>
		</div>
	);
};

export default ProcedureView;
