import { useEffect, useState } from "react";
import { Loader } from "../template";
import { mainApi } from "../utils/api";

const GroupMembers = ({ groups }) => {
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (Array.isArray(groups) && groups.length > 0) fetch(groups);
		else {
			setItems([]);
		}
	}, [groups]);

	const fetch = async (groups) => {
		setLoading(true);

		const response = await mainApi({
			url: "/group/members",
			method: "POST",
			data: { groups },
		});

		response && setItems(response.data);

		setLoading(false);
	};

	return (
		<div className="relative leading-tight text-gray-500">
			{loading && <Loader />}
			{items.map((group, index) => (
				<div key={index} className="text-sm mb-2">
					<div className="text-def-primary">
						{group.name} ({group.user_count}):
					</div>
					<div className="flex flex-wrap">
						{Array.isArray(group.members) &&
							group.members.map((member, index) => {
								return (
									<div
										key={index}
										className={`${
											member.active === true ? "" : "line-through"
										} mr-1`}
									>
										{member.firstname} {member.lastname}
										{index !== group.members.length - 1 ? "," : ""}
									</div>
								);
							})}
					</div>
				</div>
			))}
		</div>
	);
};

export default GroupMembers;
