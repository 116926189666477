import { useParams } from "react-router-dom";
import Stats from "./Stats";

const SuperiorDashboard = () => {
    const { id } = useParams();

    return (
        <div>
            <Stats org_id={id} />
        </div>
    );
};
export default SuperiorDashboard;
