import { useTranslation } from "react-i18next";
import { Watermark } from "../../template";
import PngExportable from "../PngExportable";
import TableExportable from "../TableExportable";
import Table from "../Table";
import Unit from "../Unit";
import Pie from "../../charts/Pie";
import PlanProgress from "../PlanProgress";
import Dow from "../Dow";
import CreatedBy from "../CreatedBy";
import Chain from "../Chain";

const STAT_TRANS = {
	plans: "report_label.training_plan",
	total: "report_label.training_workers",
	completed: "const.training_status.completed",
	notcompleted: "const.training_status.notcompleted",
	percent: "report_label.training_stat",
};

const TrainingReport = ({
	start,
	end,
	stats,
	plans,
	type,
	unit,
	dow,
	delay,
	top,
	worst,
	training_table,
	plan_table,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<PngExportable title={t(`report_title.training_stat`, { start, end })}>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-5 relative">
					<div className="grid gap-5">
						{stats.map(({ name, value }) => (
							<div
								key={name}
								className="p-5 bg-white shadow-md flex justify-between"
							>
								<div>{t(STAT_TRANS[name])}</div>
								<div className="text-2xl font-bold">{value}</div>
							</div>
						))}
					</div>
					<Pie
						height={450}
						data={type.map(({ name, total }) => ({
							value: total,
							name: t(`const.training_type.${name}`),
						}))}
						renderLabel={({ name, value }) => {
							return `${name} (${value})`;
						}}
					/>
					<Watermark />
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.training_progress`, { start, end })}
			>
				<div className="relative">
					<PlanProgress data={plans} tPath="const.training_status" />
					<Watermark />
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.training_day`, { start, end })}>
				<div className="relative">
					<Dow
						data={dow}
						tPath="const.training_status"
						renderCell={({ plans }) =>
							plans.map(({ date, name, type, total }, index) => (
								<div
									key={index}
									className={`text-xs mb-2 border shadow-md ${
										type === "exam" ? "bg-def-secondary" : ""
									}`}
								>
									<div>{date}</div>
									<div>{name}</div>
									<div>
										{t(`const.training_type.${type}`)} ({total}{" "}
										{t("report_label.person")})
									</div>
								</div>
							))
						}
					/>
					<Watermark />
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.training_unit`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table
						data={unit}
						tPath="const.training_status"
						columns={["notcompleted", "completed", "total"]}
					/>
				</div>
				<div className="mt-4" style={{ height: 600 }}>
					<Unit
						data={unit.map(({ notcompleted, completed, name }) => {
							return {
								name,
								children: [
									{
										name: t(`const.training_status.completed`),
										total: completed,
										color: "#008000",
									},
									{
										name: t(`const.training_status.notcompleted`),
										total: notcompleted,
										color: "#D90429",
									},
								],
							};
						})}
					></Unit>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.training_delay`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<Chain data={delay} />
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.training_top`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<CreatedBy
						data={top}
						renderRight={({ completed, total }) => `${completed}/${total}`}
					></CreatedBy>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.training_worst`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<CreatedBy
						data={worst}
						renderRight={({ completed, total }) => `${completed}/${total}`}
					></CreatedBy>
				</div>
			</PngExportable>
			<div className="relative">
				<Watermark />
				<TableExportable
					title={t(`report_title.training_plan_table`, { start, end })}
					find={{ start, end }}
					url="/report/training/export-plan"
					data={plan_table}
					columns={[
						{
							label: t("fields.created"),
							render: { type: "date", field: "created" },
						},
						{
							label: t("training-plan.schedule"),
							render: { type: "date", field: "schedule" },
						},
						{
							label: t("training-content.type"),
							render: {
								type: "trans",
								tPath: "const.training_type",
								field: "content.type",
							},
						},
						{
							label: t("training-content.name"),
							render: { type: "string", field: "content.name" },
						},
						{
							label: t("training-plan.user_count"),
							render: { type: "string", field: "user_count" },
						},
						{
							label: t("training-plan.signature_count"),
							render: { type: "string", field: "signature_count" },
						},
						{
							label: t("training-plan.progress"),
							render: ({ user_count, signature_count }) =>
								((signature_count / user_count) * 100).toFixed(2),
						},
						{
							label: t("fields.createdby"),
							render: ({ createdby }) =>
								`${createdby.firstname} ${createdby.lastname} (${createdby.position})`,
						},
					]}
				/>
			</div>
			<div className="relative">
				<Watermark />
				<TableExportable
					title={t(`report_title.training_table`, { start, end })}
					find={{ start, end }}
					url="/report/training/export"
					data={training_table}
					columns={[
						{
							label: t("fields.date"),
							render: { type: "date", field: "created" },
						},
						{
							label: t("training-content.type"),
							render: {
								type: "trans",
								tPath: "const.training_type",
								field: "training.type",
							},
						},
						{
							label: t("training-content.name"),
							render: { type: "string", field: "training.name" },
						},
						{
							label: t("fields.employee"),
							render: ({ employee }) =>
								`${employee.firstname} ${employee.lastname} (${employee.position})`,
						},
						{
							label: t("fields.status"),
							render: {
								type: "trans",
								tPath: "const.training_status",
								field: "status",
							},
						},
						{
							label: t("training-plan.signed"),
							render: { type: "date", field: "signed" },
						},
						{
							label: t("training.best"),
							render: { type: "string", field: "best" },
						},
						{
							label: t("training.attempt_count"),
							render: { type: "string", field: "attempt_count" },
						},
					]}
				/>
			</div>
		</div>
	);
};

export default TrainingReport;
