import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { DEFAULT_LANG } from "../defines";

const locales = ["MN", "US", "KR", "CN", "KZ", "RU"];

const LanguageSwitch = () => {
	const { i18n } = useTranslation();
	const LANGUAGE = locales.includes(i18n.language.toUpperCase())
		? i18n.language.toUpperCase()
		: DEFAULT_LANG;
	return (
		<div className="flex items-center uppercase select-none">
			<ReactCountryFlag svg={true} countryCode={LANGUAGE} />
			<select
				name="language-switcher"
				className="cursor ml-2"
				onChange={(e) => {
					i18n.changeLanguage(e.target.value);
				}}
				value={LANGUAGE}
			>
				{locales.map((locale, index) => (
					<option key={index} value={locale}>
						{locale}
					</option>
				))}
			</select>
		</div>
	);
};

export default LanguageSwitch;
