import CleanImage from "./clean.png";
import MuscleImage from "./muscle.png";
import SkullImage from "./skull.png";

const ELEMENTS = {
	death: { size: 5, position: { top: "2%", left: "36.5%" } },
	head: { size: 20, position: { top: "1%", left: "46.5%" } },
	neck: { size: 10, position: { top: "11%", left: "43.5%" } },
	back: { size: 7.5, position: { top: "40%", left: "41%" } },
	chest: { size: 7.5, position: { top: "20%", left: "41%" } },

	digestive: { size: 7.5, position: { top: "35%", left: "41%" } },

	left_eye: { size: 20, position: { top: "5.5%", left: "51%" } },
	right_eye: { size: 20, position: { top: "5.5%", right: "51%" } },

	nose: { size: 20, position: { top: "7%", left: "46.75%" } },
	lunge: { size: 10, position: { top: "20%", right: "43.75%" } },

	left_ear: { size: 20, position: { top: "6.5%", left: "34%" } },
	right_ear: { size: 20, position: { top: "6.5%", right: "34%" } },

	left_upper_arm: { size: 10, position: { top: "27%", left: "12%" } },
	right_upper_arm: { size: 10, position: { top: "27%", right: "12%" } },
	left_bottom_arm: { size: 20, position: { top: "48%", left: "9%" } },
	right_bottom_arm: { size: 20, position: { top: "48%", right: "9%" } },

	left_upper_leg: { size: 10, position: { top: "60%", left: "31%" } },
	right_upper_leg: { size: 10, position: { top: "60%", right: "31%" } },
	left_bottom_leg: { size: 20, position: { top: "83%", left: "35%" } },
	right_bottom_leg: { size: 20, position: { top: "83%", right: "35%" } },
};

const PARTS = {
	death: [ELEMENTS.death],
	head: [ELEMENTS.head],
	neck: [ELEMENTS.neck],
	back: [ELEMENTS.back],
	chest: [ELEMENTS.chest],
	digestive: [ELEMENTS.digestive],
	eyes: [ELEMENTS.left_eye, ELEMENTS.right_eye],
	breath: [ELEMENTS.nose, ELEMENTS.lunge],
	ears: [ELEMENTS.left_ear, ELEMENTS.right_ear],
	arms: [
		ELEMENTS.left_upper_arm,
		ELEMENTS.right_upper_arm,
		ELEMENTS.left_bottom_arm,
		ELEMENTS.right_bottom_arm,
	],
	legs: [
		ELEMENTS.left_upper_leg,
		ELEMENTS.right_upper_leg,
		ELEMENTS.left_bottom_leg,
		ELEMENTS.right_bottom_leg,
	],
};

const Pulse = ({ color = "bg-red-900", size, position }) => {
	return (
		<div className="flex absolute" style={{ ...position }}>
			<div
				style={{
					height: size,
					width: size,
					marginTop: (size / 4) * -1,
					marginLeft: (size / 4) * -1,
				}}
				className={`animate-ping absolute inline-flex rounded-full ${color} opacity-75`}
			></div>
			<div
				style={{ height: size / 2, width: size / 2 }}
				className={`rounded-full ${color}`}
			></div>
		</div>
	);
};

const IMAGES = {
	clean: CleanImage,
	muscle: MuscleImage,
	skull: SkullImage,
};

const HumanAnatomy = ({
	type = "clean",
	height = 400,
	pulseColor = "bg-red-900",
	highlights = [],
}) => {
	return (
		<div className="flex flex-shrink-0">
			<div className="relative">
				<img alt="human-body" src={IMAGES[type]} style={{ height }} />

				{highlights.map((injury) => {
					const elements = PARTS[injury];

					if (!elements) return null;

					return elements.map((element, index) => (
						<Pulse
							key={index}
							color={pulseColor}
							position={element.position}
							size={height / element.size}
						></Pulse>
					));
				})}
			</div>
		</div>
	);
};

export default HumanAnatomy;
