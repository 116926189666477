import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { stringValidation } from "../utils/validations";
import { FileUpload, Select, Text } from "../inputs";
import View from "./View";

const InductionTemplateForm = ({ editable }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "induction-template",
		id,
		init: {
			name: "",
			info: "",
			media: [],
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			info: stringValidation(true, 5000),
		}),
		view: (values) => <View {...values} key={id} id={id} />,
	};

	return (
		<Form {...props}>
			{({ disabled }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="name"
								label="induction-template.name"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="info"
								label="induction-template.info"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows={10}
							/>
						</div>
						<div>
							<Field
								name="point"
								label="induction-template.point"
								disabled={disabled}
								component={Select}
								isSearchable={false}
								axio="/const/static/point"
							/>
							<Field
								name="media"
								label="induction-template.media"
								disabled={disabled}
								component={FileUpload}
								root="induction"
								accept="image/*,video/mp4,application/pdf"
								limit={500}
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default InductionTemplateForm;
