import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import { formatString, formatDate, getAbsolutePath } from "../utils/format";
import Signature from "./Signature";

import { useState } from "react";
import { Loader } from "../template";
import { DEBUG } from "../defines";
import objectPath from "object-path";
import { format, parseISO } from "date-fns";
import { mainApi } from "../utils/api";
import * as XLSX from "xlsx";
import { RiFileExcel2Fill } from "react-icons/ri";

const MODELS = ["procedure", "training", "induction"];

const UserSignatures = ({ title, model, id, statusColumn }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const onClickDownload = async () => {
		try {
			setLoading(true);
			const response = await mainApi({
				url: `/user/signatures/${model}/${id}`,
				method: "POST",
				data: {
					limit: 1000,
				},
			});

			await exportToExcel("Signatures", columns, response.data.items);
		} catch (error) {
			DEBUG && console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const adaptRender = (row, render, key) => {
		if (typeof render === "function") {
			if (key === "signature") {
				return DEBUG ? getAbsolutePath(row.signature, { placeholder: "" }) : "";
			}

			return render(row);
		}

		if (typeof render === "object") {
			const { type, field } = render;
			const value = objectPath.get(row, field);

			if (type === "trans") {
				return t(`${render.tPath}.${value}`);
			}

			if (type === "date" && value) {
				return format(parseISO(value), "yyyy-MM-dd HH:mm:ss");
			}

			return value;
		}

		return objectPath.get(row, key);
	};

	const exportToExcel = (filename, columns, rows) => {
		const header = columns
			.filter((d) => d)
			.map((column) => {
				return t(column.label);
			});

		const data = rows.map((row) => {
			return columns
				.filter((d) => d)
				.map(({ render, key }) => adaptRender(row, render, key));
		});

		let wb = XLSX.utils.book_new();
		let ws = XLSX.utils.json_to_sheet([header, ...data], {
			skipHeader: true,
		});

		ws["!cols"] = columns.map(() => ({
			wch: 25,
		}));

		XLSX.utils.book_append_sheet(wb, ws);

		return XLSX.writeFile(wb, `${filename}.xlsx`);
	};

	const columns = [
		...statusColumn,
		MODELS.includes(model) && {
			key: "signature",
			label: "user.signature",
			render: ({ signature }) => <Signature signature={signature} />,
		},
		{
			key: "signed",
			label: "fields.date",
			sortable: true,
			filter: { type: "date" },
			width: 250,
			render: (row) => formatDate(row, "signed"),
		},
		{
			key: "unit",
			label: "user.unit",
			sortable: true,
			filter: {
				type: "select",
				axio: "/const/select/unit",
			},
			render: (row) => formatString(row, "unit.name"),
		},
		{
			key: "employee.lastname",
			label: "user.lastname",
			sortable: true,
			filter: true,
		},
		{
			key: "employee.firstname",
			label: "user.firstname",
			sortable: true,
			filter: true,
		},
		{
			key: "employee.phone",
			label: "user.phone",
			sortable: true,
			filter: true,
		},
		{
			key: "employee.position",
			label: "user.position",
			sortable: true,
			filter: true,
		},
	];

	return (
		<div className="relative">
			{loading && <Loader />}
			<h1 className="bordered">{title}</h1>
			{MODELS.includes(model) && (
				<div className="flex justify-end mb-4">
					<div>
						<button
							type="button"
							className="secondary"
							onClick={() => {
								onClickDownload();
							}}
						>
							<RiFileExcel2Fill className="text-2xl mr-2" />
							{t("button.download")}
						</button>
					</div>
				</div>
			)}
			<Table url={`/user/signatures/${model}/${id}`} columns={columns}></Table>
		</div>
	);
};

export default UserSignatures;
