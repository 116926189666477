import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import GroupMembers from "../group/GroupMembers";
import { selectValidation, stringValidation } from "../utils/validations";
import { DatePicker, Select, Toggle } from "../inputs";
import View from "./View";

const SurveyForm = () => {
	const { id } = useParams();
	const props = {
		model: "survey",
		id,
		init: {
			template: "",
			name: "",
			isPublic: true,
		},
		validationSchema: Yup.object().shape({
			template: selectValidation(true),
			name: stringValidation(true),
		}),
		beforeSubmit: (values) => {
			if (
				!values.all &&
				(!values.users || values.users.length < 1) &&
				(!values.groups || values.groups.length < 1) &&
				(!values.units || values.units.length < 1)
			)
				throw new Error("survey.select_user");

			return values;
		},

		view: (values) => values.id && <View {...values}></View>,
	};

	return (
		<Form {...props}>
			{({ disabled, values }) => {
				return (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<Field
								name="template"
								label="survey.template"
								disabled={disabled}
								component={Select}
								axio="/const/select/survey-template"
							/>
							<Field name="name" label="survey.name" disabled={disabled} />
							<Field
								name="start"
								label="survey.start"
								disabled={disabled}
								component={DatePicker}
								time={true}
							/>
							<Field
								name="end"
								label="survey.end"
								disabled={disabled}
								component={DatePicker}
								time={true}
							/>
						</div>
						<div>
							<Field
								name="isPublic"
								label="survey.isPublic"
								disabled={disabled}
								component={Toggle}
								inline
								reverse
							/>
							<Field
								name="all"
								label="survey.all"
								disabled={disabled}
								component={Toggle}
								inline
								reverse
							/>
							{!values.all && (
								<>
									<Field
										name="users"
										label="survey.users"
										disabled={disabled}
										component={Select}
										axio="/const/select/user"
										isMulti={true}
										// eslint-disable-next-line no-template-curly-in-string
										labelTemplate="${firstname} ${lastname} (${position})"
									/>
									<Field
										name="groups"
										label="survey.group"
										disabled={disabled}
										component={Select}
										axio="/const/select/group"
										isMulti={true}
										closeOnSelect={true}
									/>
									<Field
										name="units"
										label="survey.units"
										disabled={disabled}
										component={Select}
										axio="/const/select/unit"
										isMulti={true}
										closeOnSelect={true}
									/>
									<GroupMembers groups={values.groups} />
								</>
							)}
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default SurveyForm;
