import { useEffect, useState } from "react";
import { Loader, Media } from "../template";
import { mainApi } from "../utils/api";

const TrainingBrief = ({ content }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (content) fetch(content);
        else {
            setData(null);
        }
    }, [content]);

    const fetch = async (content) => {
        setLoading(true);

        const response = await mainApi(`/training-content/${content}`);
        response && setData(response.data);

        setLoading(false);
    };

    return (
        <div className="relative leading-tight text-gray-500">
            {loading && <Loader />}
            {data && (
                <div className="text-sm">
                    <div className="mb-2">
                        <Media files={data.media} />
                    </div>
                    {data.info}
                </div>
            )}
        </div>
    );
};

export default TrainingBrief;
