import Swal from "sweetalert2";
import i18n from "../i18n/config";
import withReactContent from "sweetalert2-react-content";

const ContentSwal = withReactContent(Swal);

export const Alert = Swal.mixin({
	customClass: {
		confirmButton: "swal-btn secondary",
		cancelButton: "swal-btn primary",
	},
	showClass: {
		popup: "",
	},
	buttonsStyling: false,
});

export const translateError = (error) => {
	return Alert.fire({
		html: i18n.t(error.message || error),
		icon: "warning",
	});
};

export const successAlert = (path, content) => {
	return Alert.fire({
		html: `${i18n.t(path || "message.success")}<br/>${content ?? ""}`,
		icon: "success",
		timer: content ? undefined : 1000,
	});
};

export const confirmPopup = (path) => {
	return Alert.fire({
		title: i18n.t("title.confirm"),
		html: i18n.t(path),
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: i18n.t("button.yes"),
		cancelButtonText: i18n.t("button.cancel"),
	});
};

export const showContent = (title, html, props) => {
	return ContentSwal.fire({
		customClass: {
			confirmButton: "swal-btn secondary",
			cancelButton: "swal-btn primary",
		},
		buttonsStyling: false,
		title,
		html,
		icon: false,
		showCancelButton: true,
		confirmButtonText: i18n.t("button.download"),
		showConfirmButton: false,
		cancelButtonText: i18n.t("button.close"),
		showClass: {
			popup: "",
		},
		...props,
	});
};
