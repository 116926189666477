import { useState } from "react";
import { FaChevronDown, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import menu from "./menu";
import { getAbsolutePath } from "../utils/format";

const ProfielMenu = ({ profile, firstname, role }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<div
			className="ml-4 text-sm relative"
			onClick={() => {
				setOpen(!open);
			}}
		>
			<div className="flex items-center justify-center cursor">
				<img
					className="h-10 w-10 rounded-full border object-contain"
					src={getAbsolutePath(profile, { placeholder: "/images/profile.png" })}
					crossOrigin="anonymous"
					alt={firstname}
				/>
				<div className="hidden flex-col items-start leading-none ml-2 md:flex">
					<div className="uppercase">{firstname}</div>
					<div className="text-xs">{t(`role.${role}`)}</div>
				</div>
				<div className="mx-2">{open ? <FaTimes /> : <FaChevronDown />}</div>
			</div>
			{open && (
				<div className="absolute right-0 w-64 mt-12 top-0 bg-white border shadow-lg z-10">
					{menu[role].profile.map(({ path, icon, label }, index) => (
						<Link
							key={index}
							to={path}
							className="flex items-center justify-end p-2 border-b text-xl cursor"
						>
							<span className="mr-2 text-sm">{t(label)}</span>
							{icon}
						</Link>
					))}
				</div>
			)}
		</div>
	);
};

export default ProfielMenu;
