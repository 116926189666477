import axios from "axios";
import { GetFromStorage, SaveToStorage, RemoveFromStorage } from "./storage";
import { API_ROOT, DEBUG, VERSION, i18NKEY } from "../defines";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Alert } from "./alert";
import i18n from "../i18n/config";

const mainApi = axios.create({
	baseURL: API_ROOT,
});

mainApi.interceptors.request.use((request) => {
	const accessToken = GetFromStorage("accessToken");
	const language = GetFromStorage(i18NKEY);

	if (accessToken) request.headers["Authorization"] = `Bearer ${accessToken}`;
	if (language) request.headers["Accept-Language"] = language;

	request.headers["Accept-Version"] = `ADMIN@${VERSION}`;

	return request;
});

mainApi.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error?.response?.status === 403) {
			RemoveFromStorage("refreshToken");
			RemoveFromStorage("accessToken");

			window.location = "/auth/login";
		}
		if (error?.response?.status === 400) {
			Alert.fire({
				html: error?.response?.data,
				icon: "warning",
			});

			return Promise.resolve();
		}
		if (error?.response?.status === 404) {
			Alert.fire({
				html: i18n.t("title.404"),
				icon: "warning",
			});

			return Promise.resolve();
		}
		if (error?.response?.status === 500) {
			DEBUG &&
				Alert.fire({
					html: error.response.data,
					icon: "error",
				});

			return Promise.resolve();
		}
		if (error.message === "Network Error") {
			Alert.fire({
				html: i18n.t("message.network_error"),
				icon: "warning",
			});

			return Promise.resolve();
		}

		return Promise.reject(error);
	}
);

createAuthRefreshInterceptor(mainApi, (failedRequest) =>
	mainApi({
		method: "POST",
		url: "/user/refresh",
		data: { token: GetFromStorage("refreshToken") },
	})
		.then(({ data }) => {
			SaveToStorage("refreshToken", data.refreshToken);
			SaveToStorage("accessToken", data.accessToken);

			failedRequest.response.config.headers["Authorization"] =
				"Bearer " + data.accessToken;

			return Promise.resolve();
		})
		.catch((error) => {
			return Promise.reject(error);
		})
);

export { mainApi };
