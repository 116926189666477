import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import { formatDate, formatLink, createdByCol } from "../utils/format";

const ProcedureTable = () => {
	const { t } = useTranslation();

	return (
		<Table
			url="/procedure/table"
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button primary">
								{t("button.create")}
								<HiArrowNarrowRight className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "number",
					label: "procedure.number",
					sortable: true,
					filter: true,
				},
				{
					key: "name",
					label: "procedure.name",
					sortable: true,
					filter: true,
					width: 400,
					render: (row) => formatLink(row, "name", `${row._id}`),
				},
				{
					key: "signature_count",
					label: "procedure.signature_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "comment_count",
					label: "fields.comment_count",
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "point",
					label: "procedure.point",
					width: 280,
					sortable: true,
					filter: { type: "number" },
				},
				{
					key: "start",
					label: "procedure.start",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "start"),
				},
				{
					key: "end",
					label: "procedure.end",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "end"),
				},
				{
					key: "created",
					label: "fields.created",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "created"),
				},
				createdByCol,
			]}
		/>
	);
};

export default ProcedureTable;
