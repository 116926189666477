import Stats from "./Stats";
import { useAuth } from "../utils/auth";

const AdminDashboard = () => {
    const { user } = useAuth();

    return (
        <div>
            <Stats org_id={user.org} />
        </div>
    );
};
export default AdminDashboard;
