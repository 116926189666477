import { useTranslation } from "react-i18next";
import { RiStarFill } from "react-icons/ri";
import { CreatedBy, Status, Media } from "../template";

const InductionTemplateView = ({
	createdby,
	created,
	info,
	type,
	name,
	media,
	shuffle,
	duration,
	threshold,
	point,
	question_count,
	questions,
}) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
			<div>
				<CreatedBy user={createdby} date={created}></CreatedBy>
				<Status
					color={type === "exam" ? "red" : "green"}
					left={
						point && (
							<div className="flex items-center">
								<RiStarFill className="mr-1" />
								{point} {t("fields.has-point")}
							</div>
						)
					}
					right={t(`title.induction-template`)}
				></Status>
				{name && <div className="my-4 uppercase font-semibold">{name}</div>}
				{info && <div className="info">{info}</div>}
				<Media files={media} />
			</div>
			{["exam", "guest"].includes(type) && (
				<div>
					<div className="uppercase mb-2 pb-2 border-b">
						{t("induction-template.exam")}
					</div>
					<div className="text-sm mb-4">
						{shuffle && (
							<div className="flex justify-between">
								<div>{t("induction-template.shuffle")}:</div>
								<div>{t(`fields.${shuffle}`)}</div>
							</div>
						)}
						{duration && (
							<div className="flex justify-between">
								<div>{t("induction-template.duration")}:</div>
								<div>{duration}</div>
							</div>
						)}
						{threshold && (
							<div className="flex justify-between">
								<div>{t("induction-template.threshold")}:</div>
								<div>{threshold}</div>
							</div>
						)}
						{question_count && (
							<div className="flex justify-between">
								<div>{t("induction-template.question_count")}:</div>
								<div>{question_count}</div>
							</div>
						)}
					</div>
					<div className="uppercase mb-2 pb-2 border-b">
						{t("induction-template.questions")}
					</div>
					<div className="text-sm">
						{Array.isArray(questions) &&
							questions.map(({ question, attachment, variants }, index) => {
								return (
									<div key={index} className="mb-2 pb-2 border-b border-dashed">
										{index + 1}. {question}
										<Media files={attachment} />
										<ul className="ml-8 my-2 list-outside list-decimal">
											{Array.isArray(variants) &&
												variants.map(({ variant, point }, index) => {
													return (
														<li
															key={index}
															className={`${point > 0 ? "underline" : ""}`}
														>
															{variant} ({point}{" "}
															{t("induction-template.has-point")})
														</li>
													);
												})}
										</ul>
									</div>
								);
							})}
					</div>
				</div>
			)}
		</div>
	);
};

export default InductionTemplateView;
