import { getAbsolutePath } from "../utils/format";

const Signature = ({ signature }) => {
	if ({ signature }) {
		let url = getAbsolutePath(signature?.signature, { placeholder: null });

		if (url)
			return (
				<img
					className="w-24 object-contain"
					src={url}
					alt="Signature"
					crossOrigin="anonymous"
				/>
			);
	}

	return null;
};

export default Signature;
