import { t } from "i18next";
import Table from "../list/Table";
import { CreatedBy, Gallery } from "../template";

const CommentsView = ({ model, id }) => {
    return (
        <div>
            <Table
                url={`/comment/table`}
                renderHeader={({ total }) => (
                    <div className="mb-4">
                        {t("title.comment")} - {total}
                    </div>
                )}
                defaultFind={{ model, id }}
                limit={10}
                renderItem={({
                    _id,
                    text,
                    createdby,
                    created,
                    attachments,
                }) => {
                    return (
                        <div key={_id} className="mb-4 pb-4 border-b">
                            <CreatedBy user={createdby} date={created} />
                            {text}
                            <Gallery files={attachments} width={"w-1/3"} />
                        </div>
                    );
                }}
            ></Table>
        </div>
    );
};

export default CommentsView;
