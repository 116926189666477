import * as Yup from "yup";
import { Field, Form, TimeLineItem } from "../template";
import { fileValidation, stringValidation } from "../utils/validations";
import { FileUpload, Text, Radios } from "../inputs";
import { DEBUG } from "../defines";
import { randomData, randomNumber } from "../utils/demo";
import { useAuth } from "../utils/auth";
import ContractItem from "./ContractItem";
import { successAlert } from "../utils/alert";
import View from "./View";

const OrgForm = ({ editable }) => {
	const { user } = useAuth();
	const props = {
		editable,
		model: "org",
		id: user.org,
		init: {
			logo: [],
			register: DEBUG ? randomNumber(7) : "",
			name: DEBUG ? randomData("org") + " ХХК" : "",
			pin: DEBUG ? randomNumber(4) : "",
			accident_freedays: 0,
			accident_freehours: 0,
			accident_frequency: 0,
		},
		validationSchema: Yup.object().shape({
			logo: fileValidation(true),
			register: stringValidation(true),
			name: stringValidation(true),
			pin: stringValidation(true),
		}),
		success: () => {
			successAlert();
		},
		view: (values) => <View {...values} key={user.org} id={user.org} />,
	};

	return (
		<>
			<Form {...props}>
				{({ disabled, submited, t, values }) => {
					return (
						<div>
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
								<div>
									<Field
										name="logo"
										label="org.logo"
										component={FileUpload}
										disabled={disabled}
										submited={submited}
									/>
								</div>
								<div>
									<Field
										name="register"
										label="org.register"
										type="number"
										disabled={true}
										component={Text}
									/>
									<Field name="name" label="org.name" disabled={true} />
									<Field
										name="user_count"
										label="org.user_count"
										type="number"
										disabled={true}
										component={Text}
									/>
									<Field
										name="pin"
										label="org.pin"
										type="number"
										disabled={disabled}
										component={Text}
									/>
									<Field
										name="accident_freedays"
										label="org.accident_freedays"
										type="number"
										disabled={disabled}
										component={Text}
									/>
									<Field
										name="accident_freehours"
										label="org.accident_freehours"
										type="number"
										disabled={disabled}
										component={Text}
									/>
									<Field
										name="accident_frequency"
										label="org.accident_frequency"
										type="number"
										disabled={disabled}
										component={Text}
									/>
								</div>
								<div className="col-span-2">
									<Field
										name="contract"
										label="org.contract"
										component={Radios}
										disabled={true}
										axio={`/contract/org/${user.org}`}
										renderItem={({ _id, ...contract }, selected, index) => (
											<TimeLineItem key={index} active={selected === _id}>
												<ContractItem {...contract} t={t} disabled={true} />
											</TimeLineItem>
										)}
									/>
								</div>
							</div>
						</div>
					);
				}}
			</Form>
		</>
	);
};

export default OrgForm;
