import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import {
	numberValidation,
	selectValidation,
	stringValidation,
} from "../utils/validations";
import { DatePicker, FileUpload, Select, Text } from "../inputs";
import { t } from "i18next";

const ContractForm = ({ editable }) => {
	const { id, org_id } = useParams();

	const props = {
		editable,
		model: "contract",
		id,
		init: {
			number: "",
			start: "",
			end: "",
			price: "",
			currency: "",
			limit: "",
			attachments: [],
			storage: "",
		},
		validationSchema: Yup.object().shape({
			number: stringValidation(true),
			start: stringValidation(true),
			end: stringValidation(true),
			price: numberValidation(true),
			currency: selectValidation(true),
			limit: numberValidation(true),
			storage: numberValidation(true),
		}),
		beforeSubmit: (values) => {
			if (values.start > values.end) throw new Error("contract.start_end");

			return { ...values, ...(org_id ? { org: org_id } : null) };
		},
	};

	return (
		<>
			<h1 className="bordered">
				{t(id ? "title.edit" : "title.create", {
					param: t("title.contract"),
				})}
			</h1>
			<Form {...props}>
				{({ disabled, submited }) => {
					return (
						<div className="p-4 bg-white grid grid-cols-1 lg:grid-cols-2 gap-8">
							<div>
								<Field
									name="number"
									label="contract.number"
									disabled={disabled}
									component={Text}
								/>
								<Field
									name="limit"
									label="contract.limit"
									disabled={disabled}
									component={Text}
									type="number"
								/>
								<Field
									name="price"
									label="contract.price"
									disabled={disabled}
									component={Text}
									type="number"
								/>
								<Field
									name="currency"
									label="contract.currency"
									disabled={disabled}
									component={Select}
									axio={"/const/static/currency"}
								/>
							</div>
							<div>
								<Field
									name="start"
									label="contract.start"
									disabled={disabled}
									component={DatePicker}
									time
								/>
								<Field
									name="end"
									label="contract.end"
									disabled={disabled}
									component={DatePicker}
									time
								/>
								<Field
									name="storage"
									label="contract.storage"
									disabled={disabled}
									component={Text}
									type="number"
								/>
								<Field
									name="status"
									label="contract.status"
									disabled={disabled}
									component={Select}
									axio="/const/static/contract_status"
								/>
								<Field
									name="attachments"
									label="contract.attachments"
									disabled={disabled}
									submited={submited}
									component={FileUpload}
									root="contract"
									accept="application/pdf"
								/>
							</div>
						</div>
					);
				}}
			</Form>
		</>
	);
};

export default ContractForm;
