import { t } from "i18next";
import { formatDate, formatPrice, getAbsolutePath } from "../utils/format";

const OrgView = ({
	logo,
	name,
	accident_freedays,
	accident_freehours,
	accident_frequency,
	contract_number,
	contract_start,
	contract_end,
	contract_price,
	contract_limit,
	user_count,
	register,
	pin,
}) => {
	return (
		<div className="bg-white p-4 grid grid-cols-1 md:grid-cols-2 gap-8">
			<div className="md:col-span-2 font-bold border-b flex justify-between mb-2 flex-wrap text-lg">
				<div>{name}</div>
				<div>
					{t("org.register")}: {register}
				</div>
			</div>
			<div>
				<div className="flex self-start items-center">
					<img
						src={getAbsolutePath(logo)}
						alt={name}
						className="w-32 mr-2"
						crossOrigin="anonymous"
					/>
					<div className="flex-grow">
						<div className="flex justify-between text-sm">
							<div className="text-gray-500">{t("org.accident_freedays")}</div>
							<div className="font-semibold">{accident_freedays}</div>
						</div>
						<div className="flex justify-between text-sm">
							<div className="text-gray-500">{t("org.accident_freehours")}</div>
							<div className="font-semibold">{accident_freehours}</div>
						</div>
						<div className="flex justify-between text-sm">
							<div className="text-gray-500">{t("org.accident_frequency")}</div>
							<div className="font-semibold">{accident_frequency}</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div className="text-sm">
					<div className="flex justify-between pb-2 mb-2 border-b">
						<div className="text-gray-500">{t("contract.number")}</div>
						<div className="font-semibold">{contract_number}</div>
					</div>
					<div className="flex justify-between mb-2">
						<div className="text-gray-500">{t("contract.start")}</div>
						<div className="font-semibold">{formatDate(contract_start)}</div>
					</div>
					<div className="flex justify-between mb-2">
						<div className="text-gray-500">{t("contract.end")}</div>
						<div className="font-semibold">{formatDate(contract_end)}</div>
					</div>
					<div className="flex justify-between mb-2">
						<div className="text-gray-500">{t("contract.price")}</div>
						<div className="font-semibold">{formatPrice(contract_price)}</div>
					</div>
					<div className="flex justify-between mb-2">
						<div className="text-gray-500">{t("contract.limit")}</div>
						<div className="font-semibold">{contract_limit}</div>
					</div>
					<div className="flex justify-between mb-2">
						<div className="text-gray-500">{t("org.user_count")}</div>
						<div className="font-semibold">{user_count}</div>
					</div>
					<div className="flex justify-between items-end mb-2">
						<div className="text-gray-500">{t("org.pin")}</div>
						<div className="font-semibold text-2xl">{pin}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrgView;
