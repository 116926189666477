import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../template";
import { selectValidation, stringValidation } from "../utils/validations";
import { Select, Text } from "../inputs";

const CategoryForm = ({ editable, type }) => {
	const { id } = useParams();
	const props = {
		editable,
		model: "category",
		id,
		init: {
			type: type || "",
			name: "",
		},
		validationSchema: Yup.object().shape({
			type: selectValidation(true),
			name: stringValidation(true),
		}),
	};

	return (
		<Form {...props} containerClass="w-full md:w-1/2">
			{({ disabled }) => {
				return (
					<div>
						{!type && (
							<Field
								name="type"
								label="category.type"
								disabled={disabled}
								component={Select}
								axio="/const/static/category_type"
							/>
						)}
						{["incident", "cause"].includes(type) && (
							<Field
								name="parent"
								label="category.parent"
								disabled={disabled}
								component={Select}
								axio={`/const/category/${type}`}
							/>
						)}
						<Field
							name="name"
							label="category.name"
							disabled={disabled}
							component={Text}
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default CategoryForm;
